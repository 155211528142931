const BrandAutomationActionType = {
  GET_IS_BRAND_AUTOMATION_EXIST: 'GET_IS_BRAND_AUTOMATION_EXIST',
  SAVE_IS_BRAND_AUTOMATION_EXIST: 'SAVE_IS_BRAND_AUTOMATION_EXIST',
  RESET_IS_BRAND_AUTOMATION_EXIST: 'RESET_IS_BRAND_AUTOMATION_EXIST',

  GET_CONDITIONS_ACTIONS: 'GET_CONDITIONS_ACTIONS',
  SAVE_CONDITIONS_ACTIONS: 'SAVE_CONDITIONS_ACTIONS',
  RESET_CONDITIONS_ACTIONS: 'RESET_CONDITIONS_ACTIONS',

  GET_BRAND_AUTOMATION_RULES: 'GET_BRAND_AUTOMATION_RULES',
  SAVE_BRAND_AUTOMATION_RULES: 'SAVE_BRAND_AUTOMATION_RULES',
  RESET_BRAND_AUTOMATION_RULES: 'RESET_BRAND_AUTOMATION_RULES',

  GET_BRAND_AUTOMATION_RULE: 'GET_BRAND_AUTOMATION_RULE',
  SAVE_BRAND_AUTOMATION_RULE: 'SAVE_BRAND_AUTOMATION_RULE',
  RESET_BRAND_AUTOMATION_RULE: 'RESET_BRAND_AUTOMATION_RULE',

  CREATE_BRAND_AUTOMATION: 'CREATE_BRAND_AUTOMATION',
  EDIT_BRAND_AUTOMATION: 'EDIT_BRAND_AUTOMATION',

  ENABLE_BRAND_AUTOMATION: 'ENABLE_BRAND_AUTOMATION',
  FINISH_BRAND_AUTOMATION: 'FINISH_BRAND_AUTOMATION',
  DELETE_BRAND_AUTOMATION: 'DELETE_BRAND_AUTOMATION',

  EXPORT_BRAND_AUTOMATION_RULES: 'EXPORT_BRAND_AUTOMATION_RULES',

  GET_BRAND_AUTOMATION_TRANSACTION_LOGS: 'GET_BRAND_AUTOMATION_TRANSACTION_LOGS',
  SAVE_BRAND_AUTOMATION_TRANSACTION_LOGS: 'SAVE_BRAND_AUTOMATION_TRANSACTION_LOGS',
  RESET_BRAND_AUTOMATION_TRANSACTION_LOGS: 'RESET_BRAND_AUTOMATION_TRANSACTION_LOGS',
  GENERATE_BRAND_AUTOMATION_TRANSACTION_LOGS: 'GENERATE_BRAND_AUTOMATION_TRANSACTION_LOGS',

  GET_BRAND_AUTOMATION_PERIODICAL_LOGS: 'GET_BRAND_AUTOMATION_PERIODICAL_LOGS',
  SAVE_BRAND_AUTOMATION_PERIODICAL_LOGS: 'SAVE_BRAND_AUTOMATION_PERIODICAL_LOGS',
  RESET_BRAND_AUTOMATION_PERIODICAL_LOGS: 'RESET_BRAND_AUTOMATION_PERIODICAL_LOGS',
  GENERATE_BRAND_AUTOMATION_PERIODICAL_LOGS: 'GENERATE_BRAND_AUTOMATION_PERIODICAL_LOGS',

  GET_BRAND_AUTOMATION_SIGN_UP_LOGS: 'GET_BRAND_AUTOMATION_SIGN_UP_LOGS',
  SAVE_BRAND_AUTOMATION_SIGN_UP_LOGS: 'SAVE_BRAND_AUTOMATION_SIGN_UP_LOGS',
  RESET_BRAND_AUTOMATION_SIGN_UP_LOGS: 'RESET_BRAND_AUTOMATION_SIGN_UP_LOGS',
  GENERATE_BRAND_AUTOMATION_SIGN_UP_LOGS: 'GENERATE_BRAND_AUTOMATION_SIGN_UP_LOGS',

  GET_BRAND_AUTOMATION_VERIFICATION_LOGS: 'GET_BRAND_AUTOMATION_VERIFICATION_LOGS',
  SAVE_BRAND_AUTOMATION_VERIFICATION_LOGS: 'SAVE_BRAND_AUTOMATION_VERIFICATION_LOGS',
  RESET_BRAND_AUTOMATION_VERIFICATION_LOGS: 'RESET_BRAND_AUTOMATION_VERIFICATION_LOGS',
  GENERATE_BRAND_AUTOMATION_VERIFICATION_LOGS: 'GENERATE_BRAND_AUTOMATION_VERIFICATION_LOGS',

  GET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS: 'GET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS',
  SAVE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS: 'SAVE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS',
  RESET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS: 'RESET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS',
  GENERATE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS: 'GENERATE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS',

  GET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS: 'GET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS',
  SAVE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS: 'SAVE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS',
  RESET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS: 'RESET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS',
  GENERATE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS: 'GENERATE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS',
};

export default BrandAutomationActionType;
