import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import TaxesActionType from 'actions/taxes/taxesActionType';
import { changeLoadingState, setErrorMessage, setSuccessMessage } from 'actions/setting/settingActionCreator';
import { saveDepositTaxes, saveWithdrawalTaxes, saveTaxSetting } from 'actions/taxes/taxesActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { AppConstants } from 'constants/index';
import { i18n } from 'services';
import { changeEndedStatus } from 'actions/template/templateActionCreator';

function* getDepositTaxes({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes, Deposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Deposit]);
    yield put(saveDepositTaxes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editDepositTaxes({ payload }) {
  try {
    const { brandId, data } = payload;
    const { BrandAdmin, Taxes, Deposit } = AppConstants.api;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId, Deposit], options);
    yield put(setSuccessMessage(getMessage(i18n.t('taxes'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWithdrawalTaxes({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes, Withdrawal } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId, Withdrawal]);
    yield put(saveWithdrawalTaxes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editWithdrawalTaxes({ payload }) {
  try {
    const { brandId, data } = payload;
    const { BrandAdmin, Taxes, Withdrawal } = AppConstants.api;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId, Withdrawal], options);
    yield put(setSuccessMessage(getMessage(i18n.t('taxes'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxSetting({ payload }) {
  try {
    const { brandId } = payload;
    const { BrandAdmin, Taxes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, brandId]);
    yield put(saveTaxSetting(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addTaxSetting({ payload }) {
  try {
    const { brandId, data } = payload;
    const { BrandAdmin, Taxes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, Taxes, brandId], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('brand:taxSettings'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* taxesSaga() {
  yield all([
    takeLatest(TaxesActionType.GET_DEPOSIT_TAXES, getDepositTaxes),
    takeLatest(TaxesActionType.EDIT_DEPOSIT_TAXES, editDepositTaxes),
    takeLatest(TaxesActionType.GET_WITHDRAWAL_TAXES, getWithdrawalTaxes),
    takeLatest(TaxesActionType.EDIT_WITHDRAWAL_TAXES, editWithdrawalTaxes),
    takeLatest(TaxesActionType.GET_TAX_SETTING, getTaxSetting),
    takeLatest(TaxesActionType.ADD_TAX_SETTING, addTaxSetting),
  ]);
}
