import { Card as AntdCard } from 'antd';
import styled from 'styled-components';
import { StyledFlexItem } from 'components/styledComponents/box/Box.styled';
import {
  media, flexContainer, handleColorType, truncate, getBoxShadow,
} from 'utils/styledHelpers';

const StyledAntCard = styled(AntdCard)`
  .ant-card-body {
    position: relative;
    padding: 0;
  }
  .ant-card-meta-description {
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-card-meta-detail > div:not(:last-child) {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.blue};
    margin-top: 0.57rem;
    flex-grow: 1;
    @media (max-width: 1200px) {
      flex-grow: 0;
    }
  }
  .ant-card-actions > li {
    margin: 0;
  }
  &.ant-card {
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
    box-shadow: ${() => getBoxShadow('#000000')}10;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    border: 1px solid ${(props) => props.theme.colors.secondaryBorder}60;
    background: ${(props) => props.theme.colors.mainCardBg};
    box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
    padding: 1.14rem;
    height: 100%;
    transition: 0.2s box-shadow ease-in-out;
    &:hover {
      box-shadow: ${() => getBoxShadow('#000000')}35;
    }
  }

  & .ant-card-meta-title {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
  }
  & .ant-card-actions {
    border-top: none;
    background: ${(props) => props.theme.colors.mainCardBg};
  }
`;
const StyledCardListWrapper = styled.div`
  box-shadow: ${() => getBoxShadow('#000000')}10;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder}60;
  background: ${(props) => props.theme.colors.mainCardBg};
  padding: 1.14rem 1.14rem 1.71rem;
  width: 100%;
  position: relative;
  transition: 0.2s box-shadow ease-in-out;
  &:hover {
    .ant-checkbox-wrapper {
      opacity: 1;
    }
    box-shadow: ${() => getBoxShadow('#000000')}35;
  }
`;
const StyledAntCardPerformance = styled(StyledAntCard)`
  & .ant-card-meta {
    margin: 0;
    flex-grow: 1;
  }
  & .ant-card-meta-detail {
    width: 100%;

    .ant-card-meta-title {
      margin-bottom: 1.14rem;
    }
  }
  &.ant-card {
    position: relative;
    height: auto;
    overflow: hidden;
    .ant-card-body {
      position: initial;
    }
    &:hover {
      box-shadow: 1px 3px 6px #00000014;
    }
  }
`;

const StyledCardFlexItem = styled(StyledFlexItem)`
  gap: 0.71rem;
  flex: ${(props) => (props.isService ? '0 0 100%' : '0 0 50%')};
  justify-content: space-between;
  max-width: calc(50% - 0.57rem);
  min-height: ${(props) => props.height};
  margin-bottom: 1.14rem;
  padding: 1.71rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  box-shadow: ${() => getBoxShadow('#000000')}10;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder}60;
  background: ${(props) => props.theme.colors.mainCardBg};

  ${media.md`
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1.14rem;
  `}
`;

const StyledCardItem = styled.div`
  ${flexContainer()};
  align-items: flex-start;
  position: relative;
  height: ${(props) => (props.isHeight ? '100%' : ' ')};
`;

const StyledCardImage = styled.div`
  width: ${(props) => (props.smallSize ? '2.43rem' : '4.57rem')};
  height: ${(props) => (props.smallSize ? '2.43rem' : '4.57rem')};
  ${flexContainer()};
  justify-content: center;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  margin-right: ${(props) => (props.smallSize ? '1.14rem' : '2.86rem')};
  background: ${(props) => props.theme.colors.tableTrHoverColor};
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const StyledCardImgWrapper = styled.div`
  height: 10.5rem;
  min-height: 10.5rem;
  max-height: 10.5rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
  background-color: ${(props) => props.theme.colors.primaryBg};
  margin-bottom: 1.14rem;
  position: relative;
  z-index: 1;
  cursor: ${(props) => (props.isEmpty ? 'default' : 'pointer')};
  overflow: hidden;
  img,
  video {
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
    object-fit: cover;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      fill: ${(props) => props.theme.colors.primaryBorder};
    }
  }
  & + span {
    width: 100%;
  }
`;

const CardTruncateText = styled.span`
  ${truncate()};
  max-width: 20rem;
  display: inline-block;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledCardList = styled.ul`
  ${media.xs`
    margin-top: 1.71rem;
    margin-left: -4.8rem;
  `}
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1.71rem;

    h3 {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
      color: ${(props) => props.theme.colors.primaryText};
      display: flex;
      align-items: center;

      strong {
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        margin-left: 4px;
      }
    }

    & > div {
      height: 100%;
      width: 1.14rem;
      ${flexContainer()};
      justify-content: center;
      margin-right: 0.71rem;
    }

    svg path {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
`;

const StyledCardPreview = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed!important' : 'pointer')};
  border-bottom: 1px solid ${(props) => (props.isRule ? props.theme.colors.secondaryBorder : 'none')};
  & > div {
    pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  }
  &:last-child {
    border-bottom: none;
  }
  svg g path,
  svg path {
    fill: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : '')};
  }
`;

const StyledCardItemStatus = styled.span`
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  margin-right: 0.1rem;
  background: ${({ status }) => handleColorType(status)};
`;

const StyledImgWrapper = styled.div`
  background: ${(props) => (!props.isLimitSet ? props.theme.colors.tableTrEvenColor : `${props.theme.colors.blue}20`)};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 0.7rem;
  margin-bottom: 1.14rem;
  margin-right: 1.25rem;
  width: 4.9rem;
  flex: 0 0 4.9rem;
  height: 4.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg g path,
  svg g g path,
  svg path,
  svg ellipse {
    fill: ${(props) => (!props.isLimitSet ? props.theme.colors.secondaryColor : props.theme.colors.blue)};
  }

  ${media.sm`
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.71rem;
  `}
`;
const StyledCardTitle = styled.h2`
  ${truncate()};
  max-width: 17rem;
  font-size: ${(props) => (props.isLimit ? '1.14rem' : ' 1.3rem')};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  text-transform: uppercase;
  margin-bottom: ${(props) => (props.isLimit ? '1.43rem' : ' 2.14rem')};
  text-align: center;
`;
const StyledCardDescription = styled.div`
  line-height: 1.43;
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  margin-bottom: 1.14rem;
  flex-grow: 1;
  font-size: 1rem;
  width: 95%;
`;
const StyledCardIcon = styled.div`
  width: 4.57rem;
  height: 4.57rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.tableTrEvenColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.14rem;
`;

// Custom card styles
const StyledConditionTitle = styled.h4`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin: ${(props) => (props.isMargin ? '0 0 1.14rem ' : '1.14rem 0')};
`;
const StyledConditionCard = styled.div`
  box-shadow: ${() => getBoxShadow('#000000')}10;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder}60;
  background: ${(props) => props.theme.colors.mainCardBg};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  cursor: pointer;
  width: 100%;
  height: ${(props) => (props.isPreview ? 'auto' : '100%')};
  &:hover {
    box-shadow: 0 3px 6px #00000014;
    cursor: ${(props) => (props.isPreview ? 'initial' : 'pointer')};
  }
`;
const StyledConditionCardName = styled.div`
  background: ${(props) => (props.bgDark ? props.theme.colors.tableHeadColor : props.theme.colors.tableTrHoverColor)};
  color: ${(props) => (props.isMisconfigured ? props.theme.colors.danger : props.theme.colors.primaryText)};
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusMiddle}  ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`};
  padding: 1.14rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  display: flex;
  align-items: center;
  svg {
    margin-right: 1.14rem;
  }
`;
const StyledConditionCardDescription = styled.div`
  padding: 1.14rem;
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${(props) => props.theme.colors.primaryText};
  > div:not(:last-child) {
    margin-bottom: ${(props) => (props.isPreview ? '0.86rem' : '')};
  }
  > div {
    display: ${(props) => (props.isPreview ? 'flex' : '')};
    justify-content: ${(props) => (props.isPreview ? 'space-between' : '')};
    align-items: ${(props) => (props.isPreview ? 'center' : '')};
    color: ${(props) => props.theme.colors.primaryText};
    span:first-child {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    }
  }
`;
export {
  StyledAntCard,
  StyledCardListWrapper,
  StyledCardImgWrapper,
  StyledAntCardPerformance,
  StyledImgWrapper,
  StyledCardTitle,
  StyledCardDescription,
  StyledCardItemStatus,
  StyledCardImage,
  StyledCardPreview,
  StyledCardList,
  StyledCardItem,
  StyledCardFlexItem,
  StyledCardIcon,
  CardTruncateText,
  StyledConditionTitle,
  StyledConditionCard,
  StyledConditionCardName,
  StyledConditionCardDescription,
};
