import { Button } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media, truncate, getButtonHeight } from 'utils/styledHelpers';

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: ${(props) => (props.marginTop ? '2rem' : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? '1.4rem' : '0')};
  justify-content: ${(props) => (props.isCenter ? 'center' : 'flex-end')};
  & .ant-btn > span {
    display: block;
    vertical-align: middle;
    margin-top: 0;
  }
  & .ant-btn {
    margin-left: 1.14rem;
    @media (max-width: 1200px) {
      margin-left: 0.5rem;
    }
    ${media.sm`
      margin-left: 0;
    `}
  }
  ${media.sm`
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      flex-wrap: wrap;
   `}
`;
const StyledLeftAutoButtonWrapper = styled(StyledButtonWrapper)`
  margin-left: auto;
`;
const StyledAddSettingsModalFooterButtons = styled(StyledButtonWrapper)`
  padding-top: 2.86rem;
`;
const StyledMoreWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  > span {
    border-left: 1px solid ${(props) => props.theme.colors.primaryBorder};
    padding-left: 0.57rem;
    display: flex;
    height: 1.14rem;
    > div {
      align-items: flex-end;
    }
  }
  .ant-tag {
    width: 100%;
  }
`;

const StyledMoreItem = styled.div`
  margin-bottom: 1.14rem;
  color: ${(props) => props.theme.colors.primaryText};
  &:last-child {
    margin-bottom: 0;
  }
`;
const StyledPopoverContentItem = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledPopoverItem = styled(StyledPopoverContentItem)`
  text-align: center;
  padding: ${(props) => (props.noPadding ? '0' : '0 0.57rem 0 0')};
  max-width: ${(props) => (props.allowFullWidth ? 'unset' : '7rem')};
  width: auto;
  color: ${(props) => props.theme.colors.primaryText};

  ${({ allowTruncate }) => allowTruncate
    && `
    ${truncate()};
  `}

  .ant-tag {
    margin: 0;
    width: 100%;
  }
`;

const StyledButtonWrapperAuto = styled(StyledButtonWrapper)`
  margin-top: auto;
  padding-top: ${(props) => (props.paddingTop ? '2.28rem' : '0')};

  ${media.sm`
    margin-top: 2rem;
  `}
`;

const StyledButtonWrapperCentered = styled(StyledButtonWrapper)`
  justify-content: center;
  margin-top: ${(props) => (props.isMarginTop ? 'auto' : '')};
`;
const StyledButtonWrapperRight = styled(StyledButtonWrapperCentered)`
  justify-content: flex-end;
  padding-top: ${(props) => (props.noPaddingTop ? '0' : '2.28rem')};
`;

const StyledButtonBlue = styled(Button)`
  &.ant-btn {
    width: auto;
    height: 2.28rem;
    padding: 0 1.14rem;
    min-width: 7.15rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.blue};
    border: none;
    border-radius: ${(props) => getButtonHeight(props.size)};
    line-height: ${(props) => getButtonHeight(props.size)};
    box-shadow: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;
    box-sizing: border-box;
    transition: 0.2s background ease-in-out;
    &.ant-popover-open {
      background-color: ${(props) => props.theme.colors.hover};
    }
    &:after {
      animation-duration: 0s !important;
    }

    &.ant-btn-primary {
      height: 2.57rem;
    }

    &:not(:disabled):hover {
      background-color: ${(props) => props.theme.colors.hover};
      color: ${(props) => props.theme.colors.white};
    }

    &-sm {
      height: 2rem;
    }

    ${media.sm`
      display: ${(props) => (props.hidemobile ? 'none' : 'flex')};
    `}
  }
  svg {
    margin-right: 0.85rem;
  }
  &:disabled {
    background-color: #9fa4ac33;
    color: #9fa4accc;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.blue}50;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const StyledButtonPurple = styled(StyledButtonBlue)`
  &.ant-btn {
    background-color: ${(props) => props.theme.colors.purple};
    border: 1px solid transparent;
    height: ${(props) => (props.isFullHeight ? '2.57rem' : '2.28rem')};
    &:disabled {
      background-color: ${(props) => props.theme.colors.purple};
      color: ${(props) => props.theme.colors.white};
      cursor: not-allowed;
      opacity: 0.4;
      border: none;
    }
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${(props) => props.theme.colors.purpleHover};
      color: ${(props) => props.theme.colors.white};
      border-color: transparent;
    }

    ${media.sm`
      display: ${(props) => (props.hidemobile ? 'none' : 'block')};
      margin-left: 0;
    `}
  }
`;

const StyledSmallButtonBlue = styled(StyledButtonBlue)`
  &.ant-btn {
    background-color: ${(props) => props.theme.colors.blue}20;
    border: 1px solid ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.blue};
    font-size: 0.85rem;
    min-width: 4.43rem;
    padding: 0 1.14rem;
    transition: 0.2s background-color ease-in-out;
    &:disabled {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.colors.blue}10;
      color: ${(props) => props.theme.colors.blue}50;
      border: 1px solid ${(props) => props.theme.colors.blue}50;
    }
    &:not(:disabled):hover {
      background-color: ${(props) => props.theme.colors.blue}30;
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;
const StyledSmallButtonCancel = styled(StyledButtonBlue)`
  &.ant-btn {
    background-color: ${(props) => props.theme.colors.primaryBorder}20;
    border: none;
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 0.85rem;
    min-width: 5.43rem;
    padding: 0 1.5rem;
    transition: 0.2s background-color ease-in-out;
    &:not(:disabled):hover {
      background-color: ${(props) => props.theme.colors.primaryBorder}50;
      color: ${(props) => props.theme.colors.primaryText};
    }
    &:disabled {
      opacity: 0.5;
    }
  }
`;
const StyledButtonPurpleIcon = styled(StyledButtonPurple)`
  &.ant-btn {
    padding: 0 1.14rem;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.sm`
      display: ${(props) => (props.hidemobile ? 'none' : 'flex')};
    `}
  }
  svg {
    margin-right: 0.85rem;
  }
`;

const StyledButtonBordered = styled(StyledButtonBlue)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.blue};
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.blue};
  }
  &:disabled {
    color: ${(props) => props.theme.colors.primaryBorder};
  }

  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

const StyledButtonAttachment = styled(StyledButtonBordered)`
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.darkColor};
    padding: 0.89rem 1.14rem;
    border: none;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.white};
    height: auto;
    border-radius: 21px;

    &:not(:disabled):hover {
      box-shadow: 0 2px 10px #00000045;
      background-color: ${(props) => props.theme.colors.darkColor};
      span {
        color: ${(props) => props.theme.colors.blue};
      }
      svg path {
        fill: ${(props) => props.theme.colors.blue};
      }
    }
  }
`;

const StyledButtonGray = styled(StyledButtonBordered)`
  &.ant-btn {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    padding: 0 2.29rem;
    text-transform: initial;
    font-size: ${(props) => props.theme.typography.fontSizeSmall};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    color: ${(props) => props.theme.colors.primaryText};
    border-radius: ${(props) => getButtonHeight(props.size)};
    line-height: ${(props) => getButtonHeight(props.size)};
  }
`;
const StyledButtonGrayBackground = styled(StyledButtonGray)`
  &.ant-btn {
    display: inline-block;
    width: auto;
    background-color: ${(props) => props.theme.colors.grayButton};
    border-color: ${(props) => props.theme.colors.grayButton};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: ${(props) => props.theme.colors.primaryText};
      border-color: ${(props) => props.theme.colors.grayButton};
      background-color: ${(props) => props.theme.colors.tableHeadColor};
    }
  }
`;
const StyledButtonAttachFile = styled(StyledButtonGray)`
  &.ant-btn {
    padding: 0 3.42rem 0 1.14rem;
    height: 2.29rem;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.grayButton};
    border-color: transparent;
    position: relative;
    z-index: 33;
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${(props) => props.theme.colors.secondaryBorder};
      border-color: transparent;
    }
    > span:first-of-type {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      color: ${(props) => props.theme.colors.primaryText};
    }
    > span:first-of-type {
      .ant-btn {
        background-color: ${(props) => props.theme.colors.secondaryBorder};
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
`;
const StyledButtonGrayIcon = styled(StyledButtonBordered)`
  &.ant-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    background: ${(props) => props.theme.colors.grayButton};
    color: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : props.theme.colors.primaryText)};
    text-transform: initial;
    border: none;
    padding: 0 0.85rem 0 0.57rem;
    min-width: 5rem;
    transition: all 0.3s ease-in-out;

    ${media.sm`
      display: ${(props) => (props.hidemobile ? 'none' : 'flex')};
    `}

    svg {
      margin: 0 0 0 0.57rem;
      g path {
        fill: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : props.theme.colors.primaryText)};
      }
    }

    &:not(:disabled):hover {
      background: ${(props) => (props.disabled ? props.theme.colors.primaryBg : props.theme.colors.secondaryBorder)};
      color: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : props.theme.colors.primaryText)};
      svg path {
        fill: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : props.theme.colors.secondaryColor)};
      }
      span {
        color: ${(props) => (props.disabled ? props.theme.colors.primaryBorder : props.theme.colors.primaryText)};
      }
    }
  }
  & > span {
    padding: 0 8px;
  }
`;
const StyledButtonGrayUnbordered = styled(StyledButtonGray)`
  &.ant-btn {
    background-color: initial;
    border: initial;
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    flex: 0;
    padding: 0 0 0 2.29rem;
    height: ${(props) => (props.size === 'small' ? '2.28rem' : '2.57rem')};
    &:disabled {
      touch-action: none;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
    }
    &:not(:disabled):hover {
      background-color: initial;
    }
  }
  span {
    margin-top: 4px;
    color: ${(props) => (props.blue ? props.theme.colors.blue : props.theme.colors.secondaryColor)};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  svg {
    margin-right: 0;
  }
`;

const StyledButtonBlueUnbordered = styled(StyledButtonGrayUnbordered)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.blue};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    flex: unset;
    width: auto;
    padding: 0 2.29rem;
    margin-left: 0;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: transparent;
      span {
        color: ${(props) => props.theme.colors.hover};
      }
    }
    span {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

const StyledDropdownButton = styled.div`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.71rem 1.71rem;
  }
  .ant-dropdown-menu-item {
    &:hover {
      background-color: ${(props) => props.theme.colors.hoverBg};
    }
  }
`;
const StyledLeftDropdownActionButton = styled.div`
  display: flex;
  .ant-btn.ant-dropdown-trigger {
    margin-left: ${(props) => (props.isPurple ? '1.14rem' : '1.71rem')};
    padding: 0 2.29rem 0 1rem;
    height: 2.29rem;
    background: ${(props) => (props.isPurple ? props.theme.colors.purple : props.theme.colors.blue)};
    border: 1px solid ${(props) => (props.isPurple ? props.theme.colors.purple : props.theme.colors.blue)};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusLarge};

    &:hover {
      background: ${(props) => (props.isPurple ? props.theme.colors.purple : props.theme.colors.blue)};
    }
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: ${(props) => props.theme.colors.white};
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background-color: #9fa4ac33;
    div {
      color: #9fa4accc;
    }
    svg {
      path {
        fill: #9fa4accc;
      }
    }
    cursor: not-allowed;
    border: 1px solid transparent;
    > div:last-child {
      border-right: 1px solid #9fa4ac4d;
    }
  }
`;
const StyledRightDropdownActionButton = styled.div`
  position: relative;
  margin-top: 1.14rem;
  .ant-btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 0;
    border-radius: 21px;
    background: ${(props) => props.theme.colors.darkColor};
    border: none;
  }
  .ant-btn:not(:disabled):hover {
    background: ${(props) => props.theme.colors.darkColor};
    box-shadow: 0 2px 10px #00000045;
  }
  .ant-popover {
    top: 100% !important;
    width: 100%;
    .ant-popover-inner-content {
      max-width: unset;
      padding: 0 0 0.2rem;
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      overflow: hidden;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
`;
const StyledRightDropdownIconWrapper = styled.div`
  width: 2.85rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  border-left: 1px solid ${(props) => props.theme.colors.white}20;
  justify-content: center;
  background: ${(props) => props.theme.colors.darkColor};
  border-radius: ${(props) => `0 ${props.theme.customBorderRadius.borderRadiusLarge} ${props.theme.customBorderRadius.borderRadiusLarge} 0`}!important;
  div {
    transition: all 0.2s ease-in-out;
    display: flex;
    width: 100%;
    height: 100%;
    transform: ${(props) => (props.isVisible ? 'rotateZ(45deg)' : 'rotateZ(0deg)')};
    &:hover {
      transform: rotateZ(45deg);
    }
  }
`;
const StyledRightButtonText = styled.div`
  font-size: 1rem;
  padding: 0 1.71rem;
  line-height: 1.71rem;
  flex-grow: 1;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledLeftButtonText = styled.div`
  margin-left: 3.43rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const StyledLeftDropdownIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.85rem;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.white}30;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    transition: all 0.2s ease-in-out;
    display: flex;
    width: 100%;
    height: 100%;
     transform:  ${(props) => (props.isVisible ? 'rotateZ(-45deg)' : 'rotateZ(0deg)')};
     &:hover {
       transform: rotateZ(-45deg);
     }
   }
  }
`;

const StyledButtonGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledArrowDropdownButton = styled.div`
  padding: ${(props) => (props.isGenerate ? '0 1.14rem 0 0' : '0 0 0 1.14rem')};
  
  ${media.sm`
    padding-left: 0;
  `}
  
  .ant-dropdown-button {
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    transition: .3s opacity;
    flex-direction: row-reverse;
    
    .ant-btn {
      height: 2.29rem;
      font-size: 1rem;
      border: none;
      background: ${(props) => (props.isBlue ? props.theme.colors.blue : props.theme.colors.purple)};
      margin: 0;
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      color: ${(props) => props.theme.colors.white};
      
      span {
        line-height: 24px;
        transform: none;
        
        @media(max-width: 1200px){
          line-height: initial;
        }
      }
    }
  }
  .ant-dropdown-button > .ant-btn:last-child:not(:first-child) {
    border-radius: ${(props) => ` ${props.theme.customBorderRadius.borderRadiusLarge} 0 0 ${props.theme.customBorderRadius.borderRadiusLarge}`}; !important;
    width: 2.85rem;

    &:hover {
      border: none;
      background: ${(props) => (props.isBlue ? props.theme.colors.blue : props.theme.colors.purpleHover)};
    }
  }
  .ant-dropdown-button > .ant-btn:first-child:not(:last-child) {
    border-radius: ${(props) => `0 ${props.theme.customBorderRadius.borderRadiusLarge} ${props.theme.customBorderRadius.borderRadiusLarge} 0`};
    padding: ${(props) => (props.isGenerate ? '0 1.14rem' : '0 2rem')};
    &:hover {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => (props.isBlue ? props.theme.colors.blue : props.theme.colors.purpleHover)};
    }
    &:focus {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const StyledButtonLogin = styled(Button)`
  background: ${(props) => props.theme.colors.blue};
  padding: 0 2.28rem;
  width: ${(props) => (props.size === 'isMaxWidth' ? '100%' : '')};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  color: ${(props) => props.theme.colors.white};
  border-radius: 18px;
  border: none;
  box-shadow: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: initial;
  vertical-align: middle;
  box-sizing: border-box;
  margin-top: ${(props) => (props.isMarginTop ? '5.8rem' : '')};
  margin-left: ${(props) => (props.isMarginLeft ? '1.14rem' : '')};
  height: 2.57rem;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.hover};
  }
`;

const StyledPreviewButton = styled(Link)`
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: ${(props) => getButtonHeight(props.size)};
  line-height: ${(props) => getButtonHeight(props.size)};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  width: auto;
  padding: 0 2.29rem;
  height: 2.42rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.57rem;
  &:disabled {
    color: ${(props) => props.theme.colors.primaryBorder};
  }
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

const StyledBackTopButtonWrapper = styled.div`
  .ant-float-btn {
    right: 2.14rem;
    background-color: ${(props) => props.theme.colors.secondaryBg};
    z-index: 100;

    .ant-float-btn-body {
      background-color: ${(props) => props.theme.colors.secondaryBg};
    }
    svg {
      color: ${(props) => props.theme.colors.primaryText};
    }
  }
`;

const StyledManageTabItemsButton = styled(StyledButtonGrayBackground)`
  &.ant-btn {
    padding: 0 0.71rem;
    min-width: auto;
    margin-right: 0.57rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};

    &.ant-popover-open {
      background-color: ${(props) => props.theme.colors.grayButton};
    }

    svg {
      margin: 0;
    }
  }
`;

export {
  StyledButtonBlue,
  StyledButtonLogin,
  StyledButtonBordered,
  StyledButtonAttachment,
  StyledButtonGray,
  StyledButtonGrayBackground,
  StyledButtonGrayUnbordered,
  StyledButtonBlueUnbordered,
  StyledButtonWrapper,
  StyledMoreWrapper,
  StyledMoreItem,
  StyledButtonWrapperAuto,
  StyledButtonWrapperCentered,
  StyledButtonPurple,
  StyledButtonPurpleIcon,
  StyledLeftDropdownIconWrapper,
  StyledDropdownButton,
  StyledButtonGrayIcon,
  StyledLeftButtonText,
  StyledRightButtonText,
  StyledLeftDropdownActionButton,
  StyledRightDropdownActionButton,
  StyledRightDropdownIconWrapper,
  StyledButtonAttachFile,
  StyledPopoverContentItem,
  StyledPopoverItem,
  StyledSmallButtonBlue,
  StyledSmallButtonCancel,
  StyledButtonGroupRight,
  StyledAddSettingsModalFooterButtons,
  StyledLeftAutoButtonWrapper,
  StyledArrowDropdownButton,
  StyledButtonWrapperRight,
  StyledPreviewButton,
  StyledBackTopButtonWrapper,
  StyledManageTabItemsButton,
};
