import {
  put, call, takeLatest, all, select, takeEvery,
} from 'redux-saga/effects';
import qs from 'qs';
import NetworkService from 'services/networkService';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import DashboardActionType from 'actions/dashboard/dashboardActionType';
import { getError, getMessage } from 'utils/helpers';
import {
  saveDashboards, saveWidgets, saveLastRequestedWidget, saveWidgetData, saveWidgetLoading, saveApplyToWidget, changeDashboardEndedStatus,
} from 'actions/dashboard/dashboardActionCreator';
import { AppConstants } from 'constants/index';
import { i18n } from 'services';
import { selectDashboards, selectDashboardWidgetsList } from 'selectors';

function* getDashboards() {
  try {
    const { ReportingAdmin, Dashboards } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Dashboards]);
    yield put(saveDashboards(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createDashboard({ payload }) {
  try {
    const { data } = payload;
    const { name } = data;
    const { ReportingAdmin, Dashboards } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: id } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Dashboards], {
      data,
    });
    yield put(changeDashboardEndedStatus(true));

    const state = yield select();
    const dashboards = selectDashboards(state.dashboard);
    const newDashboards = [...dashboards];
    newDashboards.push({
      id,
      ...data,
    });
    yield put(saveDashboards(newDashboards));

    yield put(setSuccessMessage(getMessage(i18n.t('dashboard:dashboard'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteDashboard({ payload }) {
  try {
    const { name, id: dashboarId } = payload.data;
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Dashboards } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, Dashboards, dashboarId]);

    const state = yield select();
    const dashboards = selectDashboards(state.dashboard);
    const newDashboards = dashboards.filter(({ id }) => id !== dashboarId);
    yield put(saveDashboards(newDashboards));

    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('dashboard:lDashboard'), i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editDashboard({ payload }) {
  try {
    const { id, data } = payload;
    const { ReportingAdmin, Dashboards } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Dashboards, payload.id], { data });
    yield put(changeDashboardEndedStatus(true));

    const state = yield select();
    const dashboards = selectDashboards(state.dashboard);
    const newDashboards = dashboards.map((el) => (el.id === id ? { ...el, ...data } : el));
    yield put(saveDashboards(newDashboards));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWidgets() {
  try {
    const { ReportingAdmin, Dashboards, Widgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Dashboards, Widgets]);

    const newWidgets = {};
    data.forEach((el) => {
      newWidgets[el.id] = el;
    });
    yield put(saveWidgets(newWidgets));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createWidget({ payload }) {
  try {
    const { data, applyTo } = payload;
    const { name } = data;
    const { ReportingAdmin, Dashboards, Widgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: id } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Dashboards, Widgets], {
      data,
    });
    yield put(changeDashboardEndedStatus(true));

    const state = yield select();
    const widgets = selectDashboardWidgetsList(state.dashboard);
    const newWidgets = { ...widgets };
    newWidgets[id] = { id, ...data };
    yield put(saveWidgets(newWidgets));

    if (applyTo) {
      yield put(saveApplyToWidget(id));
    }

    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editWidget({ payload }) {
  try {
    const { id, data, forceUpdate } = payload;
    const { ReportingAdmin, Dashboards, Widgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Dashboards, Widgets, id], { data });
    yield put(changeDashboardEndedStatus(true));

    const state = yield select();
    const widgets = selectDashboardWidgetsList(state.dashboard);
    const newWidgets = { ...widgets };
    newWidgets[id] = { id, ...data };
    yield put(saveWidgets(newWidgets));
    if (forceUpdate) {
      yield put(saveLastRequestedWidget(id));
    }

    yield put(setSuccessMessage(getMessage(i18n.t('widget'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteWidget({ payload }) {
  try {
    const { widgetId, data: requestData } = payload;
    const { name, ...data } = requestData;
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Dashboards, RemoveWidget } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [ReportingAdmin, Dashboards, RemoveWidget, widgetId], { data });

    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));

    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWidgetData({ payload }) {
  const { id, params } = payload;

  try {
    const { category } = params;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin } = AppConstants.api;

    // loading
    yield put(saveWidgetLoading(id, true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, category], options);

    // data
    yield put(saveWidgetData(id, data));

    yield put(saveWidgetLoading(id, false));
  } catch (err) {
    if (err) {
      yield put(saveWidgetData(id, { error: true }));
    }
    yield put(saveWidgetLoading(id, false));
  }
}

export default function* dashboardSaga() {
  yield all([
    takeLatest(DashboardActionType.GET_DASHBOARDS, getDashboards),
    takeLatest(DashboardActionType.CREATE_DASHBOARD, createDashboard),
    takeLatest(DashboardActionType.DELETE_DASHBOARD, deleteDashboard),
    takeLatest(DashboardActionType.EDIT_DASHBOARD, editDashboard),
    takeLatest(DashboardActionType.GET_WIDGETS, getWidgets),
    takeLatest(DashboardActionType.CREATE_WIDGET, createWidget),
    takeLatest(DashboardActionType.EDIT_WIDGET, editWidget),
    takeLatest(DashboardActionType.DELETE_WIDGET, deleteWidget),
    takeEvery(DashboardActionType.GET_WIDGET_DATA, getWidgetData),
  ]);
}
