import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { StyledSelectWrapper } from 'components/styledComponents/select/Select.styled';

const { Option } = Select;

const AdminMultiSelect = (props) => {
  const {
    id, data, isToString, onChange: onChangeProps, onBlur: onBlurProps, value, isSelectAll, searchType, renderOptions, ...rest
  } = props;

  const { t } = useTranslation();

  const [filterState, setFilterState] = useState([]);
  const [options, setOptions] = useState(data);
  const all = useMemo(() => data.map((el) => (isToString ? el.id.toString() : el.id)), [isToString, data]);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  const unique = isSelectAll && filterState.length ? _.union(value, filterState) : all;
  const intersect = isSelectAll && filterState.length ? _.intersection(value, filterState) : all;

  const getSelectedIds = (idValues) => {
    if (isSelectAll && idValues?.includes('all')) {
      if (idValues.length === all.length + 1 && intersect.length === all.length) {
        return [];
      }

      if (!_.isEmpty(filterState)) {
        if (value.length && value.length === unique.length) {
          return _.difference(value, filterState);
        }
        return unique;
      }

      return all;
    }
    return idValues;
  };

  const matchSearch = (input, name) => name.toString().toLowerCase()[searchType](input.toLowerCase());

  const onChange = (values) => {
    onChangeProps(getSelectedIds(values));
    setFilterState([]);
  };

  const onSearch = isSelectAll
    ? (input) => {
      const filterData = data.reduce((acc, curr) => {
        if (input && matchSearch(input, curr.name)) {
          acc.push(isToString ? curr.id.toString() : curr.id);
        }
        return acc;
      }, []);

      setFilterState(filterData);
    }
    : null;

  const filterOption = (input, option) => {
    if (option.key === 'all') {
      return !!data.find((el) => matchSearch(input, el.name));
    }
    return matchSearch(input, option.children);
  };

  const onBlur = (e) => {
    onBlurProps?.(e);
    setFilterState([]);
  };

  const onDropdownVisibleChange = (open) => {
    if (open) {
      // Form.Item name prop value === id
      const selectedSet = new Set(id ? value : value.map(String));
      setOptions(
        selectedSet.size
          ? data.toSorted((a, b) => {
            const aSelected = selectedSet.has(a.id.toString());
            const bSelected = selectedSet.has(b.id.toString());

            if (!(aSelected || bSelected) || (aSelected && bSelected)) {
              return 0;
            }
            return aSelected && !bSelected ? -1 : 1;
          })
          : data,
      );
    }
  };

  return (
    <StyledSelectWrapper
      mode="multiple"
      popupClassName="multi-select"
      filterOption={filterOption}
      onSearch={onSearch}
      onBlur={onBlur}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      value={value}
      {...rest}
    >
      {data.length && isSelectAll && (
        <Option
          key="all"
          value="all"
          className={`${value?.length && unique.length === value?.length ? 'ant-select-item-option-selected' : ''}
                  ${value?.length && value?.length < unique.length && intersect.length ? 'ant-select-option-indeterminate' : ''}`}
        >
          <span>{t('selectAll')}</span>
        </Option>
      )}
      {renderOptions(options)}
    </StyledSelectWrapper>
  );
};

AdminMultiSelect.propTypes = {
  id: PropTypes.string,
  all: PropTypes.array,
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isToString: PropTypes.bool,
  value: PropTypes.array,
  isSelectAll: PropTypes.bool,
  searchType: PropTypes.string,
  onBlur: PropTypes.func,
  renderOptions: PropTypes.func.isRequired,
};

AdminMultiSelect.defaultProps = {
  id: '',
  all: [],
  data: [],
  isToString: true,
  value: [],
  isSelectAll: true,
  searchType: 'startsWith',
  onBlur: null,
};

export default AdminMultiSelect;
