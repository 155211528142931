import qs from 'qs';
import {
  takeLatest, all, put, call, select,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import _ from 'lodash';
import PlayerActionType from 'actions/player/playerActionType';
import NetworkService from 'services/networkService';
import {
  getPlayersSuccess,
  changePlayerLoadingState,
  savePlayer,
  getCsvPlayersSuccess,
  saveBrandPlayersResource,
  getPlayerNotesSuccess,
  getPlayerNoteTypesSuccess,
  changePlayerModalVisibility,
  changePlayerModalLoadingState,
  savePlayerMessages,
  savePlayerMessage,
  savePlayerMessagesTotal,
  changeEndedStatus as changePlayerEndedStatus,
  savePlayerData,
  getPlayersBulkStatusUpdateHistorySuccess,
  getBulkStatusUpdatedPlayersSuccess,
  savePlayerActions,
  saveTestPlayers,
  saveTestPlayerUsers,
  changePlayerDataUpdatedStatus,
  savePlayerSelfExclusionSettings,
  savePlayerTimeoutSettings,
  savePlayerDepositSettings,
  savePlayerWithdrawalSettings,
  savePlayerBetSettings,
  savePlayerResponsibleGamingLogs,
  saveResponsibleGamingReminderLimits,
  savePlayerControls,
  resetPlayer,
  savePlayerSystemNotifications,
  savePlayerSystemNotification,
  savePlayerPersonalInfo,
  savePlayerAccountInfo,
  changePlayerAccountInfoUpdatedStatus,
  changePlayerPersonalInfoUpdatedStatus,
  changePlayerActionEndedStatus,
  savePlayerRealityCheckSettings,
  changePlayerLimitsLoading,
  savePlayerAccessLimitSettings,
  savePlayerAccessLimitOverviewSettings,
  savePlayerAccessLimitPendingSettings,
  savePlayerAccessLimitSettingsLog,
  savePlayerLossLimitSettings,
  savePlayerSingleBetSettings,
  saveHasPlayerTransaction,
  changeHasPlayerTransactionLoadingState,
  savePlayerGameTimeLimitSettings,
} from 'actions/player/playerActionCreator';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import enumTypes from 'constants/enumTypes';
import { playerStatusActionMap, playerBlockedStatuses } from 'constants/mapping';
import { downloadFile } from 'utils/utils';
import {
  selectPlayerAccessLimitSettings, selectPlayerAccountBalance, selectPlayerData, selectPlayerMessages, selectPlayerMessagesTotal, selectPlayerWithdrawalSettings,
} from 'selectors';
import { i18n, MessageService } from 'services';
import Config from 'config';
import { getError, getMessage, getResourceValue } from 'utils/helpers';

const { isBrRegulationAudit, brRegulationSpecialActionsBaseURL } = Config;

function* getPlayerData({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, GetPlayerData } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, GetPlayerData], { params: payload });
    yield put(savePlayerData(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayers({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, GetReport } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, GetReport, payload.brandId], options);
    yield put(getPlayersSuccess(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* generatePlayersReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, Generate } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Generate], options);
    yield put(changeLoadingState(false));
    yield put(changeGenerateReportEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayer({ payload }) {
  try {
    const { id, data: params } = payload;
    yield put(changePlayerLoadingState(true));
    const { PlayersAdmin, Player } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, id], { params });
    yield put(savePlayer(response.data));
    yield put(changePlayerLoadingState(false));
  } catch (err) {
    if (err) {
      if (!payload.showError) {
        const error = getError(err, true);
        if (error.status === AppConstants.notFoundStatusCode) {
          yield put(resetPlayer());
        }
      } else {
        const error = getError(err);
        yield put(setErrorMessage(error));
      }
    }
    yield put(changePlayerLoadingState(false));
  }
}

function* editPlayer({ payload }) {
  try {
    const { playerId, data } = payload;
    const { firstName, lastName } = data;
    const { PlayersAdmin, Player } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, playerId], { data });
    yield put(changeLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(changePlayerPersonalInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lPlayer'), i18n.t('notification:slUpdated'), `${!_.isNil(firstName) ? firstName : '-'} ${!_.isNil(lastName) ? lastName : '-'}`)));
  } catch (err) {
    if (err) {
      let error = getError(err);
      const errorCodes = ['PlayerMobileNumberInUse', 'PlayerEmailInUse'];
      const isDuplicatedPlayer = err?.response?.data.some(({ errorCode }) => errorCodes.includes(errorCode));
      if (isDuplicatedPlayer) {
        error = {
          errors: err?.response?.data.map((el) => ({
            ...el,
            errorMessage: i18n.t(`player:${el.errorCode}`),
          })),
        };
      }
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPlayerNotificationPreferences({ payload }) {
  try {
    const { playerId, data } = payload;
    const { PlayersAdmin, Player, NotificationPreference } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, NotificationPreference, playerId], { data });
    const state = yield select();
    const accountBalance = selectPlayerAccountBalance(state.player);
    yield put(
      savePlayerData({
        accountBalance,
        player: data,
      }),
    );
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvPlayers({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, GetCSVPlayers } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Player, GetCSVPlayers], options);
    yield put(getCsvPlayersSuccess(data));
    yield put(changePlayerEndedStatus(true));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changePlayerEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandPlayersResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { id, data } = payload;
    const options = {
      data,
      paramsSerializer: (param) => qs.stringify(param),
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { PlayersAdmin, Player, ByBrand } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Player, ByBrand, id], options);
    yield put(saveBrandPlayersResource(response.data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPlayerNotes({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    const { PlayersAdmin, PlayerNote } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, PlayerNote], options);
    yield put(getPlayerNotesSuccess(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerNoteTypes({ payload }) {
  const { isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { PlayersAdmin, PlayerNote, GetNoteTypes } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, PlayerNote, GetNoteTypes]);
    yield put(getPlayerNoteTypesSuccess(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* exportPlayerNotes({ payload }) {
  try {
    const options = {
      params: payload.data,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, Export } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Export, payload.playerId], options);
    downloadFile(data, i18n.t('player:playerNotes'), mime.detectExtension(headers['content-type']));
    yield put(changePlayerEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addPlayerNote({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { data, playerId } = payload;
    const { PlayersAdmin, PlayerNote } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, PlayerNote, playerId], { data });
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('player:notes'), i18n.t('notification:slAdded'))));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerInboxMessages({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, Inbox, GetByPlayer } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, Inbox, GetByPlayer, payload.playerId], options);
    const { data, total } = response.data;
    const state = yield select();
    const messagesState = selectPlayerMessages(state.player);
    const totalState = selectPlayerMessagesTotal(state.player);
    yield put(
      savePlayerMessages({
        ...messagesState,
        inbox: data,
      }),
    );
    yield put(
      savePlayerMessagesTotal({
        ...totalState,
        inbox: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPlayerMassMessages({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, ByPlayer } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, ByPlayer, payload.playerId], options);
    const { data, total } = response.data;
    const state = yield select();
    const messagesState = selectPlayerMessages(state.player);
    const totalState = selectPlayerMessagesTotal(state.player);
    yield put(
      savePlayerMessages({
        ...messagesState,
        sent: data,
      }),
    );
    yield put(
      savePlayerMessagesTotal({
        ...totalState,
        sent: total,
      }),
    );
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPlayerMessage({ payload }) {
  try {
    const { playerId, messageId } = payload;
    const params = { messageId };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, MassMessages, MessageByPlayer } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, MassMessages, MessageByPlayer, playerId], { params });
    yield put(savePlayerMessage(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    yield put(changeLoadingState(false));
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
  }
}

function* playerSendMessage({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { NotificationAdmin, Inbox } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [NotificationAdmin, Inbox], payload);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('communication:message'), i18n.t('notification:slSent'))));
    yield put(changePlayerEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerControls({ payload }) {
  try {
    const { BuilderAdmin, UiCustomization, GetPlayerProfileControls } = AppConstants.api;
    yield put(changePlayerLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, UiCustomization, GetPlayerProfileControls], payload);
    yield put(savePlayerControls(response.data));
    yield put(changePlayerLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLoadingState(false));
  }
}

function* editPlayerMarkAttention({ payload }) {
  try {
    const { PlayersAdmin, Actions, Attention } = AppConstants.api;
    const { params, data, playerId } = payload;
    const options = {
      data,
      params,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Attention, playerId], options);
    yield put(changeLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    const attentionLevel = +params.level;
    if (attentionLevel) {
      yield put(setSuccessMessage(i18n.t('notification:setMarkAttention', { name: getResourceValue('markAttentionLevels', attentionLevel, enumTypes.markAttentionLevels).toLowerCase() })));
    } else {
      yield put(setSuccessMessage(getMessage(i18n.t('player:markAttention'), i18n.t('notification:slRemoved'))));
    }
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* unlockPlayer({ payload }) {
  try {
    const { PlayersAdmin, Actions, Unlock } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Unlock, payload.playerId]);
    yield put(changeLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slUnlocked'))));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* blockPlayerForMessages({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, Actions, BlockInbox } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, BlockInbox, payload.playerId], options);
    yield put(changeLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:accountBlockedForMessages', { action: i18n.t(payload.params.block ? 'notification:slBlocked' : 'notification:slUnblocked') })));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePlayerStatus({ payload }) {
  try {
    const { PlayersAdmin, Actions, Status } = AppConstants.api;
    const { params, data, playerId } = payload;
    const options = {
      data,
      params,
      ...{ baseURL: isBrRegulationAudit && brRegulationSpecialActionsBaseURL },
    };
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Status, playerId], options);
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerAccountInfoUpdatedStatus(true));
    yield put(changeLoadingState(false));
    if (params.isSet === false) {
      yield put(setSuccessMessage(getMessage(i18n.t('status'), i18n.t('notification:slChanged'))));
    } else if (+params.status === enumTypes.playerStatuses.Active) {
      if (response.data) {
        yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slActivated'))));
      } else {
        yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slReactivated'))));
      }
    } else {
      yield put(setSuccessMessage(i18n.t('notification:changeAccountStatus', { action: getResourceValue('lPlayerBlockedStatuses', params.status, _.invert(playerBlockedStatuses)) })));
    }
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersBulkStatusUpdateHistory({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const {
      PlayersAdmin, Player, BulkActions, StatusUpdates,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, BulkActions, StatusUpdates, payload.brandId], options);
    yield put(getPlayersBulkStatusUpdateHistorySuccess(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBulkStatusUpdatedPlayers({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, BulkActionDetails } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, BulkActionDetails, payload.id], options);
    yield put(getBulkStatusUpdatedPlayersSuccess(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changePlayersStatusesInBulk({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, PlayerBulk, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, PlayerBulk, Status, payload.brandId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:bulkStatusUpdate', { action: getResourceValue('playerActions', [playerStatusActionMap[payload.data.newStatus]], enumTypes.playerActions) })));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerActions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changePlayerLoadingState(true));
    const { ReportingAdmin, Player, Actions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Actions, payload.id], options);
    yield put(changePlayerLoadingState(false));
    yield put(changePlayerModalVisibility(false));
    yield put(savePlayerActions(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLoadingState(false));
  }
}

function* exportPlayerActions({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, Actions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Actions, Export, id], options);
    downloadFile(data, i18n.t('player:actionLogs'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* closePlayerAccount({ payload }) {
  try {
    const { data, playerId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Actions } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [PlayersAdmin, Actions, playerId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    const state = yield select();
    let player = selectPlayerData(state.player);
    player = { ...player, isDeleted: true };
    const accountBalance = selectPlayerAccountBalance(state.player);
    const tempData = { player, accountBalance };
    yield put(savePlayerData(tempData));
    yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slClosed'))));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* activateDormantPlayer({ payload }) {
  try {
    const { playerId } = payload;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Actions, Dormant } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Dormant, playerId]);
    yield put(changeLoadingState(false));
    const state = yield select();
    let player = selectPlayerData(state.player);
    player = { ...player, isDormant: false };
    const accountBalance = selectPlayerAccountBalance(state.player);
    const tempData = { player, accountBalance };
    yield put(savePlayerData(tempData));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('account'), i18n.t('notification:slActivated'))));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addTestPlayer({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, AddTestPlayer } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Player, AddTestPlayer], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:testPlayersAccount'), i18n.t('notification:slCreated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTestPlayers({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changePlayerLoadingState(true));
    const { ReportingAdmin, Player, GetTestPlayers } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, GetTestPlayers], options);
    yield put(changePlayerLoadingState(false));
    yield put(saveTestPlayers(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLoadingState(false));
  }
}

function* assignTestPlayerToUser({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, AddTestPlayerUser } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Player, AddTestPlayerUser], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:testPlayersAccount'), i18n.t('notification:slAssigned'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishUseTestPlayer({ payload }) {
  try {
    const { params } = payload;
    const options = { params };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, FinishUseTestPlayer } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, FinishUseTestPlayer], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:finishedTestPlayersAccountUse')));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* adjustTestPlayerBalance({ payload }) {
  try {
    const { data } = payload;
    const options = { data };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, AdjustBalance } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, AdjustBalance], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:adjustedTestPlayersAccountBalance')));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTestPlayerUsers({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, GetTestUsers } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, GetTestUsers], options);
    yield put(changeLoadingState(false));
    yield put(saveTestPlayerUsers(data));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editTestUser({ payload }) {
  try {
    const { data, id } = payload;
    const options = { data, params: { id } };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, EditTestPlayerUser } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, EditTestPlayerUser], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:accountUsersInformation'), i18n.t('notification:slUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updatePlayerVerification({ payload }) {
  try {
    const { playerId, params } = payload;
    const { isSet, name, verification } = params;
    const options = {
      params: { verification, isSet },
      ...{ baseURL: isBrRegulationAudit && brRegulationSpecialActionsBaseURL },
    };
    yield put(changePlayerModalLoadingState(true));
    const { PlayersAdmin, Player, Verification } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, playerId, Verification], options);
    yield put(changePlayerModalLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(isSet ? 'notification:slVerified' : 'notification:slUnverified'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerModalLoadingState(false));
  }
}

function* updatePlayerContactInfoVerification({ payload }) {
  try {
    const { playerId, params } = payload;
    const { isSet, name, verification } = params;
    yield put(changePlayerModalLoadingState(true));
    const {
      PlayersAdmin, Player, Verification, ContactInfo,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Player, playerId, Verification, ContactInfo], { params: { verification, isSet } });
    yield put(changePlayerModalLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(name, i18n.t(isSet ? 'notification:slVerified' : 'notification:slUnverified'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerModalLoadingState(false));
  }
}

function* getPlayerSelfExclusionSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, SelfExclusion } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SelfExclusion, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, SelfExclusion], options);
    yield put(savePlayerSelfExclusionSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SelfExclusion, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SelfExclusion, false));
  }
}

function* editPlayerSelfExclusionSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, SelfExclusion } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, SelfExclusion], options);
    yield put(savePlayerSelfExclusionSettings(data));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.SelfExclusion, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerTimeoutSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Timeout } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Timeout, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Timeout], options);
    yield put(savePlayerTimeoutSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Timeout, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Timeout, false));
  }
}

function* editPlayerTimeoutSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Timeout } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Timeout], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerTimeoutSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.Timeout, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerDepositSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Deposit } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Deposit, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Deposit], options);
    yield put(savePlayerDepositSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Deposit, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Deposit, false));
  }
}

function* editPlayerDepositSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Deposit } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Deposit], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerDepositSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.Deposit, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerWithdrawalSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Withdrawal } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Withdrawal, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Withdrawal], options);
    yield put(savePlayerWithdrawalSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Withdrawal, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Withdrawal, false));
  }
}

function* editPlayerWithdrawalSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Withdrawal } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Withdrawal], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerWithdrawalSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.Withdrawal, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerBetSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Bet } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Bet, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Bet], options);
    yield put(savePlayerBetSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Bet, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.Bet, false));
  }
}

function* editPlayerBetSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Bet } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Bet], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerBetSettings(data));
    yield put(changeLoadingState(false));
    const state = yield select();
    const settings = selectPlayerWithdrawalSettings(state.player);
    yield put(
      setSuccessMessage(
        getMessage(
          getResourceValue('limitationTypes', enumTypes.limitationTypes.Bet, enumTypes.limitationTypes),
          i18n.t(Object.keys(settings).length ? 'notification:slUpdated' : 'notification:slSet'),
        ),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerRealityCheckSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, RealityCheck } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.RealityCheck, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, RealityCheck], options);
    yield put(savePlayerRealityCheckSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.RealityCheck, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.RealityCheck, false));
  }
}

function* editPlayerRealityCheckSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, RealityCheck } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, RealityCheck], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerRealityCheckSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.RealityCheck, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerResponsibleGamingLogs({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, ResponsibleGaming, ResponsibleGamingHistory } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, ResponsibleGaming, ResponsibleGamingHistory], options);
    yield put(savePlayerResponsibleGamingLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerResponsibleGamingReminderLimits({ payload }) {
  try {
    const { playerId } = payload;
    yield put(changeLoadingState(true));
    const { ReportingAdmin, ResponsibleGaming, Remainder } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, ResponsibleGaming, Remainder, playerId]);
    yield put(saveResponsibleGamingReminderLimits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSystemNotifications({ payload }) {
  try {
    const { params, playerId } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SystemTemplate, ByPlayer } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, ByPlayer, playerId], options);
    yield put(savePlayerSystemNotifications(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSystemNotification({ payload }) {
  try {
    const { messageId } = payload;
    yield put(changeLoadingState(true));
    const { NotificationAdmin, SystemTemplate, ByNotification } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, SystemTemplate, ByNotification, messageId]);
    yield put(savePlayerSystemNotification(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerPersonalInfo({ payload }) {
  const { id, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { ReportingAdmin, Player, Personal } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Personal]);

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
    yield put(savePlayerPersonalInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPlayerAccountInfo({ payload }) {
  const { isLoading, id } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { ReportingAdmin, Player, Account } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Account]);

    if (isLoading) {
      yield put(changeLoadingState(false));
    }

    yield put(savePlayerAccountInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* resetTestPlayerPassword({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, ResetTestPlayerUserPassword } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Player, ResetTestPlayerUserPassword], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(i18n.t('notification:testPlayerResetPassword')));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* disablePlayerLoginTFA({ payload }) {
  try {
    const { PlayersAdmin, Actions, Reset2FA } = AppConstants.api;
    const { data, playerId } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [PlayersAdmin, Actions, Reset2FA, playerId], options);
    yield put(changeLoadingState(false));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:tFA'), i18n.t('notification:slDisabled'))));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAccessLimitSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, AccessLimit } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.AccessLimit, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, AccessLimit], options);
    yield put(savePlayerAccessLimitSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.AccessLimit, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.AccessLimit, false));
  }
}

function* getPlayerAccessLimitOverviewSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, AccessLimitOverview } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, AccessLimitOverview], options);
    yield put(savePlayerAccessLimitOverviewSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAccessLimitPendingSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, AccessLimitPending } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, AccessLimitPending], options);
    yield put(savePlayerAccessLimitPendingSettings(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editPlayerAccessLimitSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, AccessLimit } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, AccessLimit], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerAccessLimitSettings(data));
    yield put(changeLoadingState(false));
    const state = yield select();
    const settings = selectPlayerAccessLimitSettings(state.player);
    yield put(
      setSuccessMessage(
        getMessage(
          getResourceValue('limitationTypes', enumTypes.limitationTypes.AccessLimit, enumTypes.limitationTypes),
          i18n.t(Object.keys(settings).length ? 'notification:slUpdated' : 'notification:slSet'),
        ),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerAccessLimitSettingsLog({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { ReportingAdmin, ResponsibleGaming, AccessLimitLog } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, ResponsibleGaming, AccessLimitLog], options);
    yield put(savePlayerAccessLimitSettingsLog(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerLossLimitSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Loss } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.LossLimit, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Loss], options);
    yield put(savePlayerLossLimitSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.LossLimit, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.LossLimit, false));
  }
}

function* editPlayerLossLimitSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Loss } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Loss], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerLossLimitSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.LossLimit, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSingleBetSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, SingleBet } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SingleBet, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, SingleBet], options);
    yield put(savePlayerSingleBetSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SingleBet, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.SingleBet, false));
  }
}

function* editPlayerSingleBetSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, SingleBet } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, SingleBet], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerLossLimitSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.SingleBet, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getHasPlayerTransaction({ payload }) {
  try {
    const { playerId } = payload;
    const { ReportingAdmin, Player, HasTransaction } = AppConstants.api;
    yield put(changeHasPlayerTransactionLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, playerId, HasTransaction]);
    yield put(saveHasPlayerTransaction(data));
    yield put(changeHasPlayerTransactionLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeHasPlayerTransactionLoadingState(false));
  }
}

function* getPlayerGameTimeLimitSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
    };
    const { PlayersAdmin, ResponsibleGaming, Time } = AppConstants.api;

    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.GameTimeLimit, true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, ResponsibleGaming, Time], options);
    yield put(savePlayerGameTimeLimitSettings(data));
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.GameTimeLimit, false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerLimitsLoading(enumTypes.limitationTypes.GameTimeLimit, false));
  }
}

function* editPlayerGameTimeLimitSettings({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, ResponsibleGaming, Time } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, ResponsibleGaming, Time], options);
    yield put(changeEndedStatus(true));
    yield put(savePlayerGameTimeLimitSettings(data));
    yield put(changeLoadingState(false));
    yield put(
      setSuccessMessage(
        getMessage(getResourceValue('limitationTypes', enumTypes.limitationTypes.GameTimeLimit, enumTypes.limitationTypes), i18n.t(payload.isEdit ? 'notification:slUpdated' : 'notification:slSet')),
      ),
    );
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* playerSaga() {
  yield all([
    takeLatest(PlayerActionType.GET_PLAYERS, getPlayers),
    takeLatest(PlayerActionType.GENERATE_PLAYERS_REPORT, generatePlayersReport),
    takeLatest(PlayerActionType.GET_PLAYER, getPlayer),
    takeLatest(PlayerActionType.GET_PLAYER_DATA, getPlayerData),
    takeLatest(PlayerActionType.EDIT_PLAYER, editPlayer),
    takeLatest(PlayerActionType.EDIT_PLAYER_NOTIFICATION_PREFERENCE, editPlayerNotificationPreferences),
    takeLatest(PlayerActionType.GET_CSV_PLAYERS, getCsvPlayers),
    takeLatest(PlayerActionType.GET_BRAND_PLAYERS_RESOURCE, getBrandPlayersResource),
    takeLatest(PlayerActionType.GET_PLAYER_NOTES, getPlayerNotes),
    takeLatest(PlayerActionType.GET_PLAYER_NOTE_TYPES, getPlayerNoteTypes),
    takeLatest(PlayerActionType.EXPORT_PLAYER_NOTES, exportPlayerNotes),
    takeLatest(PlayerActionType.ADD_PLAYER_NOTE, addPlayerNote),
    takeLatest(PlayerActionType.GET_PLAYER_INBOX_MESSAGES, getPlayerInboxMessages),
    takeLatest(PlayerActionType.GET_PLAYER_MASS_MESSAGES, getPlayerMassMessages),
    takeLatest(PlayerActionType.GET_PLAYER_MESSAGE, getPlayerMessage),
    takeLatest(PlayerActionType.PLAYER_SEND_MESSAGE, playerSendMessage),
    takeLatest(PlayerActionType.GET_PLAYER_CONTROLS, getPlayerControls),
    takeLatest(PlayerActionType.EDIT_PLAYER_MARK_ATTENTION, editPlayerMarkAttention),
    takeLatest(PlayerActionType.UNLOCK_PLAYER, unlockPlayer),
    takeLatest(PlayerActionType.BLOCK_PLAYER_FOR_MESSAGES, blockPlayerForMessages),
    takeLatest(PlayerActionType.CHANGE_PLAYER_STATUS, changePlayerStatus),
    takeLatest(PlayerActionType.GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY, getPlayersBulkStatusUpdateHistory),
    takeLatest(PlayerActionType.GET_BULK_STATUS_UPDATED_PLAYERS, getBulkStatusUpdatedPlayers),
    takeLatest(PlayerActionType.CHANGE_PLAYERS_STATUS_IN_BULK, changePlayersStatusesInBulk),
    takeLatest(PlayerActionType.GET_PLAYER_ACTIONS, getPlayerActions),
    takeLatest(PlayerActionType.CLOSE_PLAYER_ACCOUNT, closePlayerAccount),
    takeLatest(PlayerActionType.ACTIVATE_DORMANT_PLAYER, activateDormantPlayer),
    takeLatest(PlayerActionType.ADD_TEST_PLAYER, addTestPlayer),
    takeLatest(PlayerActionType.GET_TEST_PLAYERS, getTestPlayers),
    takeLatest(PlayerActionType.ASSIGN_TEST_PLAYER_TO_USER, assignTestPlayerToUser),
    takeLatest(PlayerActionType.FINISH_USE_TEST_PLAYER, finishUseTestPlayer),
    takeLatest(PlayerActionType.ADJUST_TEST_PLAYER_BALANCE, adjustTestPlayerBalance),
    takeLatest(PlayerActionType.GET_TEST_PLAYER_USERS, getTestPlayerUsers),
    takeLatest(PlayerActionType.EDIT_TEST_USER, editTestUser),
    takeLatest(PlayerActionType.UPDATE_PLAYER_VERIFICATION, updatePlayerVerification),
    takeLatest(PlayerActionType.UPDATE_PLAYER_CONTACT_INFO_VERIFICATION, updatePlayerContactInfoVerification),
    takeLatest(PlayerActionType.GET_PLAYER_SELF_EXCLUSION_SETTINGS, getPlayerSelfExclusionSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_SELF_EXCLUSION_SETTINGS, editPlayerSelfExclusionSettings),
    takeLatest(PlayerActionType.GET_PLAYER_TIMOUT_SETTINGS, getPlayerTimeoutSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_TIMOUT_SETTINGS, editPlayerTimeoutSettings),
    takeLatest(PlayerActionType.GET_PLAYER_DEPOSIT_SETTINGS, getPlayerDepositSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_DEPOSIT_SETTINGS, editPlayerDepositSettings),
    takeLatest(PlayerActionType.GET_PLAYER_WITHDRAWAL_SETTINGS, getPlayerWithdrawalSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_WITHDRAWAL_SETTINGS, editPlayerWithdrawalSettings),
    takeLatest(PlayerActionType.GET_PLAYER_BET_SETTINGS, getPlayerBetSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_BET_SETTINGS, editPlayerBetSettings),
    takeLatest(PlayerActionType.GET_PLAYER_REALITY_CHECK_SETTINGS, getPlayerRealityCheckSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_REALITY_CHECK_SETTINGS, editPlayerRealityCheckSettings),
    takeLatest(PlayerActionType.GET_RESPONSIBLE_GAMING_LOGS, getPlayerResponsibleGamingLogs),
    takeLatest(PlayerActionType.GET_RESPONSIBLE_GAMING_REMINDER_LIMITS, getPlayerResponsibleGamingReminderLimits),
    takeLatest(PlayerActionType.EXPORT_PLAYER_ACTIONS, exportPlayerActions),
    takeLatest(PlayerActionType.GET_PLAYER_SYSTEM_NOTIFICATIONS, getPlayerSystemNotifications),
    takeLatest(PlayerActionType.GET_PLAYER_SYSTEM_NOTIFICATION, getPlayerSystemNotification),
    takeLatest(PlayerActionType.GET_PLAYER_ACCOUNT_INFO, getPlayerAccountInfo),
    takeLatest(PlayerActionType.GET_PLAYER_PERSONAL_INFO, getPlayerPersonalInfo),
    takeLatest(PlayerActionType.RESET_TEST_PLAYER_PASSWORD, resetTestPlayerPassword),
    takeLatest(PlayerActionType.DISABLE_PLAYER_LOGIN_TFA, disablePlayerLoginTFA),
    takeLatest(PlayerActionType.GET_PLAYER_ACCESS_LIMIT_SETTINGS, getPlayerAccessLimitSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_ACCESS_LIMIT_SETTINGS, editPlayerAccessLimitSettings),
    takeLatest(PlayerActionType.GET_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS, getPlayerAccessLimitOverviewSettings),
    takeLatest(PlayerActionType.GET_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS, getPlayerAccessLimitPendingSettings),
    takeLatest(PlayerActionType.GET_PLAYER_ACCESS_LIMIT_SETTINGS_LOG, getPlayerAccessLimitSettingsLog),
    takeLatest(PlayerActionType.GET_PLAYER_LOSS_LIMIT_SETTINGS, getPlayerLossLimitSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_LOSS_LIMIT_SETTINGS, editPlayerLossLimitSettings),
    takeLatest(PlayerActionType.GET_PLAYER_SINGLE_BET_SETTINGS, getPlayerSingleBetSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_SINGLE_BET_SETTINGS, editPlayerSingleBetSettings),
    takeLatest(PlayerActionType.GET_HAS_PLAYER_TRANSACTION, getHasPlayerTransaction),
    takeLatest(PlayerActionType.GET_PLAYER_GAME_TIME_LIMIT_SETTINGS, getPlayerGameTimeLimitSettings),
    takeLatest(PlayerActionType.EDIT_PLAYER_GAME_TIME_LIMIT_SETTINGS, editPlayerGameTimeLimitSettings),
  ]);
}
