import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import NetworkService from 'services/networkService';
import { getError } from 'utils/helpers';
import { changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import qs from 'qs';
import { saveSelfAssessments } from 'actions/selfAssessment/selfAssessmentActionCreator';
import SelfAssessmentActionType from 'actions/selfAssessment/selfAssessmentActionType';

function* getSelfAssessments({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    const { ReportingAdmin, PlayerSelfAssessments } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PlayerSelfAssessments], options);
    yield put(saveSelfAssessments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateSelfAssessmentsReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, PlayerSelfAssessments, Generate } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PlayerSelfAssessments, Generate], options);
    yield put(changeLoadingState(false));
    yield put(changeGenerateReportEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* selfAssessmentsSaga() {
  yield all([takeLatest(SelfAssessmentActionType.GET_SELF_ASSESSMENTS, getSelfAssessments), takeLatest(SelfAssessmentActionType.GENERATE_SELF_ASSESSMENTS_REPORT, generateSelfAssessmentsReport)]);
}
