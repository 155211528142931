const ResourceActionType = {
  GET_RESOURCE_PAYMENT_METHODS: 'GET_RESOURCE_PAYMENT_METHODS',
  SAVE_RESOURCE_PAYMENT_METHODS: 'SAVE_RESOURCE_PAYMENT_METHODS',
  RESET_RESOURCE_PAYMENT_METHODS: 'RESET_RESOURCE_PAYMENT_METHODS',

  GET_RESOURCE_COUNTRIES: 'GET_RESOURCE_COUNTRIES',
  SAVE_RESOURCE_COUNTRIES: 'SAVE_RESOURCE_COUNTRIES',
  RESET_RESOURCE_COUNTRIES: 'RESET_RESOURCE_COUNTRIES',

  GET_RESOURCE_SEGMENTS: 'GET_RESOURCE_SEGMENTS',
  SAVE_RESOURCE_SEGMENTS: 'SAVE_RESOURCE_SEGMENTS',
  RESET_RESOURCE_SEGMENTS: 'RESET_RESOURCE_SEGMENTS',

  GET_RESOURCE_TAGS: 'GET_RESOURCE_TAGS',
  SAVE_RESOURCE_TAGS: 'SAVE_RESOURCE_TAGS',
  RESET_RESOURCE_TAGS: 'RESET_RESOURCE_TAGS',

  GET_RESOURCE_GAME_CATEGORIES: 'GET_RESOURCE_GAME_CATEGORIES',
  SAVE_RESOURCE_GAME_CATEGORIES: 'SAVE_RESOURCE_GAME_CATEGORIES',
  RESET_RESOURCE_GAME_CATEGORIES: 'RESET_RESOURCE_GAME_CATEGORIES',
  CHANGE_RESOURCE_GAME_CATEGORIES_STATE: 'CHANGE_RESOURCE_GAME_CATEGORIES_STATE',

  GET_RESOURCE_USERS: 'GET_RESOURCE_USERS',
  SAVE_RESOURCE_USERS: 'SAVE_RESOURCE_USERS',
  RESET_RESOURCE_USERS: 'RESET_RESOURCE_USERS',

  GET_RESOURCE_PAYMENT_METHOD_CURRENCIES: 'GET_RESOURCE_PAYMENT_METHOD_CURRENCIES',
  SAVE_RESOURCE_PAYMENT_METHOD_CURRENCIES: 'SAVE_RESOURCE_PAYMENT_METHOD_CURRENCIES',
  RESET_RESOURCE_PAYMENT_METHOD_CURRENCIES: 'RESET_RESOURCE_PAYMENT_METHOD_CURRENCIES',

  GET_RESOURCE_CURRENCIES: 'GET_RESOURCE_CURRENCIES',
  SAVE_RESOURCE_CURRENCIES: 'SAVE_RESOURCE_CURRENCIES',
  RESET_RESOURCE_CURRENCIES: 'RESET_RESOURCE_CURRENCIES',

  GET_RESOURCE_PROVIDERS: 'GET_RESOURCE_PROVIDERS',
  SAVE_RESOURCE_PROVIDERS: 'SAVE_RESOURCE_PROVIDERS',
  RESET_RESOURCE_PROVIDERS: 'RESET_RESOURCE_PROVIDERS',

  SAVE_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS: 'SAVE_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS',
  RESET_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS: 'RESET_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS',

  GET_RESOURCE_GAMES: 'GET_RESOURCE_GAMES',
  SAVE_RESOURCE_GAMES: 'SAVE_RESOURCE_GAMES',
  RESET_RESOURCE_GAMES: 'RESET_RESOURCE_GAMES',

  GET_RESOURCE_REASONS: 'GET_RESOURCE_REASONS',
  SAVE_RESOURCE_REASONS: 'SAVE_RESOURCE_REASONS',
  RESET_RESOURCE_REASONS: 'RESET_RESOURCE_REASONS',

  GET_RESOURCE_CAMPAIGNS: 'GET_RESOURCE_CAMPAIGNS',
  SAVE_RESOURCE_CAMPAIGNS: 'SAVE_RESOURCE_CAMPAIGNS',
  RESET_RESOURCE_CAMPAIGNS: 'RESET_RESOURCE_CAMPAIGNS',

  GET_RESOURCE_TAG_CATEGORIES: 'GET_RESOURCE_TAG_CATEGORIES',
  SAVE_RESOURCE_TAG_CATEGORIES: 'SAVE_RESOURCE_TAG_CATEGORIES',
  RESET_RESOURCE_TAG_CATEGORIES: 'RESET_RESOURCE_TAG_CATEGORIES',

  GET_RESOURCE_TAG_CATEGORY: 'GET_RESOURCE_TAG_CATEGORY',
  SAVE_RESOURCE_TAG_CATEGORY: 'SAVE_RESOURCE_TAG_CATEGORY',
  RESET_RESOURCE_TAG_CATEGORY: 'RESET_RESOURCE_TAG_CATEGORY',

  GET_RESOURCE_TAGS_NAMES: 'GET_RESOURCE_TAGS_NAMES',
  SAVE_RESOURCE_TAGS_NAMES: 'SAVE_RESOURCE_TAGS_NAMES',
  RESET_RESOURCE_TAGS_NAMES: 'RESET_RESOURCE_TAGS_NAMES',

  GET_RESOURCE_PLAYER_TAGS: 'GET_RESOURCE_PLAYER_TAGS',
  SAVE_RESOURCE_PLAYER_TAGS: 'SAVE_RESOURCE_PLAYER_TAGS',
  RESET_RESOURCE_PLAYER_TAGS: 'RESET_RESOURCE_PLAYER_TAGS',

  GET_RESOURCE_VERTICAL_CATEGORY_REASONS: 'GET_RESOURCE_VERTICAL_CATEGORY_REASONS',
  SAVE_RESOURCE_VERTICAL_CATEGORY_REASONS: 'SAVE_RESOURCE_VERTICAL_CATEGORY_REASONS',
  RESET_RESOURCE_VERTICAL_CATEGORY_REASONS: 'RESET_RESOURCE_VERTICAL_CATEGORY_REASONS',

  GET_RESOURCE_BRAND: 'GET_RESOURCE_BRAND',
  SAVE_RESOURCE_BRAND: 'SAVE_RESOURCE_BRAND',
  RESET_RESOURCE_BRAND: 'RESET_RESOURCE_BRAND',

  GET_RESOURCE_PARTNER: 'GET_RESOURCE_PARTNER',
  SAVE_RESOURCE_PARTNER: 'SAVE_RESOURCE_PARTNER',
  RESET_RESOURCE_PARTNER: 'RESET_RESOURCE_PARTNER',

  GET_RESOURCE_BETSHOPS: 'GET_RESOURCE_BETSHOPS',
  SAVE_RESOURCE_BETSHOPS: 'SAVE_RESOURCE_BETSHOPS',
  RESET_RESOURCE_BETSHOPS: 'RESET_RESOURCE_BETSHOPS',

  GET_RESOURCE_NEGATIVE_BALANCE_EXISTS: 'GET_RESOURCE_NEGATIVE_BALANCE_EXISTS',
  SAVE_RESOURCE_NEGATIVE_BALANCE_EXISTS: 'SAVE_RESOURCE_NEGATIVE_BALANCE_EXISTS',
  RESET_RESOURCE_NEGATIVE_BALANCE_EXISTS: 'RESET_RESOURCE_NEGATIVE_BALANCE_EXISTS',

  GET_RESOURCE_PARTNER_TFA_STATE: 'GET_RESOURCE_PARTNER_TFA_STATE',
  SAVE_RESOURCE_PARTNER_TFA_STATE: 'SAVE_RESOURCE_PARTNER_TFA_STATE',
  RESET_RESOURCE_PARTNER_TFA_STATE: 'RESET_RESOURCE_PARTNER_TFA_STATE',

  GET_RESOURCE_GAMES_SEGMENTS: 'GET_RESOURCE_GAMES_SEGMENTS',
  SAVE_RESOURCE_GAMES_SEGMENTS: 'SAVE_RESOURCE_GAMES_SEGMENTS',
  RESET_RESOURCE_GAMES_SEGMENTS: 'RESET_RESOURCE_GAMES_SEGMENTS',

  GET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS: 'GET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS',
  SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGNS: 'SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGNS',
  RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS: 'RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS',

  GET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES: 'GET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES',
  SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES: 'SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES',
  RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES: 'RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES',

  CHANGE_RESOURCE_GAMES_LOADING: 'CHANGE_RESOURCE_GAMES_LOADING',

  GET_RESOURCE_PROMOTIONS: 'GET_RESOURCE_PROMOTIONS',
  SAVE_RESOURCE_PROMOTIONS: 'SAVE_RESOURCE_PROMOTIONS',
  RESET_RESOURCE_PROMOTIONS: 'RESET_RESOURCE_PROMOTIONS',

  GET_RESOURCE_PROMOTION_MACHINES: 'GET_RESOURCE_PROMOTION_MACHINES',
  SAVE_RESOURCE_PROMOTION_MACHINES: 'SAVE_RESOURCE_PROMOTION_MACHINES',
  RESET_RESOURCE_PROMOTION_MACHINES: 'RESET_RESOURCE_PROMOTION_MACHINES',

  GET_RESOURCE_BRAND_PERIODS_LIMITS: 'GET_RESOURCE_BRAND_PERIODS_LIMITS',
  SAVE_RESOURCE_BRAND_PERIODS_LIMITS: 'SAVE_RESOURCE_BRAND_PERIODS_LIMITS',
  RESET_RESOURCE_BRAND_PERIODS_LIMITS: 'RESET_RESOURCE_BRAND_PERIODS_LIMITS',

  GET_RESOURCE_GAME_GROUPS_NAMES: 'GET_RESOURCE_GAME_GROUPS_NAMES',
  SAVE_RESOURCE_GAME_GROUPS_NAMES: 'SAVE_RESOURCE_GAME_GROUPS_NAMES',
  RESET_RESOURCE_GAME_GROUPS_NAMES: 'RESET_RESOURCE_GAME_GROUPS_NAMES',

  GET_RESOURCE_CURRENCIES_PRECISIONS: 'GET_RESOURCE_CURRENCIES_PRECISIONS',
  SAVE_RESOURCE_CURRENCIES_PRECISIONS: 'SAVE_RESOURCE_CURRENCIES_PRECISIONS',

  GET_RESOURCE_BRAND_TAX_DDA: 'GET_RESOURCE_BRAND_TAX_DDA',
  SAVE_RESOURCE_BRAND_TAX_DDA: 'SAVE_RESOURCE_BRAND_TAX_DDA',
  RESET_RESOURCE_BRAND_TAX_DDA: 'RESET_RESOURCE_BRAND_TAX_DDA',

  GET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT: 'GET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT',
  SAVE_RESOURCE_FIXED_WITHDRAWAL_AMOUNT: 'SAVE_RESOURCE_FIXED_WITHDRAWAL_AMOUNT',
  RESET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT: 'RESET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT',

  GET_RESOURCE_PROVIDERS_AGGREGATORS: 'GET_RESOURCE_PROVIDERS_AGGREGATORS',
  SAVE_RESOURCE_PROVIDERS_AGGREGATORS: 'SAVE_RESOURCE_PROVIDERS_AGGREGATORS',
  RESET_RESOURCE_PROVIDERS_AGGREGATORS: 'RESET_RESOURCE_PROVIDERS_AGGREGATORS',

  CHANGE_RESOURCE_TAGS_LOADING: 'CHANGE_RESOURCE_TAGS_LOADING',

  GET_RESOURCE_SPORTSBOOK_LAYOUTS: 'GET_RESOURCE_SPORTSBOOK_LAYOUTS',
  SAVE_RESOURCE_SPORTSBOOK_LAYOUTS: 'SAVE_RESOURCE_SPORTSBOOK_LAYOUTS',
  RESET_RESOURCE_SPORTSBOOK_LAYOUTS: 'RESET_RESOURCE_SPORTSBOOK_LAYOUTS',

  GET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES: 'GET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES',
  SAVE_RESOURCE_GRID_MACHINE_DIMENSION_TYPES: 'SAVE_RESOURCE_GRID_MACHINE_DIMENSION_TYPES',
  RESET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES: 'RESET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES',

  GET_RESOURCE_CUSTOM_PAGES: 'GET_RESOURCE_CUSTOM_PAGES',
  SAVE_RESOURCE_CUSTOM_PAGES: 'SAVE_RESOURCE_CUSTOM_PAGES',
  RESET_RESOURCE_CUSTOM_PAGES: 'RESET_RESOURCE_CUSTOM_PAGES',

  GET_RESOURCE_CASHIER_TYPE: 'GET_RESOURCE_CASHIER_TYPE',
  SAVE_RESOURCE_CASHIER_TYPE: 'SAVE_RESOURCE_CASHIER_TYPE',
  RESET_RESOURCE_CASHIER_TYPE: 'RESET_RESOURCE_CASHIER_TYPE',

  GET_RESOURCE_RELEASE_NOTES: 'GET_RESOURCE_RELEASE_NOTES',
  SAVE_RESOURCE_RELEASE_NOTES: 'SAVE_RESOURCE_RELEASE_NOTES',
  RESET_RESOURCE_RELEASE_NOTES: 'RESET_RESOURCE_RELEASE_NOTES',

  GET_RESOURCE_GAME_TYPES: 'GET_RESOURCE_GAME_TYPES',
  SAVE_RESOURCE_GAME_TYPES: 'SAVE_RESOURCE_GAME_TYPES',
  RESET_RESOURCE_GAME_TYPES: 'RESET_RESOURCE_GAME_TYPES',

  GET_RESOURCE_DEEP_LINK_PROMOTIONS: 'GET_RESOURCE_DEEP_LINK_PROMOTIONS',
  SAVE_RESOURCE_DEEP_LINK_PROMOTIONS: 'SAVE_RESOURCE_DEEP_LINK_PROMOTIONS',
  RESET_RESOURCE_DEEP_LINK_PROMOTIONS: 'RESET_RESOURCE_DEEP_LINK_PROMOTIONS',

  GET_RESOURCE_PERMISSION_GROUPS: 'GET_RESOURCE_PERMISSION_GROUPS',
  SAVE_RESOURCE_PERMISSION_GROUPS: 'SAVE_RESOURCE_PERMISSION_GROUPS',
  RESET_RESOURCE_PERMISSION_GROUPS: 'RESET_RESOURCE_PERMISSION_GROUPS',

  GET_RESOURCE_KYC_TYPE: 'GET_RESOURCE_KYC_TYPE',
  SAVE_RESOURCE_KYC_TYPE: 'SAVE_RESOURCE_KYC_TYPE',
  RESET_RESOURCE_KYC_TYPE: 'RESET_RESOURCE_KYC_TYPE',

  GET_RESOURCE_DIRECTORY_RULES: 'GET_RESOURCE_DIRECTORY_RULES',
  SAVE_RESOURCE_DIRECTORY_RULES: 'SAVE_RESOURCE_DIRECTORY_RULES',
  RESET_RESOURCE_DIRECTORY_RULES: 'RESET_RESOURCE_DIRECTORY_RULES',

  GET_RESOURCE_LOBBY_GROUPS: 'GET_RESOURCE_LOBBY_GROUPS',
  SAVE_RESOURCE_LOBBY_GROUPS: 'SAVE_RESOURCE_LOBBY_GROUPS',
  RESET_RESOURCE_LOBBY_GROUPS: 'RESET_RESOURCE_LOBBY_GROUPS',

  GET_RESOURCE_GROUP_GAMES: 'GET_RESOURCE_GROUP_GAMES',
  SAVE_RESOURCE_GROUP_GAMES: 'SAVE_RESOURCE_GROUP_GAMES',
  RESET_RESOURCE_GROUP_GAMES: 'RESET_RESOURCE_GROUP_GAMES',
  CHANGE_RESOURCE_GROUP_GAMES_LOADING: 'CHANGE_RESOURCE_GROUP_GAMES_LOADING',

  GET_RESOURCE_CAMPAIGNS_V2: 'GET_RESOURCE_CAMPAIGNS_V2',
  SAVE_RESOURCE_CAMPAIGNS_V2: 'SAVE_RESOURCE_CAMPAIGNS_V2',
  RESET_RESOURCE_CAMPAIGNS_V2: 'RESET_RESOURCE_CAMPAIGNS_V2',

  GET_RESOURCE_LOBBY_PROVIDERS: 'GET_RESOURCE_LOBBY_PROVIDERS',
  SAVE_RESOURCE_LOBBY_PROVIDERS: 'SAVE_RESOURCE_LOBBY_PROVIDERS',
  RESET_RESOURCE_LOBBY_PROVIDERS: 'RESET_RESOURCE_LOBBY_PROVIDERS',

  GET_RESOURCE_LOBBY_CATEGORIES: 'GET_RESOURCE_LOBBY_CATEGORIES',
  SAVE_RESOURCE_LOBBY_CATEGORIES: 'SAVE_RESOURCE_LOBBY_CATEGORIES',
  RESET_RESOURCE_LOBBY_CATEGORIES: 'RESET_RESOURCE_LOBBY_CATEGORIES',

  GET_RESOURCE_LOBBY_GAMES: 'GET_RESOURCE_LOBBY_GAMES',
  SAVE_RESOURCE_LOBBY_GAMES: 'SAVE_RESOURCE_LOBBY_GAMES',
  RESET_RESOURCE_LOBBY_GAMES: 'RESET_RESOURCE_LOBBY_GAMES',

  GET_RESOURCE_CAMPAIGNS_V2_UNITS: 'GET_RESOURCE_CAMPAIGNS_V2_UNITS',
  SAVE_RESOURCE_CAMPAIGNS_V2_UNITS: 'SAVE_RESOURCE_CAMPAIGNS_V2_UNITS',
  RESET_RESOURCE_CAMPAIGNS_V2_UNITS: 'RESET_RESOURCE_CAMPAIGNS_V2_UNITS',
};

export default ResourceActionType;
