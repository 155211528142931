import styled from 'styled-components';
import {
  media, flexContainer, getBoxShadow, truncate,
} from 'utils/styledHelpers';
import { StyledSvgIcon } from 'components/styledComponents/icon/Icon.styled';

const StyledExpandedTabs = styled.div`
  background: ${(props) => (props.isExpanded === 'disable' ? 'red' : '')};

  .ant-tabs > span,
  .ant-tabs:not(.ant-table-expanded-row) {
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    margin-top: ${(props) => (props.isMargin ? '2.28rem' : 0)};
    > span {
      margin: 0;
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr.ant-table-expanded-row > td {
    padding: ${(props) => (props.mediumPadding ? '1.14rem' : '0.5rem 1.14rem')};
  }
  .ant-tabs-content-holder {
    padding: 0 !important;
  }
  .ant-table .ant-table-tbody .ant-table-expanded-row {
    background: ${(props) => props.theme.colors.tableTrEvenColor} !important;
    .ant-table-row.even {
      background: ${(props) => props.theme.colors.secondaryBg} !important;
    }
    }
  }
  .ant-list-item {
    padding: 0;
  }
  .ant-list-split .ant-list-item {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
  .ant-tabs-nav-list{
    padding-left: 0!important;
  }
 
`;
const StyledTabBackWrapper = styled.div`
  flex-grow: 1;

  .ant-tabs-top .ant-tabs-tab:first-child {
    margin-left: 1.14rem !important;
  }
`;

const StyledTabItemText = styled.span`
  text-transform: ${(props) => (props.isLowercase ? 'uppercase' : 'initial')};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  display: block;
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0.2rem 0.7rem 0 0.7rem;
`;

const StyledTabWrapper = styled.div`
  background: ${(props) => (props.noNoteTabContent ? 'transparent' : props.theme.colors.secondaryBg)};
  width: 100%;
  border-radius: ${(props) => (props.noRadius ? 0 : `${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`)};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-shadow: ${(props) => (props.noShadow ? 'none' : `${getBoxShadow(props.theme.colors.secondaryShadow)}`)};
  position: relative;
  padding: ${(props) => (props.noTabContent && !props.noNoteTabContent ? '1.7rem 2.85rem 4.1rem' : 0)};

  ${media.sm`
    padding: ${(props) => (props.noTabContent && !props.noNoteTabContent ? '1.41rem' : '0 0 2rem')};
  `}

  .ant-tabs, .ant-tabs.ant-tabs-top {
    color: ${(props) => props.theme.colors.primaryText};
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    overflow: initial; // Table sticky columns dont work with overflow hidden style
    &-content-holder {
      flex: 1 1 auto;
      display: flex;
      min-height: initial;
      padding: 0 1.14rem;

      ${media.sm`
        padding: ${(props) => (props.mdTabContent ? '1.79rem 1.4rem 1.71rem' : '0.57rem 1.2rem 0')};
      `}
    }
    &-tabpane {
      height: 100%;
      outline: none;
    }
    &-ink-bar.ant-tabs-ink-bar-no-animated {
      display: none !important;
    }
    &-bar {
      margin: 0;
    }
    &-nav {
      width: 100%;

      &-list:not(.ant-tabs-content-holder .ant-tabs-nav-list) {
        padding-left: 1.14rem;
      }
      &-wrap > div {
        display: flex;
        justify-content: flex-start;
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        height: 3.42rem;
        ${flexContainer()};
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
        border-top-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall}!important;
        border-top-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall}!important;
        text-align: left;
        text-transform: capitalize;
        padding: 1.14rem;
        margin: 0 0.14rem 0 0;
        transition: initial;
        border-bottom: 2px solid transparent;
        &-active,
        &:hover {
          color: ${(props) => props.theme.colors.blue};
          span {
            color: ${(props) => props.theme.colors.blue};
          }
        }
      }
    }
  }
  &-ink-bar {
    background-color: ${(props) => props.theme.colors.blue};
  }
  &-bar {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
  .ant-affix .ant-tabs-bar {
    background-color: ${(props) => props.theme.colors.secondaryBg};
  }
`;

const StyledAttachmentsWrapper = styled.div`
  .ant-tabs-nav-wrap .ant-tabs-nav-list {
    padding-left: 0.5rem;
  }
  .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active,
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 2.28rem !important;
  }
  .ant-tabs-content-holder {
    padding: 1.1rem 4rem 1.71rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @media (max-width: 1366px) {
      padding: 1.79rem 2.29rem 1.71rem;
    }
    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
`;
const StyledTabWrapperInner = styled.div`
  float: left;
  padding: 0.7rem 1rem 0.7rem 1.3rem;
  top: 0.21rem;
  border-bottom: none;
  cursor: pointer;
  position: absolute;
  z-index: 50;
  height: 47px;
  svg g rect {
    fill: none;
  }
`;

const StyledTabPlayerWrapper = styled(StyledTabWrapper)`
  background: ${(props) => (!props.inside ? props.theme.colors.secondaryBg : 'transparent')};
  .ant-tabs-nav-list:not(.ant-table-expanded-row-fixed .ant-tabs-nav-list),
  .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 1px;
    &::before {
      bottom: -1px;
    }
  }
  .ant-tabs {
    &-content-holder {
      padding: 0;
    }
    &-nav {
      margin: 0;
    }
  }
`;

const StyledTabWrapperIcons = styled(StyledTabWrapper)``;

const StyledTabItem = styled.div`
  display: flex;
  align-items: center;
  ${truncate()}

  > p {
    position: relative;
    &:hover {
      color: ${(props) => props.theme.colors.blue};
    }
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      left: -1rem;
      background: ${(props) => props.theme.colors.blue};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
      display: ${(props) => (props.paneIcon ? 'block' : 'none')};
    }
  }
  &:hover p {
    color: ${(props) => props.theme.colors.blue};
  }
  svg path {
    fill: ${(props) => props.theme.colors.secondaryColor};
  }
`;
const StyledTabItemBold = styled.p`
  font-weight: ${(props) => (props.isAssigned ? props.theme.typography.fontWeightMedium : props.theme.typography.fontWeightRegular)};
  ${truncate()}
`;
const StyledTabWrapperPG = styled(StyledTabWrapper)`
  .ant-tabs-content-holder {
    ${media.sm`
      padding: 1.2rem 1.2rem;
    `}
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(.ant-table-cell-fix-right) {
    max-width: 22rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledTabWrapperSeo = styled(StyledTabWrapperIcons)`
  .ant-tabs-top-content {
    padding: 24px 56px 48px;
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      padding: 10px 24px 14px;
      text-transform: initial;
      margin-right: 4px;
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
      border-color: #00000015;
      background-color: #00000002;
      &.ant-tabs-tab-active {
        border-bottom: 1px solid transparent;
        background-color: ${(props) => props.theme.colors.secondaryBg};
      }
    }
    &.ant-tabs-card-content {
      padding: 24px 0 0;
    }
  }
`;
const StyledTabWrapperWithPadding = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.secondaryBg};
  padding: 2.85rem 2.85rem 3.2rem;
  box-sizing: border-box;
  border-radius: ${(props) => (props.noRadius ? 0 : `${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`)};
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
  .ant-table-column-sorter .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.4em;
  }
  .ant-table-cell-fix-right {
    width: 7.5rem;
  }
  .ant-table-cell.status:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7.5rem;
  }

  ${media.sm`
    padding: 1.4rem 1.4rem 1.8rem;
  `}
`;

const StyledVerticalTabs = styled.div`
  margin-bottom: ${(props) => (props.isStickyFooter ? 0 : '2.86rem')};
  flex-grow: 1;
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.tableHeadColor};
  .ant-tabs.ant-tabs-left {
    height: 100%;
    .ant-tabs-nav {
      width: 16.4rem;
      border-right: 1px solid ${(props) => props.theme.colors.tableHeadColor};
      background: ${(props) => props.theme.colors.tableTrEvenColor}80;
      border-bottom: 1px solid transparent;
      .ant-tabs-nav-list {
        flex: 0 0 auto;
        .ant-tabs-tab {
          border-bottom: 1px solid ${(props) => props.theme.colors.tableHeadColor};
          margin: 0;
          height: 3.43rem;
          padding: 0.57rem 1.71rem;
          border-radius: 0 !important;
          &:nth-last-child(2) {
            border-bottom: none;
          }
        }
      }
    }
    & > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
      padding-left: 0;
    }
  }

  .ant-tabs-extra-content {
    width: 99%;
    margin: 0 auto -1px;
    border-bottom: 1px solid ${(props) => props.theme.colors.tableTrEvenColor};
    .ant-select-multiple.ant-select-allow-clear .ant-select-selector,
    .ant-select-multiple.ant-select-allow-clear:hover .ant-select-selector {
      border-color: transparent;
      border-bottom: 0;
      border-radius: 0;
    }
  }
  .ant-tabs-tab-active {
    background: ${(props) => props.theme.colors.hoverBg};
    position: relative;
    &:after {
      background: ${(props) => props.theme.colors.blue};
      width: 4px;
      height: 104%;
      top: -1px;
      left: 0;
      content: '';
      position: absolute;
    }
    p {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row) > td {
    white-space: initial;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-left > .ant-tabs-content-holder {
    border-left: 1px solid ${(props) => props.theme.colors.tableHeadColor};
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    padding-left: 0.9rem;
  }
`;

const StyledInternalTabWrapper = styled.div`
  .ant-tabs {
    .ant-tabs-nav {
      margin: 0 0 16px 0;
      .ant-tabs-nav-wrap {
        border-radius: 0;
        .ant-tabs-nav-list {
          padding-left: 0;
          background: transparent;

          .ant-tabs-tab.ant-tabs-tab-active,
          .ant-tabs-tab {
            padding: 0.85rem 1.71rem !important;
            margin: 0 0.28rem 0 0 !important;
          }
        }
      }
    }
  }
  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
`;

const StyledAdminTabs = styled.div`
  height: 100%;

  & > .ant-tabs,
  & > .ant-tabs.ant-tabs-top {
    & > .ant-tabs-content-holder {
      padding: ${(props) => (props.isPlayerPage ? '0' : '0.57rem 2.85rem 0')};
      ${media.sm`
        padding: ${(props) => (props.isPlayerPage ? '0' : '0.57rem 1.14rem 0')};
      `}
    }

    & > .ant-tabs-nav {
      .ant-tabs-nav-list {
        padding-left: 2.85rem;
        .ant-tabs-tab {
          background-color: transparent;
          padding: 0;
          border: none;
          margin: 0 3.28rem 0 0;
          cursor: pointer !important;

          &-remove {
            position: absolute;
            right: 0;
            margin: 0;
            opacity: 0;
            padding-left: 0;
            transition: 0.3s all;

            svg g {
              stroke: ${(props) => props.theme.colors.blue};
            }
          }
          &-active {
            border-bottom: 2px solid ${(props) => props.theme.colors.blue};
            color: ${(props) => props.theme.colors.blue};
            span {
              color: ${(props) => props.theme.colors.blue};
            }
          }
          &:hover {
            .ant-tabs-tab-remove {
              opacity: 1;
            }
          }
          &-active,
          &:hover {
            svg {
              path,
              g rect,
              g circle,
              g ellipse {
                fill: ${(props) => props.theme.colors.blue};
              }
            }
          }
          &.ant-tabs-tab-with-remove {
            ${StyledSvgIcon} {
              transition: 0.3s transform;
              transform-origin: left;
            }
            &:hover {
              .ant-tabs-tab-remove {
                transform: translateX(0.87rem);
              }
            }
          }
        }
      }
    }
  }
`;

const StyledDynamicSubTabs = styled.div`
  height: 100%;
  & > .ant-tabs,
  & > .ant-tabs.ant-tabs-top {
    & > .ant-tabs-nav {
      align-items: unset;

      &:before {
        content: none;
      }

      .ant-tabs-nav-list {
        align-items: flex-start;
        padding: 0;

        .ant-tabs-tab {
          background-color: transparent;
          justify-content: center;
          height: auto;
          margin: ${(props) => (props.isPlayerPage ? '2.85rem 0.6rem 0.71rem' : '0.57rem 0.6rem 0.71rem')} !important;
          padding: 0;
          width: 6rem;
          flex: 0 0 6rem;
          border: none;

          &:first-child {
            margin-left: ${(props) => (props.isPlayerPage ? '1.14rem' : '0')} !important;
          }

          .ant-tabs-tab-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.43rem;
            height: 3.43rem;
            margin: 0 auto;
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.grayButton};
            border: 1px solid ${(props) => props.theme.colors.primaryBorder};
            border-radius: 50%;
            transition: 0.3s all;

            svg {
              path,
              g rect,
              g circle,
              g ellipse {
                fill: ${(props) => props.theme.colors[props.color]};
              }
            }

            & + p {
              text-align: center;
              word-break: break-word;
              white-space: normal;
              margin: 1rem 0 0;
              max-width: 6rem;
              font-weight: ${(props) => props.theme.typography.fontWeightRegular};
            }

            ${StyledSvgIcon} {
              transform: none;
            }
          }

          &:hover {
            .ant-tabs-tab-icon {
              transform: scale(0.9);
            }
            .ant-tabs-tab-remove {
              transform: none;
              opacity: 1;
            }
          }

          &-active {
            .ant-tabs-tab-icon {
              background-color: ${(props) => props.theme.colors[props.color]};
              border-color: ${(props) => props.theme.colors[props.color]};

              svg {
                path,
                g rect,
                g circle,
                g ellipse {
                  fill: ${(props) => props.theme.colors.white};
                }
              }
              & + p {
                color: ${(props) => props.theme.colors[props.colorText]};
              }
            }
          }

          .ant-tabs-tab-remove {
            ${flexContainer({ align: 'center', justify: 'center', direction: 'row' })};
            position: absolute;
            top: 0;
            right: 0.5rem;
            width: 1.57rem;
            height: 1.57rem;
            margin: 0;
            padding: 0;
            opacity: 0;
            svg {
              width: 1.57rem;
              height: 1.57rem;
              padding: 0.24rem;
              background: ${(props) => props.theme.colors.primaryBorder};
              border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
              border: 3px solid ${(props) => props.theme.colors.secondaryBg};
              g line {
                stroke: ${(props) => props.theme.colors.white};
              }
            }
          }
        }
      }

      .ant-tabs-nav-wrap {
        flex: unset;
      }
      .ant-tabs-nav-operations {
        display: none;

        &:not(.ant-tabs-nav-operations-hidden) + .ant-tabs-extra-content {
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            transition: opacity 0.3s;
            pointer-events: none;
            box-shadow: inset -10px 0 8px -8px #e5e7ea;
            width: 30px;
            height: 100%;
            left: -30px;
          }
        }
      }
      .ant-tabs-extra-content {
        position: relative;
      }
    }
  }
`;

const StyledSubTabAddButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  margin: ${(props) => (props.isPlayerPage ? '2.85rem 1.71rem 0.71rem 1.71rem' : '0.57rem 1.71rem 0.71rem')} !important;

  .ant-btn {
    margin: 0;
    height: 3.43rem;
    width: 3.43rem;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};

    &:hover:not(:disabled):not(.ant-btn-disabled) {
      border-color: ${(props) => props.theme.colors.primaryBorder};
    }
  }
`;

// For AdminSubTub component which is not in player's page
const StyledSubTabWrapper = styled.div`
  .ant-tabs,
  .ant-tabs.ant-tabs-top {
    .ant-tabs-nav {
      padding: 0;
    }
    .ant-tabs-content-holder {
      padding: 0;
    }
  }
`;

const StyledTabsSimplified = styled.div`
  height: ${(props) => (props.isAutoHeight ? 'auto' : '100%')};
  .ant-tabs.ant-tabs-top.ant-tabs-card {
    overflow: initial;
  }
  .ant-form > div {
    border: none;
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder}!important;
    background: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-transform: capitalize;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    height: 2.86rem !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 0.43rem;
  }
  .ant-tabs {
    &-content-holder {
      padding: 0 !important;
    }
  }
`;

export {
  StyledTabWrapper,
  StyledTabItemText,
  StyledTabWrapperIcons,
  StyledExpandedTabs,
  StyledTabItem,
  StyledTabItemBold,
  StyledTabPlayerWrapper,
  StyledTabWrapperPG,
  StyledTabWrapperSeo,
  StyledTabBackWrapper,
  StyledTabWrapperInner,
  StyledAttachmentsWrapper,
  StyledTabWrapperWithPadding,
  StyledVerticalTabs,
  StyledInternalTabWrapper,
  StyledAdminTabs,
  StyledDynamicSubTabs,
  StyledSubTabAddButtonWrapper,
  StyledSubTabWrapper,
  StyledTabsSimplified,
};
