import SelfAssessmentActionType from './selfAssessmentActionType';

export const getSelfAssessments = (params) => ({
  type: SelfAssessmentActionType.GET_SELF_ASSESSMENTS,
  payload: { params },
});

export const saveSelfAssessments = (data) => ({
  type: SelfAssessmentActionType.SAVE_SELF_ASSESSMENTS,
  payload: { data },
});

export const resetSelfAssessments = () => ({
  type: SelfAssessmentActionType.RESET_SELF_ASSESSMENTS,
});

export const generateSelfAssessmentsReport = (params) => ({
  type: SelfAssessmentActionType.GENERATE_SELF_ASSESSMENTS_REPORT,
  payload: { params },
});
