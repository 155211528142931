import _ from 'lodash';
import qs from 'qs';
import { NavigationService, i18n, DateService } from 'services';
import StoreService from 'services/storeService';
import Config from 'config';
import { types } from 'constants/accessControl';
import Can from 'components/common/Can';
import enumTypes from 'constants/enumTypes';
import { lazy } from 'react';
import { toFormatNumber } from './utils';

const { isBrRegulationAudit } = Config;

/**
 * Get notification message.
 * @param   {String}    String     The model type.
 * @param   {String}    String     The action type.
 * @param   {String}    String     The name of model.
 * @return  {String}    Return a generated message.
 */
export const getMessage = (model, action, name = '') => i18n.t('notification:generalMessage', {
  model,
  action,
  name,
});

/**
 * Get columns with additional props.
 * @param   {columns}    Array    Array.
 * @returns {columns} Return columns with additional props.
 */
export const getColumns = (columns) => columns.map((col) => ({
  ...col,
  onCell: (record) => ({
    record,
    ...col,
  }),
}));

/**
 * @param {number} partnerId
 * @returns {boolean}
 */
export const isOwnUser = (partnerId) => partnerId === enumTypes.systemPartnerId;

/**
 *
 * @param {Array} Array
 * @param {permissions}  Array
 * @returns {*}
 */
export const getInitialColumns = (columns, permissions = [], isViewMode = isBrRegulationAudit) => (permissions.some(
  ({ type, rule }) => (!isViewMode || (isViewMode && type === types.view))
      && !Can({
        type,
        rule,
        children: false,
        fallback: true,
      }),
)
  ? columns
  : columns.filter((el) => el.key !== 'actions'));

/**
 *
 * @param {string} title
 * @param {array} tabs
 * @param {string} tabKey
 * @returns {null|string} visible Tab
 */
export const getVisibleTab = (title, tabs, tabKey) => {
  const store = StoreService.getStore().getState();
  const userConfigs = tabKey && store.users.userConfigs?.[tabKey];

  if (_.isEmpty(userConfigs)) {
    const element = tabs.find((el) => el.title === title && !el.hide);
    if (element) {
      return element.title;
    }

    const firstAllowed = tabs.find((el) => !el.hide);
    return firstAllowed ? firstAllowed.title : null;
  }

  return userConfigs.find((el) => title === el.title && el.checked)?.title;
};

/**
 * @param {object} err
 * @param {boolean} statusCode
 * @param {boolean} isAuth
 * @returns {object}
 */
export const getError = (err, statusCode = false, isAuth = false) => {
  if (isBrRegulationAudit && err?.response?.status >= 500) {
    return {
      errors: [
        {
          errorMessage: i18n.t('notification:dataSyncError'),
          errorCode: 'error5XX',
        },
      ],
      status: statusCode ? err.response.status : null,
    };
  }
  if (err?.response?.data) {
    return {
      errors: !isAuth
        ? err.response.data
        : [
          {
            errorMessage: err.response.data.errorMessage,
            errorCode: err.response.data.errorCode,
          },
        ],
      status: statusCode ? err.response.status : null,
    };
  }
  return {
    errors: [
      {
        errorMessage: i18n.t('notification:unknownError'),
        errorCode: 'error404',
      },
    ],
    status: statusCode ? err.response.status : null,
  };
};

/**
 * Navigation for report's actions.
 * @param {object} event - Mouse down event.
 * @param {string} url - Navigate to url.
 */
export const handleNavigate = (e, url) => {
  if (e.button === 1) {
    window.open(url, '_blank');
  } else if (!e.button) {
    NavigationService(url);
  }
};

/**
 * To keep query params in URL
 * @param {object} params.
 * @param {string} key.
 * @returns {string}
 */
export const getUrlSearchParams = (params, key = 'inside') => qs.stringify({ [key]: JSON.stringify(params) });

/**
 * @param {array|null} configsColumns
 * @param {array} initialColumns
 * @param {boolean} isTranslated
 * @returns {array}
 */
export const getExportedColumns = (configsColumns, initialColumns, isTranslated = false) => {
  const initialExportedColumns = initialColumns.reduce((filtered, el) => {
    if (el.title && el.dataIndex && el.dataIndex !== 'actions') {
      const title = el.titleText || el.title;
      return {
        ...filtered,
        [el.dataIndex]: {
          title: !isTranslated ? i18n.t(title) : title,
          dataIndex: el.dataIndex,
        },
      };
    }
    return filtered;
  }, {});

  if (configsColumns && configsColumns.length) {
    const exportedColumns = [];
    configsColumns.forEach((el) => {
      if (el.checked && el.dataIndex && el.dataIndex !== 'actions' && initialExportedColumns[el.dataIndex]) {
        exportedColumns.push(initialExportedColumns[el.dataIndex]);
      }
    });
    return exportedColumns;
  }

  return Object.values(initialExportedColumns);
};

/**
 * Get translation by key
 * @param {string} enumName resource name
 * @param {string|number} key
 * @param {object| undefined} initialValues initial enum, is used when the translation call does not work
 * @returns {undefined|string}
 */
export const getResourceValue = (enumName, key, initialValues) => {
  const store = StoreService.getStore().getState();
  return store.locale.resources[enumName]?.[key] ?? ((initialValues && _.invert(initialValues)) || {})[key];
};

/**
 * @param permissions Array
 * @param isViewMode
 * @returns { number }
 */
export const getAllowedActionsCount = (permissions, isViewMode = isBrRegulationAudit) => permissions.filter(
  ({ type, rule }) => (!isViewMode || (isViewMode && type === types.view))
      && !Can({
        type,
        rule,
        children: false,
        fallback: true,
      }),
).length;

/**
 * @param {object} mapping
 * @returns { object } with translated values
 */
export const getMappingTranslation = (mapping) => Object.keys(mapping).reduce((res, curr) => {
  res[curr] = i18n.t(mapping[curr]);
  return res;
}, {});

/**
 *
 * @param val
 * @param currency string
 * @returns {Number} formatted string
 * @param isFormat boolean
 * e.g 1 0000 000 , 5000.00
 * * @returns {Number} string
 */
export const toFormatNumberByCurrency = (val, currency, isFormat = true) => {
  const store = StoreService.getStore().getState();
  const precision = store.resource.currenciesPrecisions[currency] ?? 2;
  return toFormatNumber(val, precision, isFormat);
};

/**
 * Get manage filters config by key
 * @param {object} baseFilterConfig - all filters form items names with properties
 * @param {string} filterKey - if exists consider userConfig as wll
 * @param {object} query - if key exists in it, then show form item
 * @returns {object} dataIndex:properties
 */
export const getFilterConfigs = (baseFilterConfig, query = {}, filterKey = '') => {
  if (_.isEmpty(baseFilterConfig)) {
    return {};
  }
  let checked = false;
  const store = StoreService.getStore().getState();
  const userFilterConfigs = filterKey && store.users.userConfigs?.[filterKey];

  return Object.keys(baseFilterConfig)?.reduce((acc, dataIndex) => {
    checked = userFilterConfigs && !_.isUndefined(userFilterConfigs[dataIndex]) ? !!userFilterConfigs[dataIndex] : baseFilterConfig?.[dataIndex]?.checked;
    acc[dataIndex] = {
      ...baseFilterConfig?.[dataIndex],
      checked,
      visible: !_.isUndefined(query[dataIndex]) || checked,
    };
    return acc;
  }, {});
};

/**
 * Convert dateTz Array to JSON string
 * @param {Array} dateArray.
 * @returns {string} stringifies JSON
 */
export const convertDateArrayToJSON = (dateArray) => JSON.stringify(
  dateArray.reduce((acc, el) => {
    const dateString = el && DateService.convertDateObjectToDateString(el);
    if (dateString) {
      acc.push(dateString);
    }
    return acc;
  }, []),
);

/**
 * Accepts an array of numbers and generates all possible sequences that can be formed from the elements of the array
 * @param {Array[number]} array
 * @returns {Array[strings|number]|*[]}
 */

export const generateRanges = (array) => {
  if (array.length === 1) return array;
  array.sort((a, b) => +a - +b);
  const res = [];
  let curr = 1;
  let start = 0;
  while (curr < array.length + 1) {
    while (array[curr] === array[curr - 1] + 1 && curr < array.length) {
      curr += 1;
    }
    if (start !== curr - 1) {
      res.push(`${array[start]}-${array[curr - 1]}`);
    } else {
      res.push(array[start]);
    }
    start = curr;
    curr += 1;
  }
  return res;
};

/**
 *
 * @param {number} size by Byte
 * @returns {number} by MB
 */

export const getFileSizeByMb = (size) => size / 1024 / 1024;

/**
 *
 * @param  {function} componentImport
 * @returns {React.LazyExoticComponent<T>}
 */
export const lazyWithRetry = (componentImport) => lazy(
  () => new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');

    componentImport()
      .then((component) => {
        window.sessionStorage.removeItem(storageKey);
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behaviour
      });
  }),
);
