import BrandAutomationActionType from 'actions/brandAutomation/brandAutomationActionType';

const getInitialState = () => ({
  isBrandAutomationExist: null,
  conditionsActions: {},
  fieldTypeConfigs: {},
  brandAutomationRules: {
    data: [],
    total: 0,
  },
  brandAutomationRule: {},
  brandAutomationTransactionLogs: {
    data: [],
    total: 0,
  },
  brandAutomationPeriodicalLogs: {
    data: [],
    total: 0,
  },
  brandAutomationSignUpLogs: {
    data: [],
    total: 0,
  },
  brandAutomationVerificationLogs: {
    data: [],
    total: 0,
  },
  brandAutomationVerificationDiscardLogs: {
    data: [],
    total: 0,
  },
  brandAutomationAccountUpdateLogs: {
    data: [],
    total: 0,
  },
});

const brandAutomationReducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
  case BrandAutomationActionType.SAVE_IS_BRAND_AUTOMATION_EXIST:
    return {
      ...state,
      isBrandAutomationExist: payload.data,
    };
  case BrandAutomationActionType.RESET_IS_BRAND_AUTOMATION_EXIST:
    return {
      ...state,
      isBrandAutomationExist: null,
    };
  case BrandAutomationActionType.SAVE_CONDITIONS_ACTIONS:
    return {
      ...state,
      conditionsActions: payload.data,
    };
  case BrandAutomationActionType.RESET_CONDITIONS_ACTIONS:
    return {
      ...state,
      conditionsActions: {},
    };
  case BrandAutomationActionType.SAVE_FIELD_TYPE_CONFIGS:
    return {
      ...state,
      fieldTypeConfigs: payload.data,
    };

  case BrandAutomationActionType.RESET_FIELD_TYPE_CONFIGS:
    return {
      ...state,
      fieldTypeConfigs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_RULES:
    return {
      ...state,
      brandAutomationRules: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_RULES:
    return {
      ...state,
      brandAutomationRules: {
        data: [],
        total: 0,
      },
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_RULE:
    return {
      ...state,
      brandAutomationRule: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_RULE:
    return {
      ...state,
      brandAutomationRule: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_TRANSACTION_LOGS:
    return {
      ...state,
      brandAutomationTransactionLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_TRANSACTION_LOGS:
    return {
      ...state,
      brandAutomationTransactionLogs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_PERIODICAL_LOGS:
    return {
      ...state,
      brandAutomationPeriodicalLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_PERIODICAL_LOGS:
    return {
      ...state,
      brandAutomationPeriodicalLogs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_SIGN_UP_LOGS:
    return {
      ...state,
      brandAutomationSignUpLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_SIGN_UP_LOGS:
    return {
      ...state,
      brandAutomationSignUpLogs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_VERIFICATION_LOGS:
    return {
      ...state,
      brandAutomationVerificationLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_VERIFICATION_LOGS:
    return {
      ...state,
      brandAutomationVerificationLogs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS:
    return {
      ...state,
      brandAutomationVerificationDiscardLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS:
    return {
      ...state,
      brandAutomationVerificationDiscardLogs: {},
    };
  case BrandAutomationActionType.SAVE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS:
    return {
      ...state,
      brandAutomationAccountUpdateLogs: payload.data,
    };
  case BrandAutomationActionType.RESET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS:
    return {
      ...state,
      brandAutomationAccountUpdateLogs: {},
    };
  default:
    return state;
  }
};

export default brandAutomationReducer;
