import LobbyManagementActionType from 'actions/lobbyManagement/lobbyManagementActionType';

const initialState = {
  lobbies: [],
  lobby: {},
  lobbyProviders: [],
  lobbyProvider: {},
  lobbyProvidersOrders: {},
  lobbyProvidersGamesOrders: {},
  csvLobbyProvidersOrders: [],
  csvLobbyProviderGamesOrders: [],
  lobbyLogs: {},
  lobbyGameGroups: [],
  lobbyGameGroup: {},
  lobbyGeneralWidgets: [],
  lobbyJackpotWidgets: [],
  lobbyGeneralWidget: [],
  lobbyJackpotWidget: [],
  lobbyHomeWidgets: [],
  lobbyHomeWidget: {},
  lobbyHomeCsvProviders: [],
};

const lobbyManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case LobbyManagementActionType.SAVE_LOBBIES:
    return {
      ...state,
      lobbies: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBIES:
    return {
      ...state,
      lobbies: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY:
    return {
      ...state,
      lobby: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY:
    return {
      ...state,
      lobby: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_PROVIDERS:
    return {
      ...state,
      lobbyProviders: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_PROVIDERS:
    return {
      ...state,
      lobbyProviders: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_PROVIDER:
    return {
      ...state,
      lobbyProvider: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_PROVIDER:
    return {
      ...state,
      lobbyProvider: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_PROVIDERS_ORDERS:
    return {
      ...state,
      lobbyProvidersOrders: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_PROVIDERS_ORDERS:
    return {
      ...state,
      lobbyProvidersOrders: {},
    };
  case LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDERS_ORDERS:
    return {
      ...state,
      csvLobbyProvidersOrders: payload.data,
    };
  case LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDERS_ORDERS:
    return {
      ...state,
      csvLobbyProvidersOrders: [],
    };
  case LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDER_GAMES_ORDERS:
    return {
      ...state,
      csvLobbyProviderGamesOrders: payload.data,
    };
  case LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDER_GAMES_ORDERS:
    return {
      ...state,
      csvLobbyProviderGamesOrders: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_PROVIDER_GAMES_ORDERS:
    return {
      ...state,
      lobbyProvidersGamesOrders: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_PROVIDER_GAMES_ORDERS:
    return {
      ...state,
      lobbyProvidersGamesOrders: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_LOGS:
    return {
      ...state,
      lobbyLogs: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_LOGS:
    return {
      ...state,
      lobbyLogs: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_GAME_GROUPS:
    return {
      ...state,
      lobbyGameGroups: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_GAME_GROUPS:
    return {
      ...state,
      lobbyGameGroups: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_GAME_GROUP:
    return {
      ...state,
      lobbyGameGroup: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_GAME_GROUP:
    return {
      ...state,
      lobbyGameGroup: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGETS:
    return {
      ...state,
      lobbyGeneralWidgets: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGETS:
    return {
      ...state,
      lobbyGeneralWidgets: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGETS:
    return {
      ...state,
      lobbyJackpotWidgets: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGETS:
    return {
      ...state,
      lobbyJackpotWidgets: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGET:
    return {
      ...state,
      lobbyGeneralWidget: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGET:
    return {
      ...state,
      lobbyGeneralWidget: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGET:
    return {
      ...state,
      lobbyJackpotWidget: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGET:
    return {
      ...state,
      lobbyJackpotWidget: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_HOME_WIDGETS:
    return {
      ...state,
      lobbyHomeWidgets: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_HOME_WIDGETS:
    return {
      ...state,
      lobbyHomeWidgets: [],
    };
  case LobbyManagementActionType.SAVE_LOBBY_HOME_WIDGET:
    return {
      ...state,
      lobbyHomeWidget: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_HOME_WIDGET:
    return {
      ...state,
      lobbyHomeWidget: {},
    };
  case LobbyManagementActionType.SAVE_LOBBY_HOME_CSV_PROVIDERS:
    return {
      ...state,
      lobbyHomeCsvProviders: payload.data,
    };
  case LobbyManagementActionType.RESET_LOBBY_HOME_CSV_PROVIDERS:
    return {
      ...state,
      lobbyHomeCsvProviders: [],
    };
  default:
    return state;
  }
};

export default lobbyManagementReducer;
