import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainInfo from 'components/brandSetting/MainInfo';
import {
  getBrand, getMarkets, getTemplates, resetTemplates, resetBrand, changeBrandCallEndedStatus, setCurrentBrand,
} from 'actions/brand/brandActionCreator';
import { resetBrandSettings } from 'actions/brandSetting/brandSettingActionCreator';
import {
  selectBrand,
  selectBrandCallEndedStatus,
  selectBrandMainSetting,
  selectBrandMarkets,
  selectBrandTemplates,
  selectLocaleResource,
  selectStaticCollectionTimeZones,
  selectUserConfig,
} from 'selectors';
import { createOrEditUserConfig } from 'actions/user/userActionCreator';
import { getTimeZones } from 'actions/staticCollection/staticCollectionActionCreator';
import { deviceTypes } from 'constants/mappingOrder';
import enumTypes from 'constants/enumTypes';
import { settingKeys } from 'constants/configKeys';

const mapStateToProps = ({
  brands, brandSetting, users, staticCollection, locale,
}) => ({
  brand: selectBrand(brands),
  main: selectBrandMainSetting(brandSetting),
  markets: selectBrandMarkets(brands),
  templates: selectBrandTemplates(brands),
  expand: selectUserConfig(users, settingKeys.brandSettingsExpand),
  timeZones: selectStaticCollectionTimeZones(staticCollection),
  locale: selectLocaleResource(locale, { brandStatuses: enumTypes.brandStatuses, deviceTypes: enumTypes.deviceTypes }, { deviceTypes }),
  callEndedStatus: selectBrandCallEndedStatus(brands),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBrand,
    getMarkets,
    getTemplates,
    resetTemplates,
    createOrEditUserConfig,
    getTimeZones,
    resetBrand,
    resetBrandSettings,
    changeBrandCallEndedStatus,
    setCurrentBrand,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);
