const CampaignV2ActionType = {
  GET_CAMPAIGNS_V2: 'GET_CAMPAIGNS_V2',
  SAVE_CAMPAIGNS_V2: 'SAVE_CAMPAIGNS_V2',
  RESET_CAMPAIGNS_V2: 'RESET_CAMPAIGNS_V2',

  GET_CAMPAIGN_V2: 'GET_CAMPAIGN_V2',
  SAVE_CAMPAIGN_V2: 'SAVE_CAMPAIGN_V2',
  RESET_CAMPAIGN_V2: 'RESET_CAMPAIGN_V2',

  CREATE_CAMPAIGN_V2: 'CREATE_CAMPAIGN_V2',
  SAVE_CREATED_CAMPAIGN_V2_ID: 'SAVE_CREATED_CAMPAIGN_V2_ID',
  CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS',
  EDIT_CAMPAIGN_V2: 'EDIT_CAMPAIGN_V2',

  GET_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'GET_CAMPAIGN_V2_PARTICIPATION_GROUPS',
  SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS',
  RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS: 'RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS',

  GET_CAMPAIGN_V2_PARTICIPATION_GROUP: 'GET_CAMPAIGN_V2_PARTICIPATION_GROUP',
  SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  RESET_CAMPAIGN_V2_PARTICIPATION_GROUP: 'RESET_CAMPAIGN_V2_PARTICIPATION_GROUP',

  CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP: 'EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP',
  DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP: 'DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP',
  CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS',

  GET_CAMPAIGN_V2_BONUSES: 'GET_CAMPAIGN_V2_BONUSES',
  SAVE_CAMPAIGN_V2_BONUSES: 'SAVE_CAMPAIGN_V2_BONUSES',
  RESET_CAMPAIGN_V2_BONUSES: 'RESET_CAMPAIGN_V2_BONUSES',

  GET_CAMPAIGN_V2_FREE_BET_BONUS: 'GET_CAMPAIGN_V2_FREE_BET_BONUS',
  SAVE_CAMPAIGN_V2_FREE_BET_BONUS: 'SAVE_CAMPAIGN_V2_FREE_BET_BONUS',
  RESET_CAMPAIGN_V2_FREE_BET_BONUS: 'RESET_CAMPAIGN_V2_FREE_BET_BONUS',

  CREATE_CAMPAIGN_V2_FREE_BET_BONUS: 'CREATE_CAMPAIGN_V2_FREE_BET_BONUS',
  EDIT_CAMPAIGN_V2_FREE_BET_BONUS: 'EDIT_CAMPAIGN_V2_FREE_BET_BONUS',
  DELETE_CAMPAIGN_V2_FREE_BET_BONUS: 'DELETE_CAMPAIGN_V2_FREE_BET_BONUS',

  GET_CAMPAIGN_V2_FREE_SPIN_BONUS: 'GET_CAMPAIGN_V2_FREE_SPIN_BONUS',
  SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS',
  RESET_CAMPAIGN_V2_FREE_SPIN_BONUS: 'RESET_CAMPAIGN_V2_FREE_SPIN_BONUS',

  CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS',
  EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS: 'EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS',
  DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS: 'DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS',

  GET_CAMPAIGN_V2_SPECIAL_BONUS: 'GET_CAMPAIGN_V2_SPECIAL_BONUS',
  SAVE_CAMPAIGN_V2_SPECIAL_BONUS: 'SAVE_CAMPAIGN_V2_SPECIAL_BONUS',
  RESET_CAMPAIGN_V2_SPECIAL_BONUS: 'RESET_CAMPAIGN_V2_SPECIAL_BONUS',

  CREATE_CAMPAIGN_V2_SPECIAL_BONUS: 'CREATE_CAMPAIGN_V2_SPECIAL_BONUS',
  EDIT_CAMPAIGN_V2_SPECIAL_BONUS: 'EDIT_CAMPAIGN_V2_SPECIAL_BONUS',
  DELETE_CAMPAIGN_V2_SPECIAL_BONUS: 'DELETE_CAMPAIGN_V2_SPECIAL_BONUS',

  GET_CAMPAIGN_V2_COMBINED_BONUS: 'GET_CAMPAIGN_V2_COMBINED_BONUS',
  SAVE_CAMPAIGN_V2_COMBINED_BONUS: 'SAVE_CAMPAIGN_V2_COMBINED_BONUS',
  RESET_CAMPAIGN_V2_COMBINED_BONUS: 'RESET_CAMPAIGN_V2_COMBINED_BONUS',

  CREATE_CAMPAIGN_V2_COMBINED_BONUS: 'CREATE_CAMPAIGN_V2_COMBINED_BONUS',
  EDIT_CAMPAIGN_V2_COMBINED_BONUS: 'EDIT_CAMPAIGN_V2_COMBINED_BONUS',
  DELETE_CAMPAIGN_V2_COMBINED_BONUS: 'DELETE_CAMPAIGN_V2_COMBINED_BONUS',

  CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS',

  GET_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'GET_CAMPAIGN_V2_TRIGGER_CONDITIONS',
  SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS',
  RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS: 'RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS',

  GET_CAMPAIGN_V2_TRIGGER_CONDITION: 'GET_CAMPAIGN_V2_TRIGGER_CONDITION',
  SAVE_CAMPAIGN_V2_TRIGGER_CONDITION: 'SAVE_CAMPAIGN_V2_TRIGGER_CONDITION',
  RESET_CAMPAIGN_V2_TRIGGER_CONDITION: 'RESET_CAMPAIGN_V2_TRIGGER_CONDITION',

  CREATE_CAMPAIGN_V2_TRIGGER_CONDITION: 'CREATE_CAMPAIGN_V2_TRIGGER_CONDITION',
  EDIT_CAMPAIGN_V2_TRIGGER_CONDITION: 'EDIT_CAMPAIGN_V2_TRIGGER_CONDITION',
  DELETE_CAMPAIGN_V2_TRIGGER_CONDITION: 'DELETE_CAMPAIGN_V2_TRIGGER_CONDITION',
  CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS',

  GET_CAMPAIGN_V2_BONUS_CONTENT: 'GET_CAMPAIGN_V2_BONUS_CONTENT',
  SAVE_CAMPAIGN_V2_BONUS_CONTENT: 'SAVE_CAMPAIGN_V2_BONUS_CONTENT',
  RESET_CAMPAIGN_V2_BONUS_CONTENT: 'RESET_CAMPAIGN_V2_BONUS_CONTENT',
  CREATE_CAMPAIGN_V2_BONUS_CONTENT: 'CREATE_CAMPAIGN_V2_BONUS_CONTENT',
  EDIT_CAMPAIGN_V2_BONUS_CONTENT: 'EDIT_CAMPAIGN_V2_BONUS_CONTENT',
  DELETE_CAMPAIGN_V2_BONUS_CONTENT: 'DELETE_CAMPAIGN_V2_BONUS_CONTENT',
  CHANGE_CAMPAIGN_V2_BONUS_CONTENT_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_BONUS_CONTENT_ENDED_STATUS',

  GET_CAMPAIGN_V2_BONUS_CONTENTS: 'GET_CAMPAIGN_V2_BONUS_CONTENTS',
  SAVE_CAMPAIGN_V2_BONUS_CONTENTS: 'SAVE_CAMPAIGN_V2_BONUS_CONTENTS',
  RESET_CAMPAIGN_V2_BONUS_CONTENTS: 'RESET_CAMPAIGN_V2_BONUS_CONTENTS',

  GET_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'GET_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  RESET_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'RESET_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  CREATE_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'CREATE_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  EDIT_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'EDIT_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  DELETE_CAMPAIGN_V2_CAMPAIGN_CONTENT: 'DELETE_CAMPAIGN_V2_CAMPAIGN_CONTENT',
  CHANGE_CAMPAIGN_V2_CAMPAIGN_CONTENT_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_CAMPAIGN_CONTENT_ENDED_STATUS',

  GET_CAMPAIGN_V2_CAMPAIGN_CONTENTS: 'GET_CAMPAIGN_V2_CAMPAIGN_CONTENTS',
  SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENTS: 'SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENTS',
  RESET_CAMPAIGN_V2_CAMPAIGN_CONTENTS: 'RESET_CAMPAIGN_V2_CAMPAIGN_CONTENTS',

  GET_CAMPAIGN_V2_OFFERS_CONTENT: 'GET_CAMPAIGN_V2_OFFERS_CONTENT',
  SAVE_CAMPAIGN_V2_OFFERS_CONTENT: 'SAVE_CAMPAIGN_V2_OFFERS_CONTENT',
  RESET_CAMPAIGN_V2_OFFERS_CONTENT: 'RESET_CAMPAIGN_V2_OFFERS_CONTENT',
  CREATE_CAMPAIGN_V2_OFFERS_CONTENT: 'CREATE_CAMPAIGN_V2_OFFERS_CONTENT',
  EDIT_CAMPAIGN_V2_OFFERS_CONTENT: 'EDIT_CAMPAIGN_V2_OFFERS_CONTENT',
  DELETE_CAMPAIGN_V2_OFFERS_CONTENT: 'DELETE_CAMPAIGN_V2_OFFERS_CONTENT',
  CHANGE_CAMPAIGN_V2_OFFERS_CONTENT_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_OFFERS_CONTENT_ENDED_STATUS',

  GET_CAMPAIGN_V2_OFFERS_CONTENTS: 'GET_CAMPAIGN_V2_OFFERS_CONTENTS',
  SAVE_CAMPAIGN_V2_OFFERS_CONTENTS: 'SAVE_CAMPAIGN_V2_OFFERS_CONTENTS',
  RESET_CAMPAIGN_V2_OFFERS_CONTENTS: 'RESET_CAMPAIGN_V2_OFFERS_CONTENTS',

  GET_CAMPAIGN_V2_UNITS: 'GET_CAMPAIGN_V2_UNITS',
  SAVE_CAMPAIGN_V2_UNITS: 'SAVE_CAMPAIGN_V2_UNITS',
  RESET_CAMPAIGN_V2_UNITS: 'RESET_CAMPAIGN_V2_UNITS',

  GET_CAMPAIGN_V2_UNIT: 'GET_CAMPAIGN_V2_UNIT',
  SAVE_CAMPAIGN_V2_UNIT: 'SAVE_CAMPAIGN_V2_UNIT',
  RESET_CAMPAIGN_V2_UNIT: 'RESET_CAMPAIGN_V2_UNIT',

  CREATE_CAMPAIGN_V2_UNIT: 'CREATE_CAMPAIGN_V2_UNIT',
  EDIT_CAMPAIGN_V2_UNIT: 'EDIT_CAMPAIGN_V2_UNIT',
  DELETE_CAMPAIGN_V2_UNIT: 'DELETE_CAMPAIGN_V2_UNIT',

  CHANGE_CAMPAIGN_V2_UNIT_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_UNIT_ENDED_STATUS',

  PUBLISH_CAMPAIGN_V2: 'PUBLISH_CAMPAIGN_V2',
  CHANGE_CAMPAIGN_V2_PUBLISH_ENDED_STATUS: 'CHANGE_CAMPAIGN_V2_PUBLISH_ENDED_STATUS',

  GET_CAMPAIGN_V2_NO_TRIGGER_STATISTICS: 'GET_CAMPAIGN_V2_NO_TRIGGER_STATISTICS',
  SAVE_CAMPAIGN_V2_STATISTICS: 'SAVE_CAMPAIGN_V2_STATISTICS',
  RESET_CAMPAIGN_V2_STATISTICS: 'RESET_CAMPAIGN_V2_STATISTICS',
  EXPORT_CAMPAIGN_V2_NO_TRIGGER_STATISTICS: 'EXPORT_CAMPAIGN_V2_NO_TRIGGER_STATISTICS',

  GET_CAMPAIGN_V2_SIGN_IN_STATISTICS: 'GET_CAMPAIGN_V2_SIGN_IN_STATISTICS',
  EXPORT_CAMPAIGN_V2_SIGN_IN_STATISTICS: 'EXPORT_CAMPAIGN_V2_SIGN_IN_STATISTICS',

  GET_CAMPAIGN_V2_SIGN_UP_STATISTICS: 'GET_CAMPAIGN_V2_SIGN_UP_STATISTICS',
  EXPORT_CAMPAIGN_V2_SIGN_UP_STATISTICS: 'EXPORT_CAMPAIGN_V2_SIGN_UP_STATISTICS',

  GET_CAMPAIGN_V2_VERIFICATION_STATISTICS: 'GET_CAMPAIGN_V2_VERIFICATION_STATISTICS',
  EXPORT_CAMPAIGN_V2_VERIFICATION_STATISTICS: 'EXPORT_CAMPAIGN_V2_VERIFICATION_STATISTICS',

  GET_CAMPAIGN_V2_DEPOSIT_STATISTICS: 'GET_CAMPAIGN_V2_DEPOSIT_STATISTICS',
  EXPORT_CAMPAIGN_V2_DEPOSIT_STATISTICS: 'EXPORT_CAMPAIGN_V2_DEPOSIT_STATISTICS',

  GET_CAMPAIGN_V2_UNIT_PLAYERS: 'GET_CAMPAIGN_V2_UNIT_PLAYERS',
  SAVE_CAMPAIGN_V2_UNIT_PLAYERS: 'SAVE_CAMPAIGN_V2_UNIT_PLAYERS',
  RESET_CAMPAIGN_V2_UNIT_PLAYERS: 'RESET_CAMPAIGN_V2_UNIT_PLAYERS',

  GET_CAMPAIGNS_V2_UNITS: 'GET_CAMPAIGNS_V2_UNITS',
  SAVE_CAMPAIGNS_V2_UNITS: 'SAVE_CAMPAIGNS_V2_UNITS',
  RESET_CAMPAIGNS_V2_UNITS: 'RESET_CAMPAIGNS_V2_UNITS',

  GET_CAMPAIGN_V2_FREE_BET_BONUSES: 'GET_CAMPAIGN_V2_FREE_BET_BONUSES',
  SAVE_CAMPAIGN_V2_FREE_BET_BONUSES: 'SAVE_CAMPAIGN_V2_FREE_BET_BONUSES',
  RESET_CAMPAIGN_V2_FREE_BET_BONUSES: 'RESET_CAMPAIGN_V2_FREE_BET_BONUSES',

  GET_CAMPAIGN_V2_SPIN_BONUSES: 'GET_CAMPAIGN_V2_SPIN_BONUSES',
  SAVE_CAMPAIGN_V2_SPIN_BONUSES: 'SAVE_CAMPAIGN_V2_SPIN_BONUSES',
  RESET_CAMPAIGN_V2_SPIN_BONUSES: 'RESET_CAMPAIGN_V2_SPIN_BONUSES',

  GET_CAMPAIGN_V2_WAGER_BONUSES: 'GET_CAMPAIGN_V2_WAGER_BONUSES',
  SAVE_CAMPAIGN_V2_WAGER_BONUSES: 'SAVE_CAMPAIGN_V2_WAGER_BONUSES',
  RESET_CAMPAIGN_V2_WAGER_BONUSES: 'RESET_CAMPAIGN_V2_WAGER_BONUSES',

  GET_CAMPAIGN_V2_SPECIAL_BONUSES: 'GET_CAMPAIGN_V2_SPECIAL_BONUSES',
  SAVE_CAMPAIGN_V2_SPECIAL_BONUSES: 'SAVE_CAMPAIGN_V2_SPECIAL_BONUSES',
  RESET_CAMPAIGN_V2_SPECIAL_BONUSES: 'RESET_CAMPAIGN_V2_SPECIAL_BONUSES',

  GET_CROSS_PLATFORM_CAMPAIGNS_V2: 'GET_CROSS_PLATFORM_CAMPAIGNS_V2',
  SAVE_CROSS_PLATFORM_CAMPAIGNS_V2: 'SAVE_CROSS_PLATFORM_CAMPAIGNS_V2',
  RESET_CROSS_PLATFORM_CAMPAIGNS_V2: 'RESET_CROSS_PLATFORM_CAMPAIGNS_V2',

  GET_CROSS_PLATFORM_CAMPAIGN_V2: 'GET_CROSS_PLATFORM_CAMPAIGN_V2',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNITS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNITS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',

  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',
  CREATE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'CREATE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',
  EDIT_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'EDIT_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',
  DELETE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT: 'DELETE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS',

  PUBLISH_CROSS_PLATFORM_CAMPAIGN_V2: 'PUBLISH_CROSS_PLATFORM_CAMPAIGN_V2',
  FINISH_CROSS_PLATFORM_CAMPAIGN_V2: 'FINISH_CROSS_PLATFORM_CAMPAIGN_V2',

  GET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS: 'GET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS',
  SAVE_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS: 'SAVE_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS',
  RESET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS: 'RESET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS',
  EXPORT_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS: 'EXPORT_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS',
};

export default CampaignV2ActionType;
