export const selectBrandAutomationExist = (state) => state.isBrandAutomationExist;
const selectConditionsActions = (state) => state.conditionsActions;

export const selectConditions = (state) => selectConditionsActions(state).conditions;
export const selectActions = (state) => selectConditionsActions(state).actions;

const selectBrandAutomationRules = (state) => state.brandAutomationRules;
export const selectBrandAutomationRulesData = (state) => selectBrandAutomationRules(state).data;
export const selectBrandAutomationRulesTotal = (state) => selectBrandAutomationRules(state).total;

export const selectBrandAutomationRule = (state) => state.brandAutomationRule;
export const selectBrandAutomationRuleName = (state) => selectBrandAutomationRule(state).name;

const selectBaTransactionLogs = (state) => state.brandAutomationTransactionLogs;
export const selectBaTransactionLogsData = (state) => selectBaTransactionLogs(state).data;
export const selectBaTransactionLogsTotal = (state) => selectBaTransactionLogs(state).total;

const selectBaPeriodicalLogs = (state) => state.brandAutomationPeriodicalLogs;
export const selectBaPeriodicalLogsData = (state) => selectBaPeriodicalLogs(state).data;
export const selectBaPeriodicalLogsTotal = (state) => selectBaPeriodicalLogs(state).total;

const selectBaSignUpLogs = (state) => state.brandAutomationSignUpLogs;
export const selectBaSignUpLogsData = (state) => selectBaSignUpLogs(state).data;
export const selectBaSignUpLogsTotal = (state) => selectBaSignUpLogs(state).total;

const selectBaVerificationLogs = (state) => state.brandAutomationVerificationLogs;
export const selectBaVerificationLogsData = (state) => selectBaVerificationLogs(state).data;
export const selectBaVerificationLogsTotal = (state) => selectBaVerificationLogs(state).total;

const selectBaVerificationDiscardLogs = (state) => state.brandAutomationVerificationDiscardLogs;
export const selectBaVerificationDiscardLogsData = (state) => selectBaVerificationDiscardLogs(state).data;
export const selectBaVerificationDiscardLogsTotal = (state) => selectBaVerificationDiscardLogs(state).total;

const selectBaAccountUpdateLogs = (state) => state.brandAutomationAccountUpdateLogs;
export const selectBaAccountUpdateLogsData = (state) => selectBaAccountUpdateLogs(state).data;
export const selectBaAccountUpdateLogsTotal = (state) => selectBaAccountUpdateLogs(state).total;
