import TaxesActionType from 'actions/taxes/taxesActionType';

const initialState = {
  depositTaxes: {},
  withdrawalTaxes: {},
  setting: {},
};

const taxesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TaxesActionType.SAVE_DEPOSIT_TAXES:
    return {
      ...state,
      depositTaxes: payload.data,
    };
  case TaxesActionType.RESET_DEPOSIT_TAXES:
    return {
      ...state,
      depositTaxes: {},
    };
  case TaxesActionType.SAVE_WITHDRAWAL_TAXES:
    return {
      ...state,
      withdrawalTaxes: payload.data,
    };
  case TaxesActionType.RESET_WITHDRAWAL_TAXES:
    return {
      ...state,
      withdrawalTaxes: {},
    };
  case TaxesActionType.SAVE_TAX_SETTING:
    return {
      ...state,
      setting: payload.data,
    };
  case TaxesActionType.RESET_TAX_SETTING:
    return {
      ...state,
      setting: {},
    };
  default:
    return state;
  }
};

export default taxesReducer;
