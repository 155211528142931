export default {
  en: {
    dateTagged: 'Date Tagged',
    taggedBy: 'Tagged By',
    unTag: 'Untag',
    tagInfo: 'Tags separated with commas and created tags will be automatically assigned. E.g: sports,casino,depositer.',
    tagName: 'Tag Name',
    categoryName: 'Category Name',
    moveTags: 'Move Tags',
    deleteTagInfo: 'The tags will be deleted with the category if you do not move those into another category. If you still delete the tags then those will be removed from players as well.',
    deleteVerticalTagCategoryInfo: 'The category will be removed with all tags from all functionalities.',
    deleteTagNote: 'Note: The tag will be deleted from all players',
    removedTagNote: 'Note: These tags will be removed from all players.',
    tagPlayers: 'Tag Players',
    allowedInfo: "You are allowed to choose whether to keep tag on players' accounts when changing category",
    keepTagsOnPlayers: 'Keep tags on players',
    keepExisting: 'Keep Existing',
    createNew: 'Create New',
    runTheRuleBeforeFinish: 'Run the rule before finish',
    ruleName: 'Rule Name',
    ruleDescription: 'Rule description',
    editRule: 'Edit {{title}} Rule',
    createRule: 'Create a New Rule',
    transactionConditions: 'Transaction Conditions',
    playerConditions: 'Player Conditions',
    addConditions: 'Add Conditions',
    addActions: 'Add Actions',
    withdrawalConditions: 'Withdrawal Conditions',
    open: 'Open',
    values: 'Value(s)',
    withdrawalId: 'Withdrawal ID',
    actionPerformed: 'Action Performed',
    countOfTransactions: 'Count Of Transactions',
    actionsPerformed: 'Actions Performed',
    sumAmount: 'Sum Amount',
    amountMin: 'Amount Min',
    amountMax: 'Amount Max',
    segmentDescription: 'Segment Description',
    lSegment: 'segment',
    segmentId: 'Segment ID',
    gameCategories: 'Game Categories',
    relatedBonusTemplates: 'Related Bonus Templates',
    lGameSegment: 'game segment',
    theseTags: 'these tags',
    ruleEndDate: 'Rule end date',
    theSegment: 'The segment',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    oneMustBeFilled: 'At least one of the Count or Sum Amount fields must be filled',
    theTag: 'The tag',
    saveAndSelectTrigger: 'Save and select trigger',
    addCondition: 'Add Condition',
    addAction: 'Add Action',
    widgetListInfo: 'Make sure to create a dashboard before moving widgets into it. Drag and drop of widgets will get available as soon as a dashboard is created',
    triggerSpecific: 'Trigger Specific',
    rules: 'Rules',
    lRule: 'rule',
    ruleStatistics: 'Rule Statistics',
    periodStart: 'Period Start',
    periodEnd: 'Period End',
    performedDate: 'Performed Date',
    performedWithdrawalsDescription: 'Selects and checks players against their withdrawals in a specified period of time defined in the rule details.',
    performedDepositsDescription: 'Selects and checks players against their deposits in a specified period of time defined in the rule details.',
    performedBetsDescription: 'Selects and checks players against their bets in a specified period of time defined in the rule details.',
    revenueDescription: 'Selects and checks players against their revenue in a specified period of time defined in the rule details.',
    withdrawalTransactionDescription: 'Selects and checks each withdrawal transaction of the players.',
    signUpDescription: 'Selects and checks players when they sign-up.',
    verifyDescription: 'Selects and checks specific verification defined in the rule details.',
    resources: 'Resources',
    disableReasonInfo: 'If you disable the reason, it will be unassigned from all used resources. Please check it before disabling it.',
    enableReasonInfo: 'If you enable the reason, it will be assigned to all included resources. Please check it before enabling it.',
    disableReasonFromResourceInfo: 'If you disable the reason, it will be unassigned from the resource. Please check it before disabling it',
    enableReasonFromResourceInfo: 'If you enable the reason, it will be assigned to resource. Please check it before enabling it.',
    deleteDefaultReasonInfo: 'The reason is default, it’s not possible to delete it.',
    lReason: 'reason',
    assignedReasons: 'Assigned Reasons',
    assignedBy: 'Assigned By',
    assignmentDate: 'Assignment Date',
    addReasons: 'Add Reasons',
    addReasonsInfo: 'Add Reasons activation will make reasons field as a required in Tags assignment',
    disableTagCategoryInfo: 'If you disable the category, it will be unassigned from all used resources. Please check it before disabling it.',
    enableTagCategoryInfo: 'If you enable the category, it will be assigned to all included resources. Please check it before enabling it.',
    disableTagInfo: 'If you disable the tag, it will be unassigned from all used resources. Please check it before disabling it.',
    enableTagInfo: 'If you enable the tag, it will be assigned to all included resources. Please check it before enabling it.',
    keepOnPlayers: 'Keep On Players',
    lTags: 'tags',
    lTheTag: 'the tag',
    assignTag: 'Assign Tag',
    verticalTag: 'Vertical Tag',
    setColor: 'Set color',
    editDefaultReasonInfo: 'The reason is default, it’s not possible to edit it.',
    reasonIsDisabled: 'The reason is disabled from the reasons system.',
    manageWithdrawalHint:
      'In case the transaction is corresponding to a few rule conditions, the system will prioritize the withdrawal Update and include the withdrawal only in one rule. Priorities are the following - 1. Freeze, 2. Decline, 3. Cancel, 4. Approve',
    product: 'Product',
    package: 'Package',
    withNoDebtBalance: 'Players with 0 Debt Balance',
    deleteSystemReasonInfo: 'The system reasons can not be deleted.',
    editSystemReasonInfo: 'The system reasons are not editable.',
    disableSystemReasonInfo: 'The system reasons can not be disabled.',
    submittedBy: 'Submitted By',
    statusDetails: 'Status Details',
    fileType: 'File Type',
    fileSize: 'File Size',
    tagsPlayers: "Tag's Players",
    actionTypes: 'Action Types',
    statusChangeDelay: 'Status Change Delay',
    statusChangeDelayInfo: "The delay set in the field will work only for the 'Manage Withdrawal' action. Other actions added within the same rule will work without any delay",
    withdrawalStatus: 'Withdrawal Status',
    providerCategory: 'Provider/Category',
    uploadCSV: 'Upload CSV',
    tagTransactionInfo: 'It is possible to assign maximum of 3 assignments during one assignment',
    unVerifyDescription: 'Selects and checks specific verifications discard in the rules.',
    verificationDiscard: 'Verification Discard',
    accountUpdateDescription: 'Selects and checks players when their account is being updated.',
    reasonNameInfo: 'The name value will be used as the Default translation for players',
    tagID: 'Tag ID',
    accountUpdateStatistics: 'Account Update Statistics',
    field: 'Field',
    citizenship: 'Citizenship',
  },
  es: {
    dateTagged: 'Fecha etiquetada',
    taggedBy: 'Etiquetado por',
    unTag: 'Desetiquetar',
    tagInfo: 'Las etiquetas separadas con comas y las etiquetas creadas se asignarán automáticamente. Por ejemplo: deportes,casino,depositante.',
    tagName: 'Nombre de la etiqueta',
    categoryName: 'Nombre de la categoría',
    moveTags: 'Mover etiquetas',
    deleteTagInfo: 'Las etiquetas se borrarán con la categoría si no las mueve a otra categoría. Si sigue borrando las etiquetas, estas también se eliminarán de los jugadores.',
    deleteTagNote: 'Nota: la etiqueta se eliminará de todos los jugadores',
    removedTagNote: 'Nota: estas etiquetas se eliminarán de todos los jugadores.',
    tagPlayers: 'Jugadores con etiqueta',
    allowedInfo: 'Se puede elegir si se mantiene la etiqueta en las cuentas de los jugadores al cambiar de categoría',
    keepTagsOnPlayers: 'Mantener las etiquetas en los jugadores',
    keepExisting: 'Mantener la existente',
    createNew: 'Crear nueva',
    runTheRuleBeforeFinish: 'Ejecutar la regla antes de terminar',
    ruleName: 'Nombre de la regla',
    ruleDescription: 'Descripción de la regla',
    editRule: 'Editar regla {{title}}',
    createRule: 'Crear una nueva regla',
    transactionConditions: 'Condiciones de la transacción',
    playerConditions: 'Condiciones de jugador',
    addConditions: 'Añadir condiciones',
    addActions: 'Añadir acciones',
    withdrawalConditions: 'Condiciones de retiro',
    open: 'Abierto',
    values: 'Valor(es)',
    withdrawalId: 'Id de retiro',
    actionPerformed: 'Acción realizada',
    countOfTransactions: 'Recuento de transacciones',
    actionsPerformed: 'Acciones realizadas',
    sumAmount: 'Importe de la suma',
    amountMin: 'Importe mín.',
    amountMax: 'Importe máx.',
    segmentDescription: 'Descripción del segmento',
    lSegment: 'segmento',
    segmentId: 'ID del segmento',
    gameCategories: 'Categorías del juego',
    relatedBonusTemplates: 'Plantillas de bono relacionadas',
    lGameSegment: 'segmento del juego',
    theseTags: 'estas etiquetas',
    ruleEndDate: 'Fecha de finalización de la regla',
    theSegment: 'El segmento',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    oneMustBeFilled: 'Al menos uno de los campos Recuento o Importe de la suma se debe rellenar',
    theTag: 'La etiqueta',
    saveAndSelectTrigger: 'Seleccionar y guardar activador',
    addCondition: 'Añadir condición',
    addAction: 'Añadir acción',
    widgetListInfo:
      'Asegúrese de crear un panel de control antes de mover los widgets en él. La posibilidad de arrastrar y soltar los widgets estará disponible tan pronto como se cree un panel de control',
    performedWithdrawalsDescription: 'Selecciona y comprueba las retiradas de los jugadores en un periodo de tiempo específico definido en los detalles de la normativa.',
    performedDepositsDescription: 'Selecciona y comprueba los depósitos de los jugadores en un periodo de tiempo específico definido en los detalles de la normativa.',
    performedBetsDescription: 'Selecciona y comprueba a los jugadores según sus apuestas en un periodo de tiempo específico definido en los detalles de la normativa.',
    revenueDescription: 'Selecciona y comprueba a los jugadores según sus ingresos en un periodo de tiempo específico definido en los detalles de la normativa.',
    withdrawalTransactionDescription: 'Selecciona y comprueba cada transacción de retirada de los jugadores.',
    signUpDescription: 'Selecciona y comprueba a los jugadores cuando se registran.',
    verifyDescription: 'Selecciona y comprueba la verificación específica definida en los detalles de la normativa.',
    triggerSpecific: 'Específicos para el trigger',
    rules: 'Reglas',
    lRule: 'Regla',
    ruleStatistics: 'Estadísticas de las normas',
    periodStart: 'Inicio del período',
    periodEnd: 'Fin del período',
    performedDate: 'Fecha de realización',
    resources: 'Recursos',
    disableReasonInfo: 'Si desactiva el motivo, se desasignará de todos los recursos utilizados. Por favor, compruébalo antes de deshabilitarlo.',
    enableReasonInfo: 'Si habilita la razón, se asignará a todos los recursos incluidos. Por favor, compruébalo antes de habilitarlo.',
    disableReasonFromResourceInfo: 'Si desactiva el motivo, se desasignará del recurso. Por favor, compruébalo antes de deshabilitarlo',
    enableReasonFromResourceInfo: 'Si se habilita la razón, se asignará al recurso. Por favor, compruébalo antes de habilitarlo.',
    lReason: 'motivo',
    assignedReasons: 'Motivos asignados',
    assignedBy: 'Asignado por',
    assignmentDate: 'Fecha de asignación',
    addReasons: 'Añadir razones',
    addReasonsInfo: 'La activación de Añadir Motivos hará que el campo de los motivos sea obligatorio en la asignación de Etiquetas',
    disableTagCategoryInfo: 'Si desactiva la categoría, se desasignará de todos los recursos utilizados. Por favor, compruébalo antes de deshabilitarla.',
    enableTagCategoryInfo: 'Si habilita la categoría, se asignará a todos los recursos incluidos. Por favor, compruébelo antes de habilitarla.',
    disableTagInfo: 'Si desactiva la etiqueta, se desasignará de todos los recursos utilizados. Por favor, compruébalo antes de deshabilitarla.',
    enableTagInfo: 'Si habilita la etiqueta, se asignará a todos los recursos incluidos. Por favor, compruébalo antes de habilitarla.',
    keepOnPlayers: 'Seguir jugando',
    lTags: 'Etiquetas',
    lTheTag: 'La Etiqueta',
    assignTag: 'Asignar etiqueta',
    verticalTag: 'Etiqueta vertical',
    setColor: 'Establecer el color',
    reasonIsDisabled: 'La razón se desactiva del sistema de motivos.',
    deleteVerticalTagCategoryInfo: 'La categoría se eliminará con todas las etiquetas de todas las funcionalidades.',
    deleteDefaultReasonInfo: 'La razón es por defecto, no es posible borrarlo.',
    editDefaultReasonInfo: 'La razón es predeterminada, no es posible editarla.',
    manageWithdrawalHint:
      'En caso de que la transacción corresponda a unas cuantas condiciones de regla, el sistema priorizará la Actualización de la retirada e incluirá la retirada sólo en una regla. Las prioridades son las siguientes - 1. Congelar, 2. Rechazar, 3. Cancelar, 4. Aprobar',
    product: 'Producto',
    package: 'Paquete',
    withNoDebtBalance: 'Jugadores con 0 Saldo Deudor',
    deleteSystemReasonInfo: 'Las razones del sistema no se pueden borrar.',
    editSystemReasonInfo: 'Las razones del sistema no son editables.',
    disableSystemReasonInfo: 'Las razones del sistema no se pueden desactivar.',
    submittedBy: 'Enviado por',
    statusDetails: 'Detalles de la Situación',
    fileType: 'Tipo de Archivo',
    fileSize: 'Tamaño del Archivo',
    tagsPlayers: 'Jugadores de Tag',
    actionTypes: 'Tipos de Acción',
    statusChangeDelay: 'Retraso de Cambio de Estado',
    statusChangeDelayInfo: 'El retraso establecido en el campo sólo funcionará para la acción «Gestionar Retirada». Otras acciones añadidas dentro de la misma regla funcionarán sin ningún retraso.',
    withdrawalStatus: 'Estado de la Retirada',
    providerCategory: 'Proveedor/Categoría',
    uploadCSV: 'Subir CSV',
    tagTransactionInfo: 'Es posible asignar un máximo de 3 tareas durante una asignación',
  },
  tr: {
    dateTagged: 'Etiketlenme Tarihi',
    taggedBy: 'Etiketleyen',
    unTag: 'Etiketi Kaldır',
    tagInfo: 'Virgülle ayrılan etiketler ve oluşturulan etiketler otomatik olarak atanır. Ör.: spor,casino,para yatıran.',
    tagName: 'Etiket Adı',
    categoryName: 'Kategori Adı',
    moveTags: 'Etiketleri Taşı',
    deleteTagInfo: 'Etiketleri başka bir kategoriye taşımazsanız, etiketler kategoriyle birlikte silinecektir. Etiketleri yine de silerseniz, bunlar oyunculardan da kaldırılacaktır.',
    deleteTagNote: 'Not: Etiket bütün oyunculardan silinir',
    removedTagNote: 'Not: Bu etiketler bütün oyunculardan kaldırılır.',
    tagPlayers: 'Oyuncuları Etiketle',
    allowedInfo: 'Kategori değiştirirken oyuncuların hesaplarında etiket saklayıp saklamamayı seçebilirsiniz',
    keepTagsOnPlayers: 'Oyuncular üzerinde etiketleri sakla',
    keepExisting: 'Var Olanı Sakla',
    createNew: 'Yeni Oluştur',
    runTheRuleBeforeFinish: 'Bitmeden önce kuralı uygula',
    ruleName: 'Kural Adı',
    ruleDescription: 'Kural açıklaması',
    editRule: '{{title}} Kuralını Düzenle',
    createRule: 'Yeni Kural Oluştur',
    transactionConditions: 'İşlem Koşulları',
    playerConditions: 'Oyuncu Koşulları',
    addConditions: 'Koşul Ekle',
    addActions: 'Eylem Ekle',
    withdrawalConditions: 'Para Çekme Koşulları',
    open: 'Açık',
    values: 'Değer(ler)',
    withdrawalId: 'Para Çekme ID',
    actionPerformed: 'Gerçekleştirilen Eylem',
    countOfTransactions: 'İşlem Sayısı',
    actionsPerformed: 'Gerçekleştirilen Eylemler',
    sumAmount: 'Toplam Tutar',
    amountMin: 'Minimum Tutar',
    amountMax: 'Maksimum Tutar',
    segmentDescription: 'Bölüm Açıklaması',
    lSegment: 'bölüm',
    segmentId: "Bölüm ID'si",
    gameCategories: 'Oyun Kategorileri',
    relatedBonusTemplates: 'İlgili Bonus Şablonları',
    lGameSegment: 'oyun bölümü',
    theseTags: 'bu etiketler',
    ruleEndDate: 'Kural sonu tarihi',
    theSegment: 'Bölüm',
    horizontal: 'Yatay',
    vertical: 'Dikey',
    oneMustBeFilled: 'Sayı veya Toplam Tutar alanlarından en az biri doldurulmalı',
    theTag: 'Etiket',
    saveAndSelectTrigger: 'Kaydet ve tetikleyiciyi seç',
    addCondition: 'Koşul Ekle',
    addAction: 'Eylem Ekle',
    widgetListInfo: "Widget'ları taşımadan önce bir dashboard oluşturduğunuzdan emin olun. Dashboard oluşturulduğunda widget'ların sürükle ve bırak özelliği kullanılabilir",
    performedWithdrawalsDescription: 'Kural ayrıntılarında tanımlanan süre içinde oyuncuları para çekme işlemlerine göre seçer ve kontrol eder.',
    performedDepositsDescription: 'Kural ayrıntılarında tanımlanan süre içinde oyuncuları para yatırma işlemlerine göre seçer ve kontrol eder.',
    performedBetsDescription: 'Kural ayrıntılarında tanımlanan süre içinde oyuncuları bahislerine göre seçer ve kontrol eder.',
    revenueDescription: 'Kural ayrıntılarında tanımlanan süre içinde oyuncuları kazançlarına göre seçer ve kontrol eder.',
    withdrawalTransactionDescription: 'Oyuncuların her bir para çekme işlemini seçer ve kontrol eder.',
    signUpDescription: 'Oyuncuları kayıt olurken seçer ve kontrol eder.',
    verifyDescription: 'Kural ayrıntılarında tanımlanan belirli doğrulamayı seçer ve kontrol eder.',
    deleteVerticalTagCategoryInfo: 'Kategori, tüm etiketleriyle birlikte tüm işlevlerden kaldırılacaktır.',
    triggerSpecific: 'Tetiklemeye Özel',
    rules: 'Kurallar',
    lRule: 'kural',
    ruleStatistics: 'Kural İstatistikleri',
    periodStart: 'Dönem Başı',
    periodEnd: 'Dönem Sonu',
    performedDate: 'Gerçekleşen Tarih',
    resources: 'Kaynaklar',
    disableReasonInfo: 'Nedeni devre dışı bırakırsanız, tüm kullanılan kaynaklardan atanmamış olacaktır. Lütfen devre dışı bırakmadan önce kontrol edin.',
    enableReasonInfo: 'Nedeni etkinleştirirseniz, dahil edilen tüm kaynaklara atanacaktır. Lütfen etkinleştirmeden önce kontrol edin.',
    disableReasonFromResourceInfo: 'Nedeni devre dışı bırakırsanız, kaynaktan atanmamış olacaktır. Lütfen devre dışı bırakmadan önce kontrol edin',
    enableReasonFromResourceInfo: 'Nedeni etkinleştirirseniz, kaynağa atanacaktır. Lütfen etkinleştirmeden önce kontrol edin.',
    lReason: 'neden',
    assignedReasons: 'Atanan Nedenler',
    assignedBy: 'Tarafından atanma',
    assignmentDate: 'Atanma Tarihi',
    addReasons: 'Neden Ekle',
    addReasonsInfo: 'Neden Ekle aktivasyonu, Etiketler atamasında nedenler alanını zorunlu hale getirecektir',
    disableTagCategoryInfo: 'Kategoriyi devre dışı bırakırsanız, kullanılan tüm kaynaklardan ataması kaldırılacaktır. Lütfen devre dışı bırakmadan önce kontrol edin.',
    enableTagCategoryInfo: 'Kategoriyi etkinleştirirseniz, dahil edilen tüm kaynaklara atanacaktır. Lütfen etkinleştirmeden önce kontrol edin.',
    disableTagInfo: 'Etiketi devre dışı bırakırsanız, kullanılan tüm kaynaklardan ataması kaldırılacaktır. Lütfen devre dışı bırakmadan önce kontrol edin.',
    enableTagInfo: 'Etiketi etkinleştirirseniz, dahil edilen tüm kaynaklara atanacaktır. Lütfen etkinleştirmeden önce kontrol edin.',
    keepOnPlayers: 'Oyunculara Devam',
    lTags: 'etiketler',
    lTheTag: 'etiket',
    assignTag: 'Etiket Atama',
    verticalTag: 'Dikey Etiket',
    setColor: 'Renk ayarla',
    reasonIsDisabled: 'Neden, nedenler sisteminden devre dışı bırakılır.',
    deleteDefaultReasonInfo: 'Neden varsayılandır, silmek mümkün değildir.',
    editDefaultReasonInfo: 'Neden varsayılandır, düzenlemek mümkün değildir.',
    manageWithdrawalHint:
      'İşlemin birkaç kural durumuna karşılık gelmesi halinde, sistem para çekme Güncellemesine öncelik verecek ve para çekme işlemini yalnızca bir kurala dahil edecektir. Öncelikler aşağıdaki gibidir - 1. Dondur, 2. Reddet, 3. İptal, 4. Onayla',
    product: 'Ürün',
    package: 'Paket',
    withNoDebtBalance: '0 Borç Bakiyesi Olan Oyuncular',
    deleteSystemReasonInfo: 'Sistem nedenleri silinemez.',
    editSystemReasonInfo: 'Sistem nedenleri düzenlenemez.',
    disableSystemReasonInfo: 'Sistem nedenleri devre dışı bırakılamaz.',
    submittedBy: 'Gönderen',
    statusDetails: 'Durum Detayları',
    fileType: 'Dosya Türü',
    fileSize: 'Dosya Boyutu',
    tagsPlayers: 'Etiketin Oyuncuları',
    actionTypes: 'Eylem Türleri',
    statusChangeDelay: 'Durum Değişikliği Gecikmesi',
    statusChangeDelayInfo: "Bu alanda ayarlanan gecikme yalnızca 'Para Çekmeyi Yönet' eylemi için geçerlidir. Aynı kurala eklenen diğer eylemler herhangi bir gecikme olmadan çalışır",
    withdrawalStatus: 'Para Çekme Durumu',
    providerCategory: 'Sağlayıcı/Kategori',
    uploadCSV: 'CSV Yükle',
    tagTransactionInfo: 'Bir atamada en fazla 3 atama yapmak mümkündür',
  },
  ru: {
    dateTagged: 'Дата добавления тега',
    taggedBy: 'Тег добавлен (кем)',
    unTag: 'Удалить тег',
    tagInfo: 'Теги, отделяемые запятыми, и создаваемые теги будут назначаться автоматически. Например: спорт, казино, депозитор.',
    tagName: 'Название тега',
    categoryName: 'Название категории',
    moveTags: 'Переместить теги',
    deleteTagInfo: 'Теги будут удалены вместе с категорией, если вы не переместите их в другую категорию. Если вы все-таки удалите теги, они будут удалены также у игроков.',
    deleteTagNote: 'Примечание: тег буден удален у всех игроков',
    removedTagNote: 'Примечание: эти теги будут удалены у всех игроков.',
    tagPlayers: 'Добавить теги к игрокам',
    allowedInfo: 'Вам не разрешено выбирать, сохранять ли теги в учетных записях игроков при изменении категории',
    keepTagsOnPlayers: 'Сохранить теги у игроков',
    keepExisting: 'Сохранить существующие',
    createNew: 'Создать новые',
    runTheRuleBeforeFinish: 'Запустить правило перед завершением',
    ruleName: 'Название правила',
    ruleDescription: 'Описание правила',
    editRule: 'Изменить правило {{title}}',
    createRule: 'Создать новое правило',
    transactionConditions: 'Условия транзакции',
    playerConditions: 'Условия игроков',
    addConditions: 'Добавить условия',
    addActions: 'Добавить действия',
    withdrawalConditions: 'Условия вывода средств',
    open: 'Открыть',
    values: 'Значение(-я)',
    withdrawalId: 'ID вывода средств',
    actionPerformed: 'Выполненное действие',
    countOfTransactions: 'Количество транзакций',
    actionsPerformed: 'Выполненные действия',
    sumAmount: 'Сумма',
    amountMin: 'Минимальная сумма',
    amountMax: 'Максимальная сумма',
    segmentDescription: 'Описание сегмента',
    lSegment: 'сегмент',
    segmentId: 'ID сегмента',
    gameCategories: 'Категории игр',
    relatedBonusTemplates: 'Связанные шаблоны бонусов',
    lGameSegment: 'сегмент игр',
    theseTags: 'эти теги',
    ruleEndDate: 'Дата завершения правила',
    theSegment: 'Сегмент',
    horizontal: 'Горизонтальный',
    vertical: 'Вертикальный',
    oneMustBeFilled: 'Должно быть заполнено по крайней мере одно поле Количество или Сумма',
    theTag: 'Тег',
    saveAndSelectTrigger: 'Сохранить и выбрать триггер',
    addCondition: 'Добавить условие',
    addAction: 'Добавить действие',
    widgetListInfo: 'Создайте панель управления, прежде чем перемещать виджеты. Перетаскивание виджетов станет доступным, как только будет создана панель управления',
    performedWithdrawalsDescription: 'Выбор и проверка игроков по выводам средств за определённый период времени, указанный в правилах.',
    performedDepositsDescription: 'Выбор и проверка игроков по депозитам за определённый период времени, указанный в правилах.',
    performedBetsDescription: 'Выбор и проверка игроков по ставкам за определённый период времени, указанный в правилах.',
    revenueDescription: 'Выбор и проверка игроков по показателям их прибыли за определённый период времени, указанный в правилах.',
    withdrawalTransactionDescription: 'Выбор и проверка каждой операции игроков по выводу средств.',
    signUpDescription: 'Выбор и проверка игроков при регистрации.',
    verifyDescription: 'Выбор и проверка определённой верификации, указанной в правилах.',
    deleteVerticalTagCategoryInfo: 'Категория будет удалена вместе со всеми тегами из всех функций.',
    triggerSpecific: 'Зависящий от триггера',
    rules: 'Правила',
    lRule: 'правило',
    ruleStatistics: 'Статистика правила',
    periodStart: 'Период начала',
    periodEnd: 'Период завершения',
    performedDate: 'Дата выполнения',
    resources: 'Ресурсы',
    disableReasonInfo: 'Если вы отключите причину, она будет снята со всех используемых ресурсов. Пожалуйста, проверьте перед отключением.',
    enableReasonInfo: 'Если вы включите эту причину, она будет присвоена всем включенным ресурсам. Пожалуйста, проверьте перед включением.',
    disableReasonFromResourceInfo: 'Если вы отключите причину, она будет удалена из ресурса. Пожалуйста, проверьте перед отключением',
    enableReasonFromResourceInfo: 'Если вы включите причину, она будет присвоена ресурсу. Пожалуйста, проверьте перед включением.',
    lReason: 'причина',
    assignedReasons: 'Присвоенные причины',
    assignedBy: 'Задано (кем)',
    assignmentDate: 'Дата присвоения',
    addReasons: 'Добавить причины',
    addReasonsInfo: 'Активация «Добавить причины» будет означать, что поле причины будет являться обязательным для присвоения тегов',
    disableTagCategoryInfo: 'Если вы отключите категорию, она будет снята со всех используемых ресурсов. Пожалуйста, проверьте перед отключением.',
    enableTagCategoryInfo: 'Если вы включите категорию, она будет присвоена всем включенным ресурсам. Пожалуйста, проверьте перед включением.',
    disableTagInfo: 'Если вы отключите тег, он будет снят со всех используемых ресурсов. Пожалуйста, проверьте перед отключением.',
    enableTagInfo: 'Если вы включите тег, он будет присвоен всем включенным ресурсам. Пожалуйста, проверьте перед включением.',
    keepOnPlayers: 'Оставить для игроков',
    lTags: 'теги',
    lTheTag: 'тег',
    assignTag: 'Задать тег',
    verticalTag: 'Вертикальный тег',
    setColor: 'Установить цвет',
    reasonIsDisabled: 'Причина отключена из системы причин.',
    deleteDefaultReasonInfo: 'Причина задана по умолчанию, удалить ее невозможно.',
    editDefaultReasonInfo: 'Причина задана по умолчанию, ее невозможно редактировать.',
    manageWithdrawalHint:
      'В случае, если транзакция соответствует нескольким условиям правила, система отдаст приоритет обновлению вывода средств и включит вывод средств только в одно правило. Приоритеты следующие: 1. Заморозить, 2. Отклонить, 3. Отменить, 4. Одобрить',
    product: 'Продукт',
    package: 'Пакет',
    withNoDebtBalance: 'Игроки с нулевой задолженностью',
    deleteSystemReasonInfo: 'Системные причины не могут быть удалены.',
    editSystemReasonInfo: 'Системные причины не могут быть редактированы.',
    disableSystemReasonInfo: 'Системные причины не могут быть отключены.',
    submittedBy: 'Отправлено',
    statusDetails: 'Подробности о статусе',
    fileType: 'Тип файла',
    fileSize: 'Размер файла',
    tagsPlayers: 'Игроки Тега',
    actionTypes: 'Типы действий',
    statusChangeDelay: 'Задержка изменения статуса',
    statusChangeDelayInfo:
      'Задержка, установленная в поле, будет работать только для действия «Управление выводом средств». Другие действия, добавленные в рамках того же правила, будут работать без задержек ',
    withdrawalStatus: 'Статус вывода средств',
    providerCategory: 'Провайдер/категория',
    uploadCSV: 'Загрузить CSV',
    tagTransactionInfo: 'За одно задание можно выполнить не более 3 заданий',
  },
  pt: {
    dateTagged: 'Data Marcada (Tagged)',
    taggedBy: 'Marcada (Tagged) por',
    unTag: 'Desmarcar (Untag)',
    tagInfo: 'Tags separadas com vírgulas e tags criadas serão automaticamente atribuídas. Por exemplo: desportos, casino, depositante.',
    tagName: 'Nome da Tag',
    categoryName: 'Nome da Categoria',
    moveTags: 'Mover Tags',
    deleteTagInfo: 'As tags serão apagadas com a categoria caso não as mova para outra categoria. Se, ainda assim, apagar as tags, estas também serão removidas dos jogadores.',
    deleteTagNote: 'Observação: A tag será apagada de todos os jogadores',
    removedTagNote: 'Observação: Estas tags serão removidas de todos os jogadores.',
    tagPlayers: 'Marcar (Tag) Jogadores',
    allowedInfo: 'Pode escolher se deseja manter a tag nas contas dos jogadores ao mudar de categoria',
    keepTagsOnPlayers: 'Manter tags nos jogadores',
    keepExisting: 'Manter Existentes',
    createNew: 'Criar Novo',
    runTheRuleBeforeFinish: 'Executar a regra antes de terminar',
    ruleName: 'Nome da Regra',
    ruleDescription: 'Descrição da Regra',
    editRule: 'Editar Regra {{title}}',
    createRule: 'Criar uma Nova Regra',
    transactionConditions: 'Condições de Transação',
    playerConditions: 'Condições do Jogador',
    addConditions: 'Adicionar Condições',
    addActions: 'Adicionar Ações',
    withdrawalConditions: 'Condições de Levantamento',
    open: 'Abrir',
    values: 'Valor(es)',
    withdrawalId: 'ID do Levantamento',
    actionPerformed: 'Ação Executada',
    countOfTransactions: 'Contagem de Transações',
    actionsPerformed: 'Ações Executadas',
    sumAmount: 'Valor da Soma',
    amountMin: 'Valor Mín.',
    amountMax: 'Valor Máx.',
    segmentDescription: 'Descrição do Segmento',
    lSegment: 'segmento',
    segmentId: 'ID do Segmento',
    gameCategories: 'Categorias dos Jogos',
    relatedBonusTemplates: 'Modelos de Bónus Relacionados',
    lGameSegment: 'segmento do jogo',
    theseTags: 'estas tags',
    ruleEndDate: 'Data de Fim da Regra',
    theSegment: 'O segmento',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    oneMustBeFilled: 'Pelo menos um dos campos de Contagem ou Soma tem de ser preenchido',
    theTag: 'A tag',
    saveAndSelectTrigger: 'Gravar e selecionar o ativador',
    addCondition: 'Adicionar Condição',
    addAction: 'Adicionar Ação',
    widgetListInfo: 'Certifique-se de que cria um painel de controlo antes de mover widgets para dentro deste. Arraste e solte os widgets assim que um painel for criado',
    performedWithdrawalsDescription: 'Seleciona e verifica os saques dos jogadores em um período de tempo especificado definido nos detalhes da regra.',
    performedDepositsDescription: 'Seleciona e verifica os depósitos dos jogadores em um período de tempo especificado nos detalhes da regra.',
    performedBetsDescription: 'Seleciona e verifica os jogadores em relação às suas apostas em um período de tempo específico definido nos detalhes da regra.',
    revenueDescription: 'Seleciona e verifica os jogadores em relação às suas receitas em um período de tempo especificado nos detalhes da regra.',
    withdrawalTransactionDescription: 'Seleciona e verifica cada transação de saque dos jogadores.',
    signUpDescription: 'Seleciona e verifica os jogadores quando eles se inscrevem.',
    verifyDescription: 'Seleciona e verifica verificações específicas definidas nos detalhes da regra.',
    deleteVerticalTagCategoryInfo: 'A categoria será removida com todas as tags de todas as funcionalidades.',
    triggerSpecific: 'Ativador Específico',
    rules: 'Regras',
    lRule: 'regra',
    ruleStatistics: 'Estatísticas das Regras',
    periodStart: 'Início do Período',
    periodEnd: 'Fim do Período',
    performedDate: 'Data da Execução',
    resources: 'Recursos',
    disableReasonInfo: 'Se desativar o motivo, este ficará por atribuir a todos os recursos utilizados. Por favor, verifique antes de o desativar.',
    enableReasonInfo: 'Se ativar o motivo, este será atribuído a todos os recursos incluídos. Por favor, verifique antes de o ativar.',
    disableReasonFromResourceInfo: 'Se desativar o motivo, este ficará por atribuir ao recurso. Por favor, verifique antes de o desativar.',
    enableReasonFromResourceInfo: 'Se ativar o motivo, este será atribuído ao recurso. Por favor, verifique antes de o ativar.',
    lReason: 'motivo',
    assignedReasons: 'Motivos Atribuídos',
    assignedBy: 'Atribuído por',
    assignmentDate: 'Data da Atribuição',
    addReasons: 'Adicionar Motivos',
    addReasonsInfo: 'A ativação dos Motivos Adicionais fará com que o campo relativo aos motivos seja considerado obrigatório na atribuição das Tags',
    disableTagCategoryInfo: 'Se desativar a categoria, esta ficará por atribuir a todos os recursos utilizados. Por favor, verifique antes de o desativar.',
    enableTagCategoryInfo: 'Se ativar a categoria, esta será atribuída a todos os recursos incluídos. Por favor, verifique antes de o ativar.',
    disableTagInfo: 'Se desativar a tag, esta ficará por atribuir a todos os recursos utilizados. Por favor, verifique antes de o desativar.',
    enableTagInfo: 'Se ativar a tag, esta será atribuída a todos os recursos incluídos. Por favor, verifique antes de o ativar.',
    keepOnPlayers: 'Manter nos Jogadores',
    lTags: 'tags',
    lTheTag: 'a tag',
    assignTag: 'Atribuir Tag',
    verticalTag: 'Tag Vertical',
    setColor: 'Definir Cor',
    reasonIsDisabled: 'O motivo é desativado a partir do sistema dos motivos.',
    deleteDefaultReasonInfo: 'O motivo é padrão, não é possível eliminá-lo.',
    editDefaultReasonInfo: 'O motivo é padrão, não é possível editá-lo.',
    manageWithdrawalHint:
      'Caso a transação corresponda a algumas condições da regra, o sistema priorizará a atualização do saque e incluirá o saque em apenas uma regra. As prioridades são as seguintes - 1. Congelar, 2. Recusar, 3. Cancelar, 4. Aprovar',
    product: 'Produto',
    package: 'Pacote',
    withNoDebtBalance: 'Jogadores com 0 Saldo Devedor ',
    deleteSystemReasonInfo: 'Os motivos de sistema não podem ser eliminados.',
    editSystemReasonInfo: 'Os motivos do sistema não são editáveis.',
    disableSystemReasonInfo: 'Os motivos do sistema não podem ser desactivados.',
    submittedBy: 'Enviado por',
    statusDetails: 'Detalhes do estado',
    fileType: 'Tipo de Ficheiro',
    fileSize: 'Tamnha do Ficheiro',
    tagsPlayers: 'Jogadores de Tag',
    actionTypes: 'Tipos de Ação',
    statusChangeDelay: 'Atraso na mudança de estado',
    statusChangeDelayInfo: 'O atraso definido no campo só funcionará para a ação "Gerir levantamento". As outras acções adicionadas à mesma regra funcionarão sem qualquer atraso',
    withdrawalStatus: 'Estado de retirada',
    providerCategory: 'Fornecedor/Categoria',
    uploadCSV: 'Carregar CSV',
    tagTransactionInfo: 'É possível atribuir um máximo de 3 tarefas durante uma tarefa',
  },
  zh: {
    dateTagged: '已标记日期',
    taggedBy: '标记为',
    unTag: '取消标记',
    tagInfo: '用逗号分隔的标签和创建的标签将被自动分配。例如：体育、娱乐场、存款人。',
    tagName: '标记名称',
    categoryName: '类别名称',
    moveTags: '移动标记',
    deleteTagInfo: '如果您不把这些标记移到另一个类别中，那么这些标记就会和标记所在的类别一起被删除。如果您仍然要删除标记，那么这些标记也将从玩家身上删除。',
    deleteTagNote: '注意：所有玩家的标签都将被删除',
    removedTagNote: '注意：这些标签将从所有玩家身上移除。',
    tagPlayers: '标记玩家',
    allowedInfo: '您可以选择在改变类别时是否保留玩家账户的标签',
    keepTagsOnPlayers: '在玩家身上保留标签',
    keepExisting: '保留现有',
    createNew: '创建新的',
    runTheRuleBeforeFinish: '在结束前运行规则',
    ruleName: '规则名称',
    ruleDescription: '规则描述',
    editRule: '编辑 {{title}} 规则',
    createRule: '创建新规则',
    transactionConditions: '交易条件',
    playerConditions: '玩家条件',
    addConditions: '添加条件',
    addActions: '添加操作',
    withdrawalConditions: '取款条件',
    open: '打开',
    values: '价值',
    withdrawalId: '取款ID',
    actionPerformed: '已执行操作',
    countOfTransactions: '交易数量',
    actionsPerformed: '已执行操作',
    sumAmount: '总额',
    amountMin: '最低金额',
    amountMax: '最高金额',
    segmentDescription: '分区描述',
    lSegment: '分区',
    segmentId: '分区ID',
    gameCategories: '游戏类别',
    relatedBonusTemplates: '相关奖金模板',
    lGameSegment: '游戏分区',
    theseTags: '这些标签',
    ruleEndDate: '规则结束日期',
    theSegment: '分区',
    horizontal: '水平',
    vertical: '垂直',
    oneMustBeFilled: '您必须至少填写一个计数或总额字段',
    theTag: '标签',
    verticalCategoryInfo: '同一垂直类别中只能选择一个标记。系统会将其他标记锁定。',
    saveAndSelectTrigger: '保存并选择触发器',
    addCondition: '添加条件',
    addAction: '添加操作',
    widgetListInfo: '请确保在将组件移入信息中心之前创建一个信息中心。创建了信息中心后，您就可以使用组件拖放功能',
    performedWithdrawalsDescription: '根据规则细节中定义的指定时间段内的提款情况选择并检查玩家。',
    performedDepositsDescription: '根据规则细节中定义的指定时间段内的存款选择并检查玩家。',
    performedBetsDescription: '在规则细节规定的指定时间内，根据玩家的投注选择并检查玩家。',
    revenueDescription: '根据规则细节中定义的指定时间段内的收入，选择并检查玩家。',
    withdrawalTransactionDescription: '选择并检查玩家的每笔取款交易。',
    signUpDescription: '在玩家注册时对其进行选择和检查。',
    verifyDescription: '选择并检查规则细节中定义的特定验证。',
    ruleStatistics: '规则统计',
    periodStart: '期间开始',
    periodEnd: '期间结束',
    performedDate: '执行日期',
    resources: '资源',
    disableReasonInfo: '如果您禁用了这个原因，它将从所有使用的资源中取消分配。在禁用它之前，请检查它。',
    enableReasonInfo: '如果您启用该原因，它将被分配给所有包含的资源。请在启用它之前检查。',
    disableReasonFromResourceInfo: '如果您禁用该原因，它将被从资源中取消分配。在禁用它之前，请检查它',
    enableReasonFromResourceInfo: '如果您启用该原因，它将被分配给资源。请在启用它之前检查。',
    lReason: '原因',
    assignedReasons: '指派的原因',
    assignedBy: '指定单位',
    assignmentDate: ' 派遣日期',
    addReasons: '添加原因',
    addReasonsInfo: '添加原因的激活将使原因字段成为标签分配中的必要条件。',
    disableTagCategoryInfo: '如果您禁用该类别，它将从所有使用的资源中取消分配。在禁用它之前，请检查它。',
    enableTagCategoryInfo: '如果您启用该类别，它将被分配给所有包含的资源。请在启用前检查。',
    disableTagInfo: '如果您禁用该标签，它将从所有使用的资源中取消分配。在禁用它之前，请检查它。',
    enableTagInfo: '如果您启用该标签，它将被分配给所有包含的资源。请在启用前检查。',
    keepOnPlayers: '继续玩家',
    lTags: '标签',
    lTheTag: '标签',
    assignTag: '指派标签',
    verticalTag: '垂直标签',
    setColor: '设置颜色',
    reasonIsDisabled: '该原因在原因系统中被禁用。',
    deleteVerticalTagCategoryInfo: '该类别将与所有功能中的所有标签一起删除。',
    triggerSpecific: '触发器特定',
    rules: '规则',
    lRule: '规则',
    deleteDefaultReasonInfo: '原因是默认，不可能删除它。',
    editDefaultReasonInfo: '原因是默认，不可能编辑它。',
    manageWithdrawalHint: '如果交易与几个规则条件相对应，系统将优先考虑提款更新，只将提款列入一个规则。优先级如下 - 1.冻结，2.拒绝，3.取消，4.批准',
    product: '产品',
    package: '包装',
    withNoDebtBalance: '债务余额为0的玩家',
    deleteSystemReasonInfo: '系统原因无法删除。',
    editSystemReasonInfo: '系统原因不可编辑。',
    disableSystemReasonInfo: '系统原因无法禁用。',
    submittedBy: '提交方',
    statusDetails: '状态详情',
    fileType: '文件类型',
    fileSize: '文件大小',
    tagsPlayers: '标签的玩家',
    actionTypes: '操作类型',
    statusChangeDelay: '状态更改延迟',
    statusChangeDelayInfo: '字段中设置的延迟仅对"管理取款"操作有效。在同一规则中添加的其他操作将不会有任何延迟',
    withdrawalStatus: '取款状态',
    providerCategory: '提供商/类别',
    uploadCSV: '上传CSV',
    tagTransactionInfo: '一次任务中最多可分配3项任务',
  },
  ro: {
    dateTagged: 'Data adăugării etichetei',
    taggedBy: 'Etichetat de ',
    unTag: 'Dezetichetă',
    tagInfo: 'Etichetele separate prin virgule și etichetele create vor fi atribuite automat. De exemplu: sport, cazinou, deponent.',
    tagName: 'Numele etichetei',
    categoryName: 'Numele categoriei',
    moveTags: 'Mutare etichete',
    deleteTagInfo: 'Etichetele vor fi șterse împreună cu categoria, dacă nu le muți într-o altă categorie. Dacă tot ștergi etichetele, atunci și ele vor fi scoase de la jucători.',
    deleteTagNote: 'Notă: Eticheta va fi ștearsă din toți jucători',
    removedTagNote: 'Notă: Aceste etichete vor fi șterse de la toți jucătorii',
    tagPlayers: 'Etichetează pe jucători',
    allowedInfo: 'Este permis să alegi dacă dorești să salvezi eticheta pe conturile jucătorilor atunci când schimbi categoria',
    keepTagsOnPlayers: 'Păstrează etichetele jucătorilor',
    keepExisting: 'Păstrează cea existentă',
    createNew: 'Creează una nouă',
    runTheRuleBeforeFinish: 'Executarea regulei înainte de sfârșit',
    ruleName: 'Numele regulei',
    ruleDescription: 'Descrierea regulei',
    editRule: 'Editează regula {{title}}',
    createRule: 'Creează o nouă regulă',
    transactionConditions: 'Condiții de tranzacție',
    playerConditions: 'Condiții de jucător',
    addConditions: 'Adaugă condiții',
    addActions: 'Adaugă acțiuni',
    withdrawalConditions: 'Condiții de retragere',
    open: 'Deschide',
    values: 'Valoare(valori)',
    withdrawalId: 'ID de retragere',
    actionPerformed: 'Acțiune efectuată',
    countOfTransactions: 'Numărul de tranzacții',
    actionsPerformed: 'Acțiuni efectuate',
    sumAmount: 'Valoarea sumei',
    amountMin: 'Valoare minimă',
    amountMax: 'Valoare maximă',
    segmentDescription: 'Descrierea segmentului',
    lSegment: 'Segment',
    segmentId: 'ID de segment',
    gameCategories: 'Categorii de jocuri',
    relatedBonusTemplates: 'Șabloane de bonus înrudite',
    lGameSegment: 'segment de joc',
    theseTags: 'aceste etichete',
    ruleEndDate: 'Regulă și dată',
    theSegment: 'Segmentul',
    horizontal: 'Orizontal',
    vertical: 'Vertical',
    oneMustBeFilled: 'Trebuie completat cel puțin unul dintre câmpurile numărului sau sumei',
    theTag: 'Eticheta',
    saveAndSelectTrigger: 'Salvează și selectează declanșatorul',
    addCondition: 'Adăugare condiție',
    addAction: 'Adăugare acțiune',
    widgetListInfo:
      'Asigură-te că ai creat un tablou de bord înainte de a muta widget-urile în acesta. Trage și plasează widget-urile ce vor deveni disponibile imediat după crearea unui tablou de bord',
    triggerSpecific: 'Declanșarea acțiunii de revenire specifice\n/ Declanșator specific',
    rules: 'Reguli',
    lRule: 'regulă',
    ruleStatistics: 'Statistica regulei',
    periodStart: 'Startul perioadei',
    periodEnd: 'Sfârșitul perioadei',
    performedDate: 'Data desfășurării',
    performedWithdrawalsDescription: 'Selectează și verifică jucătorii în funcție de retragerile făcute într-o anumită perioadă de timp definită în detaliile regulii',
    performedDepositsDescription: 'Selectează și verifică jucătorii în funcție de depunerile făcute într-o anumită perioadă de timp definită în detaliile regulii',
    performedBetsDescription: 'Selectează și verifică jucătorii în funcție de pariurile plasate într-o anumită perioadă de timp definită în detaliile regulii',
    revenueDescription: 'Selectează și verifică jucătorii în funcție de veniturile lor într-o anumită perioadă de timp definită în detaliile regulii',
    withdrawalTransactionDescription: 'Selectează și verifică fiecare tranzacție de retragere a jucătorilor',
    signUpDescription: 'Selectează și verifică jucătorii atunci când aceștia  se înregistrează',
    verifyDescription: 'Selectează și verifică o anumită verificare specifică definită în detaliile regulii',
    deleteVerticalTagCategoryInfo: 'Categoria va fi eliminată împreună cu toate etichetele din toate \nfuncționalitățile.',
    resources: 'Resurse',
    disableReasonInfo: 'Dacă dezactivezi motivul, acesta va fi dezasociat din toate resursele folosite. Te rugăm să-l verifici înainte de a-l dezactiva.',
    enableReasonInfo: 'Dacă activezi motivul, acesta va fi atribuit tuturor resurselor incluse. Te rugăm să verifici înainte de a-l activa.',
    disableReasonFromResourceInfo: 'Dacă dezactivezi motivul, acesta va fi dezasociat din resursă. Te rugăm să-l verifici înainte de a-l dezactiva',
    enableReasonFromResourceInfo: 'Dacă activezi motivul, acesta va fi atribuit resursei. Te rugăm să verifici înainte de a-l activa.',
    deleteDefaultReasonInfo: 'Motivul este implicit, nu este posibil să o ștergi.',
    lReason: 'motiv ',
    assignedReasons: 'Motive atribute',
    assignedBy: 'Atribuit de',
    assignmentDate: 'Data atribuirii',
    addReasons: 'Adăugarea motivelor',
    addReasonsInfo: 'Activarea opțiunii Adăugarea motivelor va face câmpul motivelor obligatoriu în atribuirea etichetelor.',
    disableTagCategoryInfo: 'Dacă dezactivezi categoria, aceasta va fi dezasociată din toate resursele folosite. Te rugăm s-o verifici înainte de a o dezactiva.',
    enableTagCategoryInfo: 'Dacă activezi categoria, aceasta va fi atribuită tuturor resurselor incluse. Te rugăm să-o verifici înainte de activare.',
    disableTagInfo: 'Dacă dezactivezi eticheta, aceasta va fi dezasociată din toate resursele folosite. Te rugăm s-o verifici înainte de a o dezactiva.',
    enableTagInfo: 'Dacă activezi eticheta, aceasta va fi atribuită tuturor resurselor incluse. Te rugăm să o verifici înainte de a o activa.',
    keepOnPlayers: 'Păstrare jucători',
    lTags: 'Etichete',
    lTheTag: 'Etichetă',
    assignTag: 'Atribuire etichetă',
    verticalTag: 'Etichetă verticală',
    setColor: 'Setare culoare',
    editDefaultReasonInfo: 'Motivul este implicit, nu este posibil să fie editat.',
    reasonIsDisabled: 'Motivul este dezactivat din sistemul de motive.',
    manageWithdrawalHint:
      'În cazul în care tranzacția corespunde câtorva condiții ale regulii, sistemul va prioritiza actualizarea retragerii și va include retragerea doar într-o singură regulă. Prioritățile vor fi următoarele: 1. Îngheață, 2. Refuză, 3. Anulează, 4. Aprobă',
    product: 'Produs',
    package: 'Pachetă',
    withNoDebtBalance: 'Jucătorii cu soldul datoriei 0',
  },
  bg: {},
  el: {
    dateTagged: 'Ημερομηνία ανάθεσης ετικέτας',
    taggedBy: 'Η ετικέτα ανατέθηκε από',
    unTag: 'Αφαίρεση ετικέτας',
    tagInfo: 'Οι ετικέτες που διαχωρίζονται με κόμματα και οι δημιουργημένες ετικέτες θα ανατίθενται αυτόματα. Π.χ. αθλητικό στοίχημα, καζίνο, καταθέτης.',
    tagName: 'Όνομα ετικέτας',
    categoryName: 'Όνομα κατηγορίας',
    moveTags: 'Μετακίνηση ετικετών',
    deleteTagInfo: 'Οι ετικέτες θα διαγραφούν μαζί με την κατηγορία αν δεν τις μετακινήσετε σε άλλη κατηγορία. Αν όντως διαγράψετε τις ετικέτες, τότε θα αφαιρεθούν επίσης από τους παίκτες.',
    deleteVerticalTagCategoryInfo: 'Η κατηγορία θα αφαιρεθεί με όλες τις ετικέτες από όλες τις λειτουργίες.',
    deleteTagNote: 'Σημείωση: Η ετικέτα θα διαγραφεί από όλους τους παίκτες',
    removedTagNote: 'Σημείωση: Αυτές οι ετικέτες θα αφαιρεθούν από όλους τους παίκτες.',
    tagPlayers: 'Ανάθεση ετικετών σε παίκτες',
    allowedInfo: 'Μπορείτε να επιλέξετε αν θα διατηρήσετε την ετικέτα στους λογαριασμούς παικτών όταν αλλάζετε κατηγορία',
    keepTagsOnPlayers: 'Διατήρηση ετικετών σε παίκτες',
    keepExisting: 'Διατήρηση υφιστάμενων',
    createNew: 'Δημιουργία νέου',
    runTheRuleBeforeFinish: 'Εφαρμογή του κανόνα πριν από τον τερματισμό',
    ruleName: 'Όνομα κανόνα',
    ruleDescription: 'Περιγραφή κανόνα',
    editRule: 'Επεξεργασία κανόνα {{title}}',
    createRule: 'Δημιουργία νέου κανόνα',
    transactionConditions: 'Προϋποθέσεις συναλλαγής',
    playerConditions: 'Προϋποθέσεις παίκτη',
    addConditions: 'Προσθήκη προϋποθέσεων',
    addActions: 'Προσθήκη ενεργειών',
    withdrawalConditions: 'Προϋποθέσεις ανάληψης',
    open: 'Άνοιγμα',
    values: 'Τιμή(-ές)',
    withdrawalId: 'Αναγνωριστικό ανάληψης',
    actionPerformed: 'Ενέργεια που εκτελέστηκε',
    countOfTransactions: 'Αριθμός συναλλαγών',
    actionsPerformed: 'Ενέργειες που εκτελέστηκαν',
    sumAmount: 'Συνολικό ποσό',
    amountMin: 'Ελαχ. ποσό',
    amountMax: 'Μεγ. ποσό',
    segmentDescription: 'Περιγραφή τμήματος',
    lSegment: 'τμήμα',
    segmentId: 'Αναγνωριστικό τμήματος',
    gameCategories: 'Κατηγορίες παιχνιδιού',
    relatedBonusTemplates: 'Πρότυπα σχετιζόμενων μπόνους',
    lGameSegment: 'τμήμα παιχνιδιού',
    theseTags: 'αυτές οι ετικέτες',
    ruleEndDate: 'Ημερομηνία λήξης κανόνα',
    theSegment: 'Το τμήμα',
    horizontal: 'Οριζόντια',
    vertical: 'Κάθετη',
    oneMustBeFilled: 'Πρέπει να συμπληρωθεί τουλάχιστον ένα από τα πεδία αριθμού ή συνολικού ποσού',
    theTag: 'Η ετικέτα',
    saveAndSelectTrigger: 'Αποθήκευση και επιλογή ενεργοποίησης',
    addCondition: 'Προσθήκη προϋπόθεσης',
    addAction: 'Προσθήκη ενέργειας',
    widgetListInfo:
      'Βεβαιωθείτε ότι έχετε δημιουργήσει πίνακα ελέγχου πριν μετακινήσετε γραφικά στοιχεία σε αυτόν. Η μεταφορά και απόθεση γραφικών στοιχείων θα είναι διαθέσιμη αφού δημιουργηθεί ένας πίνακας ελέγχου',
    triggerSpecific: 'Συγκεκριμένη ενεργοποίηση',
    rules: 'Κανόνες',
    lRule: 'κανόνας',
    ruleStatistics: 'Στατιστικά κανόνα',
    periodStart: 'Έναρξη περιόδου',
    periodEnd: 'Λήξη περιόδου',
    performedDate: 'Ημερομηνία εκτέλεσης',
    performedWithdrawalsDescription: 'Επιλέγει και ελέγχει παίκτες συγκρίνοντας τις αναλήψεις τους σε συγκεκριμένη χρονική περίοδο που ορίζεται στα στοιχεία του κανόνα.',
    performedDepositsDescription: 'Επιλέγει και ελέγχει παίκτες συγκρίνοντας τις καταθέσεις τους σε συγκεκριμένη χρονική περίοδο που ορίζεται στα στοιχεία του κανόνα.',
    performedBetsDescription: 'Επιλέγει και ελέγχει παίκτες συγκρίνοντας τα στοιχήματά τους σε συγκεκριμένη χρονική περίοδο που ορίζεται στα στοιχεία του κανόνα.',
    revenueDescription: 'Επιλέγει και ελέγχει παίκτες συγκρίνοντας τα έσοδά τους σε συγκεκριμένη χρονική περίοδο που ορίζεται στα στοιχεία του κανόνα.',
    withdrawalTransactionDescription: 'Επιλέγει και ελέγχει κάθε συναλλαγή ανάληψης των παικτών.',
    signUpDescription: 'Επιλέγει και ελέγχει τους παίκτες όταν κάνουν εγγραφή.',
    verifyDescription: 'Επιλέγει και ελέγχει την ειδική επαλήθευση που ορίζεται στα στοιχεία του κανόνα.',
    resources: 'Πόροι',
    disableReasonInfo: 'Αν απενεργοποιήσετε την αιτία, θα καταργηθεί η ανάθεσή της από όλους τους χρησιμοποιημένους πόρους. Ελέγξτε πριν την απενεργοποιήσετε.',
    enableReasonInfo: 'Αν ενεργοποιήσετε την αιτία, θα ανατεθεί σε όλους τους συμπεριλαμβανόμενους πόρους. Ελέγξτε πριν την ενεργοποιήσετε.',
    disableReasonFromResourceInfo: 'Αν απενεργοποιήσετε την αιτία, θα καταργηθεί η ανάθεσή της από τον πόρο. Ελέγξτε πριν την απενεργοποιήσετε.',
    enableReasonFromResourceInfo: 'Αν ενεργοποιήσετε την αιτία, θα ανατεθεί στον πόρο. Ελέγξτε πριν την ενεργοποιήσετε.',
    deleteDefaultReasonInfo: 'Η αιτία είναι προεπιλεγμένη, δεν είναι δυνατή η διαγραφή της.',
    lReason: 'αιτία',
    assignedReasons: 'Ανατεθειμένες αιτίες',
    assignedBy: 'Ανατέθηκε από',
    assignmentDate: 'Ημερομηνία ανάθεσης',
    addReasons: 'Προσθήκη αιτιών',
    addReasonsInfo: 'Η ενεργοποίηση της Προσθήκης αιτιών θα καταστήσει το πεδίο των αιτιών υποχρεωτικό στην ανάθεση ετικετών',
    disableTagCategoryInfo: 'Αν απενεργοποιήσετε την κατηγορία, θα καταργηθεί η ανάθεσή της από όλους τους χρησιμοποιημένους πόρους. Ελέγξτε πριν την απενεργοποιήσετε.',
    enableTagCategoryInfo: 'Αν ενεργοποιήσετε την κατηγορία, θα ανατεθεί σε όλους τους συμπεριλαμβανόμενους πόρους. Ελέγξτε πριν την ενεργοποιήσετε.',
    disableTagInfo: 'Αν απενεργοποιήσετε την ετικέτα, θα καταργηθεί η ανάθεσή της από όλους τους χρησιμοποιημένους πόρους. Ελέγξτε πριν την απενεργοποιήσετε.',
    enableTagInfo: 'Αν ενεργοποιήσετε την ετικέτα, θα ανατεθεί σε όλους τους συμπεριλαμβανόμενους πόρους. Ελέγξτε πριν την ενεργοποιήσετε.',
    keepOnPlayers: 'Διατήρηση σε παίκτες',
    lTags: 'ετικέτες',
    lTheTag: 'η ετικέτα',
    assignTag: 'Ανάθεση ετικέτας',
    verticalTag: 'Κάθετη ετικέτα',
    setColor: 'Ρύθμιση χρώματος',
    editDefaultReasonInfo: 'Η αιτία είναι προεπιλεγμένη, δεν είναι δυνατή η επεξεργασία της.',
    reasonIsDisabled: 'Η αιτία απενεργοποιήθηκε από το σύστημα αιτιών.',
    manageWithdrawalHint:
      'Σε περίπτωση που η συναλλαγή αντιστοιχεί σε διάφορες προϋποθέσεις κανόνων, το σύστημα θα θέσει σε προτεραιότητα την Ενημέρωση ανάληψης και θα συμπεριλάβει την ανάληψη μόνο σε έναν κανόνα. Οι προτεραιότητες είναι οι παρακάτω - 1. Πάγωμα, 2. Απόρριψη, 3. Ακύρωση, 4. Έγκριση',
    product: 'Προϊόν',
    package: 'Πακέτο',
    withNoDebtBalance: 'Παίκτες με 0 Υπόλοιπο οφειλής',
    deleteSystemReasonInfo: 'Δεν μπορεί να γίνει διαγραφή των αιτιών συστήματος.',
    editSystemReasonInfo: 'Δεν μπορεί να γίνει επεξεργασία των αιτιών συστήματος.',
    disableSystemReasonInfo: 'Δεν μπορεί να γίνει απενεργοποίηση των αιτιών συστήματος.',
    submittedBy: 'Υποβλήθηκε από',
    statusDetails: 'Στοιχεία κατάστασης',
    fileType: 'Τύπος αρχείου',
    fileSize: 'Μέγεθος αρχείου',
    tagsPlayers: 'Παίκτες ετικέτας',
    actionTypes: 'Τύποι ενεργειών',
  },
};
