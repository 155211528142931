import SelfAssessmentActionType from 'actions/selfAssessment/selfAssessmentActionType';

const initialState = {
  selfAssessments: {
    data: [],
    total: 0,
  },
};

const selfAssessmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SelfAssessmentActionType.SAVE_SELF_ASSESSMENTS:
    return {
      ...state,
      selfAssessments: payload.data,
    };
  case SelfAssessmentActionType.RESET_SELF_ASSESSMENTS:
    return {
      ...state,
      selfAssessments: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default selfAssessmentReducer;
