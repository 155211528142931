import { createSelector } from 'reselect';
import _ from 'lodash';
import enumTypes from 'constants/enumTypes';

export const selectResourcePaymentMethods = createSelector(
  (state) => state.paymentMethods,
  (paymentMethods) => _.sortBy(paymentMethods, 'name'),
);

export const selectResourceCountries = (state) => state.countries;
export const selectResourceSegments = (state) => state.segments;
export const selectResourceTags = (state, key = 'first') => state.tags[key];
export const selectResourceTagsLoading = (state) => state.tagsLoading;
export const selectResourceEnableVerticalTags = createSelector(selectResourceTags, (tags) => tags?.filter((tag) => tag.enable && tag?.category?.enable));
export const selectResourceEnableTags = createSelector(selectResourceTags, (tags) => tags?.filter((tag) => !tag.isVertical || (tag.enable && tag?.category?.enable)));
export const selectResourceGameCategories = (state) => state.gameCategories;
export const selectResourceUsers = (state) => state.users;
export const selectPaymentMethodCurrencies = (state) => state.paymentMethodCurrencies;
export const selectResourceCurrencies = (state) => state.currencies;
export const selectResourceProviders = createSelector(
  (state, key = 'first') => state.providers[key],
  (providers) => _.sortBy(providers, 'name'),
);

export const selectResourceProviderCategories = (state) => state.providerCategories;
export const selectResourceGames = (state) => state.games;
export const selectResourceReasons = (state) => state.reasons;
export const selectResourceEnableReasons = createSelector(selectResourceReasons, (reasons) => reasons.filter(({ isActive }) => isActive));
export const selectResourceCampaigns = (state) => state.campaigns;
export const selectResourceTagCategories = (state, key = 'first') => state.tagCategories[key];
export const selectResourceEnableTagCategories = createSelector(
  (state, key = 'first') => selectResourceTagCategories(state, key),
  (categories) => categories?.filter(({ enable, type }) => type === enumTypes.tagCategoryType.Horizontal || enable),
);

export const selectResourceTagCategory = (state) => state.tagCategory;
export const selectResourceEnableVerticalTagCategories = createSelector(
  (state, key = 'first') => selectResourceTagCategories(state, key),
  (categories) => categories?.filter(({ enable }) => enable),
);
export const selectResourceTagNames = (state) => state.tagNames;
export const selectResourcePlayerTags = (state) => state.playerTags;
export const selectResourceVerticalCategoryReasons = (state, key = 'first') => state.verticalCategoryReasons[key];
export const selectResourceBrand = (state) => state.brand;
export const selectResourceBrandTimeZone = (state) => selectResourceBrand(state)?.timeZone;
export const selectResourcePartner = (state) => state.partner;
export const selectResourceBetshops = (state) => state.betshops;
export const selectResourceNegativeBalanceExists = (state) => state.negativeBalanceExists;
export const selectResourcePartnerTFAState = (state) => state.partnerTFAState;

export const selectResourceGamesSegments = (state) => state.gamesSegments;

export const selectResourceCrossPlatformCampaign = (state) => state.crossPlatformCampaign;
export const selectResourceCrossPlatformCampaignBonuses = (state) => state.crossPlatformCampaignBonuses;

export const selectResourceGamesLoading = (state) => state.gamesLoading;
export const selectResourcePromotions = (state, key = 'first') => state.promotions[key];
export const selectResourcePromotionMatches = (state, key = 'first') => state.promotionMatches[key];
export const selectResourceBrandPeriodsLimits = (state) => state.brandPeriodsLimits;
export const selectResourceGameGroupsNames = (state) => state.gameGroupsNames;
export const selectResourceCurrenciesPrecisions = (state) => state.currenciesPrecisions;
export const selectResourceBrandTaxDda = (state) => state.brandTaxDda;
export const selectResourceHasFixedWithdrawalAmount = (state) => state.hasFixedWithdrawalAmount;
export const selectResourceProvidersAggregators = (state) => state.providersAggregators;
export const selectResourceGameCategoriesLoading = (state) => state.gameCategoriesLoading;
export const selectResourceGridMachineDimensionTypes = (state) => state.gridMachineDimensionTypes;

export const selectResourceSportsBookLayouts = (state) => state.sportsBookLayouts;
export const selectResourceCustomPages = (state) => state.customPages;
export const selectResourceCashierType = (state) => state.cashierType;
export const selectResourceReleaseNotes = (state) => state.releaseNotes;
export const selectResourceGameTypes = (state) => state.gameTypes;
export const selectResourceDeepLinkPromotions = (state) => state.deepLinkPromotions;

export const selectResourcePermissionGroups = (state, key = 'first') => state.permissionGroups[key];
export const selectResourceGroupedPermissionGroups = (state, key = 'first') => createSelector(
  () => selectResourcePermissionGroups(state, key),
  (items) => {
    let data = {};
    const groups = ['title:myGroups', 'title:defaultGroups'];
    if (items) {
      data = _.groupBy(items, (el) => groups[+el.isDefault]);
    }
    return data;
  },
)();

export const selectResourceKycType = (state) => state.kycType;
export const selectResourceDirectoryRules = (state) => state.directoryRules;
export const selectResourceLobbyGroups = (state) => state.lobbyGroups;
export const selectResourceLobbyProviders = (state) => state.lobbyProviders;
export const selectResourceLobbyGames = (state, key = 'first') => state.lobbyGames[key];
export const selectResourceLobbyCategories = (state) => state.lobbyCategories;

export const selectResourceGroupGames = (state) => state.groupGames;
export const selectResourceGroupGamesLoading = (state) => state.groupGamesLoading;

export const selectResourceCampaignsV2 = (state) => state.campaignsV2;
export const selectResourceCampaignsV2Units = (state) => state.campaignsV2Units;
