import CampaignV2ActionType from './campaignV2ActionType';

export const getCampaignsV2 = (params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGNS_V2,
  payload: { params },
});

export const saveCampaignsV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGNS_V2,
  payload: { data },
});

export const resetCampaignsV2 = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGNS_V2,
});

export const getCampaignV2 = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2,
  payload: { id },
});

export const saveCampaignV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2,
  payload: { data },
});

export const resetCampaignV2 = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2,
});

export const createCampaignV2 = (data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2,
  payload: { data },
});

export const saveCreatedCampaignV2Id = (id) => ({
  type: CampaignV2ActionType.SAVE_CREATED_CAMPAIGN_V2_ID,
  payload: { id },
});

export const changeCampaignV2GeneralConfigEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS,
  payload: { status },
});

export const editCampaignV2 = (id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2,
  payload: { id, data },
});

export const createCampaignV2ParticipationGroup = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, data },
});

export const editCampaignV2ParticipationGroup = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id, data },
});

export const deleteCampaignV2ParticipationGroup = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id, name },
});

export const getCampaignV2ParticipationGroups = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUPS,
  payload: { id },
});

export const saveCampaignV2ParticipationGroups = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS,
  payload: { data },
});

export const resetCampaignV2ParticipationGroups = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS,
});

export const getCampaignV2ParticipationGroup = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { campaignId, id },
});

export const saveCampaignV2ParticipationGroup = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP,
  payload: { data },
});

export const resetCampaignV2ParticipationGroup = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUP,
});

export const changeCampaignV2ParticipationGroupEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS,
  payload: { status },
});

export const createCampaignV2FreeSpinBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2FreeSpinBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2FreeSpinBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2FreeSpinBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { data },
});

export const resetCampaignV2FreeSpinBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_SPIN_BONUS,
});

export const deleteCampaignV2FreeSpinBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, name },
});

export const createCampaignV2FreeBetBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2FreeBetBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2FreeBetBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2FreeBetBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { data },
});

export const deleteCampaignV2FreeBetBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, name },
});

export const resetCampaignV2FreeBetBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUS,
});

export const createCampaignV2CombinedBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2CombinedBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2CombinedBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2CombinedBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { data },
});

export const deleteCampaignV2CombinedBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id, name },
});

export const resetCampaignV2CombinedBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_COMBINED_BONUS,
});

export const changeCampaignV2BonusEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2Bonuses = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_BONUSES,
  payload: { id },
});

export const saveCampaignV2Bonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUSES,
  payload: { data },
});

export const resetCampaignV2Bonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUSES,
});

export const getCampaignV2TriggerConditions = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITIONS,
  payload: { id },
});

export const saveCampaignV2TriggerConditions = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS,
  payload: { data },
});

export const resetCampaignV2TriggerConditions = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS,
});

export const getCampaignV2TriggerCondition = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, id },
});

export const saveCampaignV2TriggerCondition = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { data },
});

export const resetCampaignV2TriggerCondition = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITION,
});

export const createCampaignV2TriggerCondition = (id, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { id, data },
});

export const editCampaignV2TriggerCondition = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, data },
});

export const deleteCampaignV2TriggerCondition = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_TRIGGER_CONDITION,
  payload: { campaignId, data },
});

export const changeCampaignV2TriggerConditionEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS,
  payload: { status },
});

export const createCampaignV2SpecialBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, data },
});

export const editCampaignV2SpecialBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id, data },
});

export const getCampaignV2SpecialBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id },
});

export const saveCampaignV2SpecialBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { data },
});

export const deleteCampaignV2SpecialBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id, name },
});

export const resetCampaignV2SpecialBonus = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_SPECIAL_BONUS,
});

export const getCampaignV2BonusContents = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENTS,
  payload: { id },
});

export const saveCampaignV2BonusContents = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENTS,
  payload: { data },
});

export const resetCampaignV2BonusContents = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENTS,
});

export const createCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const editCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const deleteCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const getCampaignV2BonusContent = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, id },
});

export const saveCampaignV2BonusContent = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { data },
});

export const resetCampaignV2BonusContent = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENT,
});

export const changeCampaignV2BonusContentEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2CampaignContents = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENTS,
  payload: { id },
});

export const saveCampaignV2CampaignContents = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENTS,
  payload: { data },
});

export const resetCampaignV2CampaignContents = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENTS,
});

export const createCampaignV2CampaignContent = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_CAMPAIGN_CONTENT,
  payload: { campaignId, data },
});

export const editCampaignV2CampaignContent = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_CAMPAIGN_CONTENT,
  payload: { campaignId, data },
});

export const deleteCampaignV2CampaignContent = (campaignId, languageId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_CAMPAIGN_CONTENT,
  payload: { campaignId, languageId, data },
});

export const getCampaignV2CampaignContent = (id, languageId) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENT,
  payload: { id, languageId },
});

export const saveCampaignV2CampaignContent = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENT,
  payload: { data },
});

export const resetCampaignV2CampaignContent = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENT,
});

export const changeCampaignV2CampaignContentEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_CAMPAIGN_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2Units = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_UNITS,
  payload: { id },
});

export const saveCampaignV2Units = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNITS,
  payload: { data },
});

export const resetCampaignV2Units = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_UNITS,
});

export const getCampaignV2Unit = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_UNIT,
  payload: { campaignId, id },
});

export const saveCampaignV2Unit = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNIT,
  payload: { data },
});

export const resetCampaignV2Unit = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_UNIT,
});

export const createCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const editCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const deleteCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const changeCampaignV2UnitEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_UNIT_ENDED_STATUS,
  payload: { status },
});

export const publishCampaignV2 = (id, data, name) => ({
  type: CampaignV2ActionType.PUBLISH_CAMPAIGN_V2,
  payload: { id, data, name },
});

export const changeCampaignV2PublishEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PUBLISH_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2OffersContents = (id) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_OFFERS_CONTENTS,
  payload: { id },
});

export const saveCampaignV2OffersContents = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_OFFERS_CONTENTS,
  payload: { data },
});

export const resetCampaignV2OffersContents = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_OFFERS_CONTENTS,
});

export const createCampaignV2OffersContent = (campaignId, data, unitName) => ({
  type: CampaignV2ActionType.CREATE_CAMPAIGN_V2_OFFERS_CONTENT,
  payload: { campaignId, data, unitName },
});

export const editCampaignV2OffersContent = (campaignId, offersContentId, data, unitName) => ({
  type: CampaignV2ActionType.EDIT_CAMPAIGN_V2_OFFERS_CONTENT,
  payload: {
    campaignId,
    offersContentId,
    data,
    unitName,
  },
});

export const deleteCampaignV2OffersContent = (campaignId, offersContentId, data) => ({
  type: CampaignV2ActionType.DELETE_CAMPAIGN_V2_OFFERS_CONTENT,
  payload: { campaignId, offersContentId, data },
});

export const getCampaignV2OffersContent = (id, languageId) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_OFFERS_CONTENT,
  payload: { id, languageId },
});

export const saveCampaignV2OffersContent = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_OFFERS_CONTENT,
  payload: { data },
});

export const resetCampaignV2OffersContent = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_OFFERS_CONTENT,
});

export const changeCampaignV2OffersContentEndedStatus = (status) => ({
  type: CampaignV2ActionType.CHANGE_CAMPAIGN_V2_OFFERS_CONTENT_ENDED_STATUS,
  payload: { status },
});

export const getCampaignV2NoTriggerStatistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const saveCampaignV2Statistics = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_STATISTICS,
  payload: { data },
});

export const resetCampaignV2Statistics = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_STATISTICS,
});

export const exportCampaignV2NoTriggerStatistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CAMPAIGN_V2_NO_TRIGGER_STATISTICS,
  payload: { id, params },
});

export const getCampaignV2SignInStatistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_SIGN_IN_STATISTICS,
  payload: { id, params },
});

export const exportCampaignV2SignInStatistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CAMPAIGN_V2_SIGN_IN_STATISTICS,
  payload: { id, params },
});

export const getCampaignV2SignUpStatistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_SIGN_UP_STATISTICS,
  payload: { id, params },
});

export const exportCampaignV2SignUpStatistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CAMPAIGN_V2_SIGN_UP_STATISTICS,
  payload: { id, params },
});

export const getCampaignV2VerificationStatistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_VERIFICATION_STATISTICS,
  payload: { id, params },
});

export const exportCampaignV2VerificationStatistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CAMPAIGN_V2_VERIFICATION_STATISTICS,
  payload: { id, params },
});

export const getCampaignV2DepositStatistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_DEPOSIT_STATISTICS,
  payload: { id, params },
});

export const exportCampaignV2DepositStatistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CAMPAIGN_V2_DEPOSIT_STATISTICS,
  payload: { id, params },
});

export const getCampaignsV2Units = (params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGNS_V2_UNITS,
  payload: { params },
});

export const saveCampaignsV2Units = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGNS_V2_UNITS,
  payload: { data },
});

export const resetCampaignsV2Units = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGNS_V2_UNITS,
});

export const getCampaignV2UnitPlayers = (id, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_UNIT_PLAYERS,
  payload: { id, params },
});

export const saveCampaignV2UnitPlayers = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNIT_PLAYERS,
  payload: { data },
});

export const resetCampaignV2UnitPlayers = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_UNIT_PLAYERS,
});

export const getCampaignV2FreeBetBonuses = (brandId, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUSES,
  payload: { brandId, params },
});

export const saveCampaignV2FreeBetBonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUSES,
  payload: { data },
});

export const resetCampaignV2FreeBetBonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUSES,
});

export const getCampaignV2SpinBonuses = (brandId, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_SPIN_BONUSES,
  payload: { brandId, params },
});

export const saveCampaignV2SpinBonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPIN_BONUSES,
  payload: { data },
});

export const resetCampaignV2SpinBonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_SPIN_BONUSES,
});

export const getCampaignV2WagerBonuses = (brandId, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_WAGER_BONUSES,
  payload: { brandId, params },
});

export const saveCampaignV2WagerBonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_WAGER_BONUSES,
  payload: { data },
});

export const resetCampaignV2WagerBonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_WAGER_BONUSES,
});

export const getCampaignV2SpecialBonuses = (brandId, params) => ({
  type: CampaignV2ActionType.GET_CAMPAIGN_V2_SPECIAL_BONUSES,
  payload: { brandId, params },
});

export const saveCampaignV2SpecialBonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPECIAL_BONUSES,
  payload: { data },
});

export const resetCampaignV2SpecialBonuses = () => ({
  type: CampaignV2ActionType.RESET_CAMPAIGN_V2_SPECIAL_BONUSES,
});

export const getCrossPlatformCampaignsV2 = (params) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGNS_V2,
  payload: { params },
});

export const saveCrossPlatformCampaignsV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGNS_V2,
  payload: { data },
});

export const resetCrossPlatformCampaignsV2 = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGNS_V2,
});

export const getCrossPlatformCampaignV2 = (id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { id },
});

export const saveCrossPlatformCampaignV2 = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { data },
});

export const resetCrossPlatformCampaignV2 = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2,
});

export const createCrossPlatformCampaignV2 = (data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { data },
});

export const editCrossPlatformCampaignV2 = (id, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { id, data },
});

export const createCrossPlatformCampaignV2FreeSpinBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2FreeSpinBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, data },
});

export const getCrossPlatformCampaignV2FreeSpinBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2FreeSpinBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { data },
});

export const resetCrossPlatformCampaignV2FreeSpinBonus = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
});

export const deleteCrossPlatformCampaignV2FreeSpinBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS,
  payload: { campaignId, id, name },
});

export const createCrossPlatformCampaignV2FreeBetBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2FreeBetBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, data },
});

export const getCrossPlatformCampaignV2FreeBetBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2FreeBetBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { data },
});

export const deleteCrossPlatformCampaignV2FreeBetBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
  payload: { campaignId, id, name },
});

export const resetCrossPlatformCampaignV2FreeBetBonus = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS,
});

export const createCrossPlatformCampaignV2SpecialBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2SpecialBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id, data },
});

export const getCrossPlatformCampaignV2SpecialBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2SpecialBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { data },
});

export const deleteCrossPlatformCampaignV2SpecialBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
  payload: { campaignId, id, name },
});

export const resetCrossPlatformCampaignV2SpecialBonus = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS,
});

export const createCrossPlatformCampaignV2CombinedBonus = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2CombinedBonus = (campaignId, id, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id, data },
});

export const getCrossPlatformCampaignV2CombinedBonus = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2CombinedBonus = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { data },
});

export const deleteCrossPlatformCampaignV2CombinedBonus = (campaignId, id, name) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
  payload: { campaignId, id, name },
});

export const resetCrossPlatformCampaignV2CombinedBonus = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS,
});

export const getCrossPlatformCampaignV2Bonuses = (id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES,
  payload: { id },
});

export const resetCrossPlatformCampaignV2Bonuses = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES,
});

export const saveCrossPlatformCampaignV2Bonuses = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES,
  payload: { data },
});

export const getCrossPlatformCampaignV2BonusContents = (id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS,
  payload: { id },
});

export const saveCrossPlatformCampaignV2BonusContents = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS,
  payload: { data },
});

export const resetCrossPlatformCampaignV2BonusContents = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS,
});

export const createCrossPlatformCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const deleteCrossPlatformCampaignV2BonusContent = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, data },
});

export const getCrossPlatformCampaignV2BonusContent = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2BonusContent = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
  payload: { data },
});

export const resetCrossPlatformCampaignV2BonusContent = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT,
});

export const getCrossPlatformCampaignV2Units = (id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS,
  payload: { id },
});

export const saveCrossPlatformCampaignV2Units = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNITS,
  payload: { data },
});

export const resetCrossPlatformCampaignV2Units = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS,
});

export const getCrossPlatformCampaignV2Unit = (campaignId, id) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
  payload: { campaignId, id },
});

export const saveCrossPlatformCampaignV2Unit = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
  payload: { data },
});

export const resetCrossPlatformCampaignV2Unit = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
});

export const createCrossPlatformCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const editCrossPlatformCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const deleteCrossPlatformCampaignV2Unit = (campaignId, data) => ({
  type: CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT,
  payload: { campaignId, data },
});

export const publishCrossPlatformCampaignV2 = (id, name) => ({
  type: CampaignV2ActionType.PUBLISH_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { id, name },
});

export const finishCrossPlatformCampaignV2 = (id, name) => ({
  type: CampaignV2ActionType.FINISH_CROSS_PLATFORM_CAMPAIGN_V2,
  payload: { id, name },
});

export const getCrossPlatformCampaignV2Statistics = (id, params) => ({
  type: CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS,
  payload: { id, params },
});

export const saveCrossPlatformCampaignV2Statistics = (data) => ({
  type: CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS,
  payload: { data },
});

export const resetCrossPlatformCampaignV2Statistics = () => ({
  type: CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS,
});

export const exportCrossPlatformCampaignV2Statistics = (id, params) => ({
  type: CampaignV2ActionType.EXPORT_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS,
  payload: { id, params },
});
