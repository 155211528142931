import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import BonusActionType from 'actions/bonus/bonusActionType';
import { AppConstants } from 'constants/index';
import {
  saveCasinoBonus,
  saveSportBonus,
  saveSpecialBonus,
  saveCasinoBonuses,
  saveSportBonuses,
  saveSpecialBonuses,
  savePlayerCasinoBonuses,
  savePlayerSportBonuses,
  savePlayerSpecialBonuses,
  saveCasinoBonusStatistics,
  saveSportBonusStatistics,
  saveSpecialBonusStatistics,
  saveBonusContent,
  saveSpinBonuses,
  saveSpinBonus,
  saveSpinBonusStatistics,
  savePlayerSpinBonuses,
  savePlayerFreeBetBonusesV2,
  savePlayerCombinedBonusesV2,
  savePlayerSpecialBonusesV2,
  savePlayerSpinBonusesV2,
} from 'actions/bonus/bonusActionCreator';
import {
  setErrorMessage, changeLoadingState, changeEndedStatus, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';
import mime from 'mimetypes';
import { downloadFile } from 'utils/utils';

function* getCasinoBonus({ payload }) {
  try {
    const { BonusAdmin, Casino } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Casino, payload.id]);
    yield put(saveCasinoBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinBonus({ payload }) {
  try {
    const { BonusAdmin, FreeSpin } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, payload.id]);
    yield put(saveSpinBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBonus({ payload }) {
  try {
    const { BonusAdmin, Sport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Sport, payload.id]);
    yield put(saveSportBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonus({ payload }) {
  const { isLoading } = payload;
  try {
    const { BonusAdmin, Special } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Special, payload.id]);
    yield put(saveSpecialBonus(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCasinoBonuses({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Casino, Bonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Casino, brandId, Bonuses], options);
    yield put(saveCasinoBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinBonuses({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, FreeSpin, Bonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, brandId, Bonuses], options);
    yield put(saveSpinBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBonuses({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Sport, Bonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Sport, brandId, Bonuses], options);
    yield put(saveSportBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonuses({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Special, Bonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Special, brandId, Bonuses], options);
    yield put(saveSpecialBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCasinoBonuses({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Player, CasinoBonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, playerId, CasinoBonuses], options);
    yield put(savePlayerCasinoBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSpinBonuses({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Player, FreeSpinBonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, playerId, FreeSpinBonuses], options);
    yield put(savePlayerSpinBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSportBonuses({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Player, SportBonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, playerId, SportBonuses], options);
    yield put(savePlayerSportBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSpecialBonuses({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Player, SpecialBonuses } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, playerId, SpecialBonuses], options);
    yield put(savePlayerSpecialBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCasinoBonusStatistics({ payload }) {
  try {
    const { bonusId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Casino, Statistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Casino, bonusId, Statistics], options);
    yield put(saveCasinoBonusStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinBonusStatistics({ payload }) {
  try {
    const { bonusId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, FreeSpin, Statistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, bonusId, Statistics], options);
    yield put(saveSpinBonusStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBonusStatistics({ payload }) {
  try {
    const { bonusId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Sport, Statistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Sport, bonusId, Statistics], options);
    yield put(saveSportBonusStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonusStatistics({ payload }) {
  try {
    const { bonusId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Special, Statistics } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Special, bonusId, Statistics], options);
    yield put(saveSpecialBonusStatistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportCasinoBonusStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Casino, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Casino, id, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:bonusStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSpinBonusStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, FreeSpin, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, id, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:bonusStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSportBonusStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Sport, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Sport, id, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:bonusStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSpecialBonusStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Special, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Special, id, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:bonusStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelBonus({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { BonusAdmin, Bonus, Cancel } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Bonus, Cancel], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slCancelled'))));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelPlayerBonus({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { BonusAdmin, Player, Cancel } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Player, Cancel], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slCancelled'))));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusContent({ payload }) {
  try {
    const { bonusId } = payload;
    const { BonusAdmin, Bonus, ContentConfig } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Bonus, bonusId, ContentConfig]);
    yield put(saveBonusContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerFreeBetBonusesV2({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Player, V2, Bonuses, FreeBet,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, V2, playerId, Bonuses, FreeBet], options);
    yield put(savePlayerFreeBetBonusesV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSpinBonusesV2({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Player, V2, Bonuses, Spin,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, V2, playerId, Bonuses, Spin], options);
    yield put(savePlayerSpinBonusesV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCombinedBonusesV2({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Player, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, V2, playerId, Bonuses, Combined], options);
    yield put(savePlayerCombinedBonusesV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSpecialBonusesV2({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Player, V2, Bonuses, Special,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Player, V2, playerId, Bonuses, Special], options);
    yield put(savePlayerSpecialBonusesV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cancelPlayerBonusV2({ payload }) {
  try {
    const { playerBonusId } = payload;
    const {
      BonusAdmin, Player, V2, Cancel,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Player, V2, playerBonusId, Cancel]);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slCancelled'))));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* bonusSaga() {
  yield all([
    takeLatest(BonusActionType.GET_CASINO_BONUS, getCasinoBonus),
    takeLatest(BonusActionType.GET_SPIN_BONUS, getSpinBonus),
    takeLatest(BonusActionType.GET_SPORT_BONUS, getSportBonus),
    takeLatest(BonusActionType.GET_SPECIAL_BONUS, getSpecialBonus),

    takeLatest(BonusActionType.GET_CASINO_BONUSES, getCasinoBonuses),
    takeLatest(BonusActionType.GET_SPIN_BONUSES, getSpinBonuses),
    takeLatest(BonusActionType.GET_SPORT_BONUSES, getSportBonuses),
    takeLatest(BonusActionType.GET_SPECIAL_BONUSES, getSpecialBonuses),

    takeLatest(BonusActionType.GET_PLAYER_CASINO_BONUSES, getPlayerCasinoBonuses),
    takeLatest(BonusActionType.GET_PLAYER_SPIN_BONUSES, getPlayerSpinBonuses),
    takeLatest(BonusActionType.GET_PLAYER_SPORT_BONUSES, getPlayerSportBonuses),
    takeLatest(BonusActionType.GET_PLAYER_SPECIAL_BONUSES, getPlayerSpecialBonuses),

    takeLatest(BonusActionType.GET_CASINO_BONUS_STATISTICS, getCasinoBonusStatistics),
    takeLatest(BonusActionType.GET_SPIN_BONUS_STATISTICS, getSpinBonusStatistics),
    takeLatest(BonusActionType.GET_SPORT_BONUS_STATISTICS, getSportBonusStatistics),
    takeLatest(BonusActionType.GET_SPECIAL_BONUS_STATISTICS, getSpecialBonusStatistics),

    takeLatest(BonusActionType.EXPORT_CASINO_BONUS_STATISTICS, exportCasinoBonusStatistics),
    takeLatest(BonusActionType.EXPORT_SPIN_BONUS_STATISTICS, exportSpinBonusStatistics),
    takeLatest(BonusActionType.EXPORT_SPORT_BONUS_STATISTICS, exportSportBonusStatistics),
    takeLatest(BonusActionType.EXPORT_SPECIAL_BONUS_STATISTICS, exportSpecialBonusStatistics),

    takeLatest(BonusActionType.CANCEL_BONUS, cancelBonus),
    takeLatest(BonusActionType.CANCEL_PLAYER_BONUS, cancelPlayerBonus),

    takeLatest(BonusActionType.GET_BONUS_CONTENT, getBonusContent),

    takeLatest(BonusActionType.GET_PLAYER_FREE_BET_BONUSES_V2, getPlayerFreeBetBonusesV2),
    takeLatest(BonusActionType.GET_PLAYER_SPIN_BONUSES_V2, getPlayerSpinBonusesV2),
    takeLatest(BonusActionType.GET_PLAYER_COMBINED_BONUSES_V2, getPlayerCombinedBonusesV2),
    takeLatest(BonusActionType.GET_PLAYER_SPECIAL_BONUSES_V2, getPlayerSpecialBonusesV2),

    takeLatest(BonusActionType.CANCEL_PLAYER_BONUS_V2, cancelPlayerBonusV2),
  ]);
}
