import { createSelector } from 'reselect';

export const selectPlayers = (state) => state.players;
export const selectPlayer = (state) => state.player;
export const selectPlayerUserName = (state) => selectPlayer(state).userName;

export const selectPlayerData = (state) => state.playerData;

export const selectPlayerCurrencyId = (state) => selectPlayerData(state).currencyId;
export const selectPlayerAccountBalance = (state) => state.accountBalance;
export const selectCsvPlayers = (state) => state.csvPlayers;
export const selectBrandPlayers = (state) => state.brandPlayers;
export const selectPlayerLoading = (state) => state.loading;
export const selectPlayerReportLoading = (state) => state.reportLoading;
export const selectPlayerTotal = (state) => state.total;
const selectPlayerNote = (state) => state.note;
export const selectPlayerNoteData = (state) => selectPlayerNote(state).data;
export const selectPlayerNoteTotal = (state) => selectPlayerNote(state).total;
export const selectPlayerNoteTypes = (state) => selectPlayerNote(state).types;
export const selectPlayerModalVisible = (state) => state.modalVisible;
export const selectPlayerModalLoadingState = (state) => state.modalLoading;
export const selectPlayerMessages = (state) => state.messages;
export const selectPlayerMessage = (state) => state.message;
export const selectPlayerMessagesTotal = (state) => state.messagesTotal;
export const selectPlayerEndedStatus = (state) => state.endedStatus;
export const selectPlayerControls = (state) => state.controls;
const selectPlayerRGSettings = (state) => state.responsibleGamingSettings;
export const selectPlayerSelfExclusionSettings = (state) => selectPlayerRGSettings(state).selfExclusion;
export const selectPlayerTimeoutSettings = (state) => selectPlayerRGSettings(state).timeout;
export const selectPlayerDepositSettings = (state) => selectPlayerRGSettings(state).deposit;
export const selectPlayerWithdrawalSettings = (state) => selectPlayerRGSettings(state).withdrawal;
export const selectPlayerBetSettings = (state) => selectPlayerRGSettings(state).bet;
export const selectPlayerRealityCheckSettings = (state) => selectPlayerRGSettings(state).realityCheck;
const selectPlayerRGLogs = (state) => selectPlayerRGSettings(state).logs;
export const selectPlayerAccessLimitSettings = (state) => selectPlayerRGSettings(state).accessLimit;
export const selectPlayerAccessLimitPendingSettings = (state) => selectPlayerRGSettings(state).accessLimitPending;
export const selectPlayerAccessLimitOverviewSettings = (state) => selectPlayerRGSettings(state).accessLimitOverview;
export const selectPlayerAccessLimitSettingsLog = (state) => selectPlayerRGSettings(state).accessLimitLog;
export const selectPlayerRGLogsData = (state) => selectPlayerRGLogs(state).data;
export const selectPlayerRGLogsTotal = (state) => selectPlayerRGLogs(state).total;
export const selectPlayersStatusUpdateHistory = (state) => state.playersStatusUpdateHistory.data;
export const selectPlayersStatusUpdateHistoryTotal = (state) => state.playersStatusUpdateHistory.total;
export const selectStatusUpdatedPlayers = (state) => state.bulkStatusUpdatedPlayers.data;
export const selectStatusUpdatedPlayersTotal = (state) => state.bulkStatusUpdatedPlayers.total;
const selectPlayerActions = (state) => state.actions;
export const selectPlayerActionsData = (state) => selectPlayerActions(state).data;
export const selectPlayerActionsTotal = (state) => selectPlayerActions(state).total;
const selectTestPlayers = (state) => state.testPlayers;
export const selectTestPlayersData = (state) => selectTestPlayers(state).data;
export const selectTestPlayersTotal = (state) => selectTestPlayers(state).total;
const selectTestPlayerUsers = (state) => state.testPlayerUsers;
export const selectTestPlayerUsersData = (state) => selectTestPlayerUsers(state).data;
export const selectTestPlayerUsersTotal = (state) => selectTestPlayerUsers(state).total;
export const selectPlayerDataChanged = (state) => state.isPlayerDataChanged;
export const selectPlayerBrandId = (state) => selectPlayerData(state).brandId;
const selectPlayerReminderLimits = (state) => selectPlayerRGSettings(state).reminderLimits;
export const selectPlayerBetLimit = (state) => selectPlayerReminderLimits(state).betLimit;
export const selectPlayerDepositLimit = (state) => selectPlayerReminderLimits(state).depositLimit;
export const selectPlayerWithdrawalLimit = (state) => selectPlayerReminderLimits(state).withdrawalLimit;
export const selectPlayerLossLimit = (state) => selectPlayerReminderLimits(state).lossLimit;
export const selectPlayerGameTimeLimit = (state) => selectPlayerReminderLimits(state).timeLimit;
export const selectPlayerCurrency = (state) => selectPlayerData(state).currencyId;
const selectPlayerSystemNotifications = (state) => state.systemNotifications;
export const selectPlayerSystemNotificationsData = (state) => selectPlayerSystemNotifications(state).data;
export const selectPlayerSystemNotificationsTotal = (state) => selectPlayerSystemNotifications(state).total;
export const selectPlayerSystemNotification = (state) => state.systemNotification;
export const selectPlayerPersonalInfo = (state) => state.personalInfo;
export const selectPlayerAccountInfo = (state) => state.accountInfo;
export const selectPlayerIsDeleted = createSelector(selectPlayerData, (data) => data.isDeleted);
export const selectPlayerBlockedPaymentMethods = (state) => selectPlayerAccountInfo(state).blockedPaymentMethods;
export const selectPlayerAccountInfoChanged = (state) => state.isPlayerAccountInfoChanged;
export const selectPlayerPersonalInfoChanged = (state) => state.isPlayerPersonalInfoChanged;
export const selectPlayerActionEndedStatus = (state) => state.playerActionEndedStatus;
export const selectPlayerFullName = createSelector(selectPlayer, (data) => `${data?.firstName || '-'} ${data?.lastName || '-'}`);

export const selectPlayerLimitsLoading = (state) => state.playerLimitsLoading;

export const selectPlayerLimitLoading = (state, id) => createSelector(
  () => selectPlayerLimitsLoading(state),
  (data) => !!data[id],
)();

export const selectPlayerLossLimitSettings = (state) => selectPlayerRGSettings(state).lossLimit;
export const selectPlayerSingleBetSettings = (state) => selectPlayerRGSettings(state).singleBet;
export const selectPlayerGameTimeLimitSettings = (state) => selectPlayerRGSettings(state).gameTimeLimit;
export const selectHasPlayerTransaction = (state) => state.hasTransaction;
export const selectHasPlayerTransactionLoading = (state) => state.hasTransactionLoading;
