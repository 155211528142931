import PlayerActionType from 'actions/player/playerActionType';

const initialState = {
  loading: false,
  modalLoading: false,
  reportLoading: false,
  endedStatus: false,
  playerData: {},
  player: {},
  accountBalance: null,
  players: [],
  csvPlayers: [],
  brandPlayers: [],
  total: 0,
  modalVisible: false,
  note: {
    data: [],
    total: 0,
    types: [],
  },
  messages: {
    sent: [],
    inbox: [],
  },
  messagesTotal: {
    sent: 0,
    inbox: 0,
  },
  message: [],
  controls: {},
  responsibleGamingSettings: {
    selfExclusion: {},
    timeout: {},
    deposit: {},
    withdrawal: {},
    bet: {},
    logs: {},
    reminderLimits: {},
    realityCheck: {},
    accessLimit: {},
    accessLimitPending: {},
    accessLimitOverview: {},
    accessLimitLog: {},
    lossLimit: {},
    singleBet: {},
    gameTimeLimit: {},
  },
  playersStatusUpdateHistory: {},
  bulkStatusUpdatedPlayers: {},
  actions: {},
  testPlayers: {},
  testPlayerUsers: {},
  // it's only for player data changes
  isPlayerDataChanged: false,
  systemNotifications: {},
  systemNotification: [],
  personalInfo: {},
  accountInfo: {},
  isPlayerPersonalInfoChanged: false,
  isPlayerAccountInfoChanged: false,
  playerActionEndedStatus: false,
  playerLimitsLoading: {},
  hasTransaction: null,
  hasTransactionLoading: false,
};

const playerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerActionType.SAVE_PLAYER_DATA:
    return {
      ...state,
      playerData: payload.data.player,
      accountBalance: payload.data.accountBalance,
    };
  case PlayerActionType.RESET_PLAYER_DATA:
    return {
      ...state,
      playerData: {},
      accountBalance: null,
    };
  case PlayerActionType.CHANGE_PLAYER_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case PlayerActionType.CHANGE_PLAYER_MODAL_LOADING_STATE:
    return {
      ...state,
      modalLoading: payload.state,
    };
  case PlayerActionType.CHANGE_PLAYER_ENDED_STATUS:
    return {
      ...state,
      endedStatus: payload.status,
    };
  case PlayerActionType.GET_PLAYERS_SUCCESS:
    return {
      ...state,
      players: payload.data,
      total: payload.total,
    };
  case PlayerActionType.RESET_PLAYERS:
    return {
      ...state,
      players: [],
      total: 0,
    };
  case PlayerActionType.SAVE_PLAYER:
    return {
      ...state,
      player: payload.data,
    };
  case PlayerActionType.RESET_PLAYER:
    return {
      ...state,
      player: {},
    };
  case PlayerActionType.GET_CSV_PLAYERS_SUCCESS:
    return {
      ...state,
      csvPlayers: payload.data,
    };
  case PlayerActionType.RESET_CSV_PLAYERS:
    return {
      ...state,
      csvPlayers: [],
    };
  case PlayerActionType.SAVE_BRAND_PLAYERS_RESOURCE:
    return {
      ...state,
      brandPlayers: payload.data,
    };
  case PlayerActionType.RESET_BRAND_PLAYERS_RESOURCE:
    return {
      ...state,
      brandPlayers: [],
      total: 0,
    };
  case PlayerActionType.GET_PLAYER_NOTES_SUCCESS:
    return {
      ...state,
      note: {
        ...state.note,
        data: payload.data,
        total: payload.total,
      },
    };
  case PlayerActionType.RESET_PLAYER_NOTES:
    return {
      ...state,
      note: {
        ...state.note,
        data: [],
        total: 0,
      },
    };
  case PlayerActionType.GET_PLAYER_NOTE_TYPES_SUCCESS:
    return {
      ...state,
      note: {
        ...state.note,
        types: payload.data,
      },
    };
  case PlayerActionType.RESET_PLAYER_NOTE_TYPES:
    return {
      ...state,
      note: {
        ...state.note,
        types: [],
      },
    };
  case PlayerActionType.CHANGE_PLAYER_MODAL_VISIBILITY:
    return {
      ...state,
      modalVisible: payload.state,
    };
  case PlayerActionType.SAVE_PLAYER_MESSAGES:
    return {
      ...state,
      messages: payload.data,
    };
  case PlayerActionType.RESET_PLAYER_INBOX_MESSAGES:
    return {
      ...state,
      messages: {
        ...state.messages,
        inbox: [],
      },
      messagesTotal: {
        ...state.messagesTotal,
        inbox: 0,
      },
    };
  case PlayerActionType.RESET_PLAYER_MASS_MESSAGES:
    return {
      ...state,
      messages: {
        ...state.messages,
        sent: [],
      },
      messagesTotal: {
        ...state.messagesTotal,
        sent: 0,
      },
    };
  case PlayerActionType.SAVE_PLAYER_MESSAGE:
    return {
      ...state,
      message: payload.data,
    };
  case PlayerActionType.RESET_PLAYER_MESSAGE:
    return {
      ...state,
      message: [],
    };
  case PlayerActionType.SAVE_PLAYER_MESSAGES_TOTAL:
    return {
      ...state,
      messagesTotal: payload.data,
    };
  case PlayerActionType.SAVE_PLAYER_CONTROLS:
    return {
      ...state,
      controls: payload.data,
    };
  case PlayerActionType.GET_PLAYERS_BULK_STATUS_UPDATE_HISTORY_SUCCESS:
    return {
      ...state,
      playersStatusUpdateHistory: payload.data,
    };
  case PlayerActionType.RESET_PLAYERS_BULK_STATUS_UPDATE_HISTORY:
    return {
      ...state,
      playersStatusUpdateHistory: {},
    };
  case PlayerActionType.GET_BULK_STATUS_UPDATED_PLAYERS_SUCCESS:
    return {
      ...state,
      bulkStatusUpdatedPlayers: payload.data,
    };
  case PlayerActionType.RESET_BULK_STATUS_UPDATED_PLAYERS:
    return {
      ...state,
      bulkStatusUpdatedPlayers: {},
    };
  case PlayerActionType.CHANGE_PLAYER_REPORT_LOADING_STATE:
    return {
      ...state,
      reportLoading: payload.state,
    };
  case PlayerActionType.SAVE_PLAYER_ACTIONS:
    return {
      ...state,
      actions: payload.data,
    };
  case PlayerActionType.RESET_PLAYER_ACTIONS:
    return {
      ...state,
      actions: {},
    };
  case PlayerActionType.SAVE_TEST_PLAYERS:
    return {
      ...state,
      testPlayers: payload.data,
    };
  case PlayerActionType.RESET_TEST_PLAYERS:
    return {
      ...state,
      testPlayers: {},
    };
  case PlayerActionType.SAVE_TEST_PLAYER_USERS:
    return {
      ...state,
      testPlayerUsers: payload.data,
    };
  case PlayerActionType.RESET_TEST_PLAYER_USERS:
    return {
      ...state,
      testPlayerUsers: {},
    };
  case PlayerActionType.CHANGE_PLAYER_DATA_UPDATED_STATUS:
    return {
      ...state,
      isPlayerDataChanged: payload.status,
    };
  case PlayerActionType.SAVE_PLAYER_SELF_EXCLUSION_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        selfExclusion: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_TIMOUT_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        timeout: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_DEPOSIT_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        deposit: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_WITHDRAWAL_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        withdrawal: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_BET_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        bet: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_REALITY_CHECK_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        realityCheck: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_LOSS_LIMIT_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        lossLimit: payload.data,
      },
    };
  case PlayerActionType.RESET_PLAYER_RESPONSIBLE_GAMING_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        selfExclusion: {},
        timeout: {},
        deposit: {},
        withdrawal: {},
        bet: {},
        logs: {},
        reminderLimits: {},
        realityCheck: {},
        accessLimit: {},
        accessLimitPending: {},
        accessLimitOverview: {},
        accessLimitLog: {},
        lossLimit: {},
        singleBet: {},
      },
    };
  case PlayerActionType.SAVE_RESPONSIBLE_GAMING_LOGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        logs: payload.data,
      },
    };
  case PlayerActionType.RESET_RESPONSIBLE_GAMING_LOGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        logs: {},
      },
    };
  case PlayerActionType.SAVE_RESPONSIBLE_GAMING_REMINDER_LIMITS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        reminderLimits: payload.data,
      },
    };
  case PlayerActionType.RESET_RESPONSIBLE_GAMING_REMINDER_LIMITS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        reminderLimits: {},
      },
    };
  case PlayerActionType.SAVE_PLAYER_SYSTEM_NOTIFICATIONS: {
    return {
      ...state,
      systemNotifications: payload.data,
    };
  }
  case PlayerActionType.RESET_PLAYER_SYSTEM_NOTIFICATIONS: {
    return {
      ...state,
      systemNotifications: {},
    };
  }
  case PlayerActionType.SAVE_PLAYER_SYSTEM_NOTIFICATION: {
    return {
      ...state,
      systemNotification: payload.data,
    };
  }
  case PlayerActionType.RESET_PLAYER_SYSTEM_NOTIFICATION: {
    return {
      ...state,
      systemNotification: [],
    };
  }
  case PlayerActionType.SAVE_PLAYER_PERSONAL_INFO:
    return {
      ...state,
      personalInfo: payload.data,
    };
  case PlayerActionType.RESET_PLAYER_PERSONAL_INFO:
    return {
      ...state,
      personalInfo: {},
    };
  case PlayerActionType.SAVE_PLAYER_ACCOUNT_INFO:
    return {
      ...state,
      accountInfo: payload.data,
    };
  case PlayerActionType.RESET_PLAYER_ACCOUNT_INFO:
    return {
      ...state,
      accountInfo: {},
    };
  case PlayerActionType.CHANGE_PLAYER_ACCOUNT_INFO_UPDATED_STATUS:
    return {
      ...state,
      isPlayerAccountInfoChanged: payload.status,
    };
  case PlayerActionType.CHANGE_PLAYER_PERSONAL_INFO_UPDATED_STATUS:
    return {
      ...state,
      isPlayerPersonalInfoChanged: payload.status,
    };
  case PlayerActionType.CHANGE_PLAYER_ACTIONS_ENDED_STATUS:
    return {
      ...state,
      playerActionEndedStatus: payload.status,
    };
  case PlayerActionType.CHANGE_PLAYER_LIMITS_LOADING:
    return {
      ...state,
      playerLimitsLoading: {
        ...state.playerLimitsLoading,
        [payload.id]: payload.loading,
      },
    };
  case PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        accessLimit: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_PENDING_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        accessLimitPending: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_OVERVIEW_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        accessLimitOverview: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_ACCESS_LIMIT_SETTINGS_LOG:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        accessLimitLog: payload.data,
      },
    };
  case PlayerActionType.SAVE_PLAYER_SINGLE_BET_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        singleBet: payload.data,
      },
    };
  case PlayerActionType.SAVE_HAS_PLAYER_TRANSACTION:
    return {
      ...state,
      hasTransaction: payload.data,
    };
  case PlayerActionType.RESET_HAS_PLAYER_TRANSACTION:
    return {
      ...state,
      hasTransaction: null,
    };
  case PlayerActionType.CHANGE_HAS_PLAYER_TRANSACTION_LOADING_STATE:
    return {
      ...state,
      hasTransactionLoading: payload.state,
    };
  case PlayerActionType.SAVE_PLAYER_GAME_TIME_LIMIT_SETTINGS:
    return {
      ...state,
      responsibleGamingSettings: {
        ...state.responsibleGamingSettings,
        gameTimeLimit: payload.data,
      },
    };
  default:
    return state;
  }
};

export default playerReducer;
