import styled from 'styled-components';
import { Select, TreeSelect } from 'antd';
import { StyledLabelText } from 'components/styledComponents/input/Input.styled';
import { media } from 'utils/styledHelpers';

const StyledSelectWrapper = styled(Select)`
  width: 100%;
  &.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
    background-color: transparent;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    line-height: inherit;
  }
  .ant-select-dropdown {
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.selectBg};
    &.select-scrollable {
      padding-right: 8px;
    }
    .ant-select-dropdown-menu {
      padding: 0;
    }
  }

  &.ant-select-focused {
    position: relative;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 4.14rem;
  }

  &.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 4.5rem !important;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }

  &.ant-select .ant-select-clear {
    background: transparent;
  }

  &.ant-select {
    height: ${(props) => (props.size === 'middle' ? '2.29rem' : `${props.size === 'smallSize' ? '2rem' : '2.57rem'}`)};
    line-height: ${(props) => (props.size === 'middle' ? '2.29rem' : `${props.size === 'smallSize' ? '2rem' : '2.57rem'}`)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    &.ant-select-open .ant-select-selection,
    &:hover .ant-select-selection {
      border-color: ${(props) => props.theme.colors.blue};
      box-shadow: initial !important;
    }

    .ant-select-selection--single,
    .ant-select-selection__rendered,
    .ant-select-selection--multiple,
    .ant-select-selector {
      height: 100%;
      min-height: initial;
    }
    .ant-select-selection-item {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
    .ant-select-selection-overflow {
      .ant-select-selection-item {
        line-height: ${(props) => (props.size === 'middle' ? '2.29rem' : `${props.size === 'smallSize' ? '2rem' : '2.57rem'}`)};
        background-color: transparent;
        border: none;
        margin: 0;
        height: inherit;

        &-remove:hover {
          color: ${(props) => props.theme.colors.primaryText};
        }
        &-content {
          white-space: nowrap;
          font-size: ${(props) => props.theme.typography.fontSizeDefault};
        }
      }
    }
    .ant-select {
      width: 100%;
    }
    .ant-select-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
      height: auto;
      opacity: 1;
      svg {
        width: 0.7rem;
        height: 0.9rem;
      }
    }
    .ant-select-selection-placeholder {
      line-height: ${(props) => (props.size === 'middle' ? '2.29rem' : `${props.size === 'smallSize' ? '2rem' : '2.57rem'}`)};
      inset-inline-start: 11px;
      inset-inline-end: 11px;
    }
  }
  &.ant-select-multiple {
    .ant-select-selection-overflow-item {
      max-width: ${(props) => (props.value && props.value.length > props.maxTagCount ? `calc((100% / ${props.maxTagCount + 1}) - 1%)` : '100%')};

      .ant-select-selection-item-remove {
        display: ${(props) => props.hideItemRemove && 'none'};
      }
    }
    &.ant-select-allow-clear {
      .ant-select-selector {
        background-color: transparent;
        border-color: ${(props) => props.theme.colors.primaryBorder};
        &:hover {
          border-color: ${(props) => props.theme.colors.blue};
        }
        padding: 0;
        flex-wrap: initial;
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
      }
    }
  }
`;
const StyledUserManagementSelectWrapper = styled(StyledSelectWrapper)`
  &.ant-select {
    height: 2.57rem;
  }
`;

const StyledDisplayField = styled.div`
  display: ${(props) => (props.isDisplay ? 'block' : 'none')};
`;

const StyledSelectHeading = styled.div`
  height: 2.28rem;
  line-height: 2.28rem;
  .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-select {
    width: 100%;
    height: 2.28rem;
    &.ant-select-show-arrow {
      .ant-select-selector {
        background: ${(props) => props.theme.colors.secondaryBorder};
        border: none;
        padding: 0;
      }
    }
    &:hover .ant-select-clear,
    .ant-select-clear {
      background: ${(props) => props.theme.colors.secondaryBorder};
    }
  }
`;

const StyledSelectInTable = styled.div`
  max-width: ${(props) => (props.wide ? '20rem' : '14rem')};
  .ant-select {
    .ant-select-selector {
      background-color: ${(props) => props.theme.colors.selectBg} !important;
    }
  }
`;

const StyledSelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isBetween ? 'space-between' : 'flex-start')};
  gap: 0.6rem;
  height: 100%;
  max-width: 100%;
`;
const StyledHiddenOptionExtraData = styled.div`
  .ant-select-selection-overflow-item {
    .ant-select-selection-item-content div + div {
      display: none;
    }
  }
`;

const StyledCustomSelect = styled.div`
  max-height: 18rem;
  padding: 0 4px;
  overflow: auto;
`;
const StyledCustomSelectOption = styled(StyledSelectOption)`
  min-height: 32px;
  padding: 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
  transition: 0.3s background-color;
  cursor: pointer;

  &:hover {
    background-color: #cce2fa;
  }
`;

const StyledMultiSelectWithSeparator = styled.div`
  position: relative;
  max-width: 20rem;

  .ant-col {
    position: initial;
  }
  .ant-space-compact {
    width: 100%;
    .ant-form-item {
      position: initial;
      width: 50%;

      .ant-select.ant-select-single {
        height: 2.58rem;

        .ant-select-selector {
          padding: 0 0.71rem;

          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            padding-right: 0.57rem;
          }

          .ant-select-selection-search {
            left: 0.71rem;
            width: calc(100% - 3rem);
          }
        }
      }

      &:first-child {
        .ant-select.ant-select-single {
          .ant-select-selector {
            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &:last-child {
        .ant-select.ant-select-single {
          .ant-select-selector {
            border-left-width: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      .ant-form-item-control-input {
        height: 100%;
      }

      .ant-form-item-explain {
        z-index: 555;
        top: 100%;
        &-error {
          height: auto;
        }
      }
      .ant-form-item-explain-error {
        position: relative;
        z-index: 99;
        width: calc(200% + 3.42rem);
      }
    }
    .ant-input {
      background: ${(props) => props.theme.colors.selectBg} !important;
    }
  }
`;

const StyledMultiSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${StyledLabelText} {
    flex-basis: 100%;
  }

  .ant-form-item {
    &:first-of-type {
      flex-basis: 35%;
      width: 35%;
      .ant-select {
        .ant-select-selector {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right-color: transparent;
        }
      }
    }

    &:last-of-type {
      flex-grow: 1;
      .ant-select {
        .ant-select-selector {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
`;

const StyledSelectOptionWithExtra = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectOptionExtra = styled.div`
  background: ${(props) => props.theme.colors.primaryBg};
  padding: 0 0.5rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  height: 1.5rem;
  line-height: 1.5rem;
  margin-left: 0.85rem;
  > div {
    font-size: 0.85rem;
  }
`;

const StyledAutoRefresh = styled.div`
  .ant-form-item {
    .ant-row {
      align-items: center;
      .ant-col.ant-form-item-label,
      .ant-col.ant-form-item-control {
        flex: 0 0 auto;
        padding: 0;
      }
      .ant-select {
        width: 10rem;
        margin-left: 1rem;
      }
    }
  }
`;

const StyledTreeSelect = styled(TreeSelect)`
  .ant-select-selector.ant-select-selector {
    background: ${(props) => props.theme.colors.secondaryBg};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  }

  .ant-select-tree {
    background: ${(props) => props.theme.colors.selectBg};
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-select-arrow svg {
    fill: ${(props) => props.theme.colors.primaryText};
  }
`;

const StyledSelectInManageFilters = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  padding-right: 1.14rem;
  margin-bottom: 0.57rem;
  display: flex;
  align-items: center;
  column-gap: 1.71rem;
  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: 0;
    }
    .ant-select {
      padding-right: 0;
      width: 10rem;
    }
  }

  ${media.sm`
    border: none;
    margin-right: 0;
    padding-right: 0;
    
    .ant-form {
      .ant-form-item {
        .ant-col.ant-form-item-label,
        .ant-col.ant-form-item-control {
          flex: 0 0 auto;
        }
      }
    }
  `}

  ${media.xs`
    .ant-form-item{
      width:10rem;
    }
  `}
`;

export {
  StyledSelectWrapper,
  StyledSelectHeading,
  StyledUserManagementSelectWrapper,
  StyledDisplayField,
  StyledSelectInTable,
  StyledSelectOption,
  StyledHiddenOptionExtraData,
  StyledCustomSelect,
  StyledCustomSelectOption,
  StyledMultiSelectWithSeparator,
  StyledMultiSelectWrapper,
  StyledSelectOptionWithExtra,
  StyledSelectOptionExtra,
  StyledAutoRefresh,
  StyledTreeSelect,
  StyledSelectInManageFilters,
};
