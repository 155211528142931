import { createSelector } from 'reselect';

const selectCampaigns = (state) => state.campaigns;
export const selectCampaignsV2Data = (state) => selectCampaigns(state).data;
export const selectCampaignsV2Total = (state) => selectCampaigns(state).total;
export const selectCampaignV2 = (state) => state.campaign;
export const selectCampaignV2Name = (state) => selectCampaignV2(state).name;
export const selectCreatedCampaignV2Id = (state) => state.createdCampaignId;
export const selectCampaignV2GeneralConfigEndedStatus = (state) => state.generalConfigEndedStatus;
export const selectCampaignV2Trigger = (state) => selectCampaignV2(state).trigger;

export const selectCampaignV2BrandId = (state) => selectCampaignV2(state).brandId;
export const selectCampaignV2ParticipationGroups = (state) => state.participationGroups;
export const selectCampaignV2ParticipationGroup = (state) => state.participationGroup;
export const selectCampaignV2ParticipationGroupEndedStatus = (state) => state.participationGroupEndedStatus;

export const selectCampaignV2BonusEndedStatus = (state) => state.bonusEndedStatus;
export const selectCampaignV2FreeBetBonus = (state) => state.freeBetBonus;
export const selectCampaignV2FreeSpinBonus = (state) => state.freeSpinBonus;
export const selectCampaignV2CombinedBonus = (state) => state.combinedBonus;
export const selectCampaignV2SpecialBonus = (state) => state.specialBonus;
export const selectCampaignV2Bonuses = (state) => state.bonuses;

export const selectCampaignV2TriggerConditions = (state) => state.triggerConditions;
export const selectCampaignV2TriggerCondition = (state) => state.triggerCondition;
export const selectCampaignV2TriggerConditionEndedStatus = (state) => state.triggerConditionEndedStatus;
export const selectCampaignV2PublishEndedStatus = (state) => state.publishEndedStatus;

export const selectCampaignV2BonusContents = (state) => state.bonusContents;
export const selectCampaignV2BonusContent = (state) => state.bonusContent;
export const selectCampaignV2BonusContentEndedStatus = (state) => state.bonusContentEndedStatus;

export const selectCampaignV2CampaignContents = (state) => state.campaignContents;
export const selectCampaignV2CampaignContent = (state) => state.campaignContent;
export const selectCampaignV2CampaignContentEndedStatus = (state) => state.campaignContentEndedStatus;

export const selectNonContentBonuses = (state) => createSelector(
  () => selectCampaignV2BonusContents(state),
  () => selectCampaignV2BonusContent(state),
  () => selectCampaignV2Bonuses(state),
  (bonusContents, bonusContent, bonuses) => {
    const contentIds = bonusContents.map((content) => content.bonusId);

    return bonuses.filter((bonus) => bonus.id === bonusContent.bonusId || !contentIds.includes(bonus.id)).map((bonus) => ({ id: bonus.id, name: bonus.name }));
  },
)();

export const selectCampaignV2Units = (state) => state.units;
export const selectCampaignV2Unit = (state) => state.unit;
export const selectCampaignV2UnitEndedStatus = (state) => state.unitEndedStatus;

export const selectCampaignV2OffersContents = (state) => state.offersContents;
export const selectCampaignV2OffersContent = (state) => state.offersContent;
export const selectCampaignV2OffersContentEndedStatus = (state) => state.offersContentEndedStatus;

const selectCampaignV2Statistics = (state) => state.campaignV2Statistics;
export const selectCampaignV2StatisticsData = (state) => selectCampaignV2Statistics(state).data;
export const selectCampaignV2StatisticsTotal = (state) => selectCampaignV2Statistics(state).total;

const campaignsV2Units = (state) => state.campaignsV2Units;
export const selectCampaignsV2UnitsData = (state) => campaignsV2Units(state).data;
export const selectCampaignsV2UnitsTotal = (state) => campaignsV2Units(state).total;

const selectCampaignV2UnitPlayers = (state) => state.campaignV2UnitPlayers;
export const selectCampaignV2UnitPlayersData = (state) => selectCampaignV2UnitPlayers(state).data;
export const selectCampaignV2UnitPlayersTotal = (state) => selectCampaignV2UnitPlayers(state).total;

const selectCampaignV2FreeBetBonuses = (state) => state.freeBetBonuses;

export const selectCampaignV2FreeBetBonusesData = (state) => selectCampaignV2FreeBetBonuses(state).data;
export const selectCampaignV2FreeBetBonusesTotal = (state) => selectCampaignV2FreeBetBonuses(state).total;

const selectCampaignV2SpinBonuses = (state) => state.spinBonuses;

export const selectCampaignV2SpinBonusesData = (state) => selectCampaignV2SpinBonuses(state).data;
export const selectCampaignV2SpinBonusesTotal = (state) => selectCampaignV2SpinBonuses(state).total;

const selectCampaignV2WagerBonuses = (state) => state.wagerBonuses;

export const selectCampaignV2WagerBonusesData = (state) => selectCampaignV2WagerBonuses(state).data;
export const selectCampaignV2WagerBonusesTotal = (state) => selectCampaignV2WagerBonuses(state).total;

const selectCampaignV2SpecialBonuses = (state) => state.specialBonuses;

export const selectCampaignV2SpecialBonusesData = (state) => selectCampaignV2SpecialBonuses(state).data;
export const selectCampaignV2SpecialBonusesTotal = (state) => selectCampaignV2SpecialBonuses(state).total;

const selectCrossPlatformCampaignsV2 = (state) => state.crossPlatformCampaignsV2;
export const selectCrossPlatformCampaignsV2Data = (state) => selectCrossPlatformCampaignsV2(state).data;
export const selectCrossPlatformCampaignsV2Total = (state) => selectCrossPlatformCampaignsV2(state).total;
export const selectCrossPlatformCampaignV2 = (state) => state.crossPlatformCampaignV2;

export const selectCrossPlatformCampaignV2BrandId = (state) => selectCrossPlatformCampaignV2(state).brandId;

export const selectCrossPlatformCampaignV2FreeBetBonus = (state) => state.crossPlatformCampaignV2FreeBetBonus;
export const selectCrossPlatformCampaignV2FreeSpinBonus = (state) => state.crossPlatformCampaignV2FreeSpinBonus;
export const selectCrossPlatformCampaignV2CombinedBonus = (state) => state.crossPlatformCampaignV2CombinedBonus;
export const selectCrossPlatformCampaignV2SpecialBonus = (state) => state.crossPlatformCampaignV2SpecialBonus;
export const selectCrossPlatformCampaignV2Bonuses = (state) => state.crossPlatformCampaignV2Bonuses;

export const selectCrossPlatformCampaignV2BonusContents = (state) => state.crossPlatformCampaignV2BonusContents;
export const selectCrossPlatformCampaignV2BonusContent = (state) => state.crossPlatformCampaignV2BonusContent;

export const selectCrossPlatformCampaignV2NonContentBonuses = (state) => createSelector(
  () => selectCrossPlatformCampaignV2BonusContents(state),
  () => selectCrossPlatformCampaignV2BonusContent(state),
  () => selectCrossPlatformCampaignV2Bonuses(state),
  (bonusContents, bonusContent, bonuses) => {
    const contentIds = bonusContents.map((content) => content.bonusId);

    return bonuses.filter((bonus) => bonus.id === bonusContent.bonusId || !contentIds.includes(bonus.id)).map((bonus) => ({ id: bonus.id, name: bonus.name }));
  },
)();

export const selectCrossPlatformCampaignV2Units = (state) => state.crossPlatformCampaignV2Units;
export const selectCrossPlatformCampaignV2Unit = (state) => state.crossPlatformCampaignV2Unit;

const selectCrossPlatformCampaignV2Statistics = (state) => state.crossPlatformCampaignV2Statistics;
export const selectCrossPlatformCampaignV2StatisticsData = (state) => selectCrossPlatformCampaignV2Statistics(state).data;
export const selectCrossPlatformCampaignV2StatisticsTotal = (state) => selectCrossPlatformCampaignV2Statistics(state).total;
