import ResourceActionType from 'actions/resource/resourceActionType';
import { LocalStorageService } from 'services';

const initialState = {
  paymentMethods: [],
  countries: [],
  segments: [],
  tags: [],
  gameCategories: [],
  users: [],
  paymentMethodCurrencies: [],
  currencies: [],
  providers: {},
  providerCategories: [],
  games: [],
  reasons: [],
  campaigns: [],
  tagCategories: {},
  tagCategory: {},
  tagNames: [],
  playerTags: [],
  verticalCategoryReasons: {},
  brand: {},
  partner: {},
  negativeBalanceExists: false,
  betshops: [],
  partnerTFAState: false,
  gamesSegments: [],
  crossPlatformCampaign: [],
  crossPlatformCampaignBonuses: [],
  gamesLoading: false,
  promotions: {},
  promotionMatches: {},
  brandPeriodsLimits: {},
  gameGroupsNames: [],
  currenciesPrecisions: LocalStorageService.get('currenciesPrecisions') || [],
  brandTaxDda: false,
  hasFixedWithdrawalAmount: false,
  providersAggregators: [],
  tagsLoading: false,
  gameCategoriesLoading: false,
  sportsBookLayouts: [],
  gridMachineDimensionTypes: [],
  customPages: [],
  cashierType: null,
  releaseNotes: [],
  gameTypes: [],
  deepLinkPromotions: [],
  permissionGroups: {},
  kycType: null,
  directoryRules: [],
  lobbyGroups: [],
  groupGames: [],
  groupGamesLoading: false,
  campaignsV2: [],
  lobbyProviders: [],
  campaignsV2Units: [],
  lobbyCategories: [],
  lobbyGames: {},
};

const resourceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ResourceActionType.SAVE_RESOURCE_PAYMENT_METHODS:
    return {
      ...state,
      paymentMethods: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PAYMENT_METHODS:
    return {
      ...state,
      paymentMethods: [],
    };
  case ResourceActionType.SAVE_RESOURCE_COUNTRIES:
    return {
      ...state,
      countries: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_COUNTRIES:
    return {
      ...state,
      countries: [],
    };
  case ResourceActionType.SAVE_RESOURCE_SEGMENTS:
    return {
      ...state,
      segments: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_SEGMENTS:
    return {
      ...state,
      segments: [],
    };
  case ResourceActionType.SAVE_RESOURCE_TAGS:
    return {
      ...state,
      tags: {
        ...state.tags,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_TAGS:
    return {
      ...state,
      tags: {
        ...state.tags,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_GAME_CATEGORIES:
    return {
      ...state,
      gameCategories: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAME_CATEGORIES:
    return {
      ...state,
      gameCategories: [],
    };
  case ResourceActionType.CHANGE_RESOURCE_GAME_CATEGORIES_STATE:
    return {
      ...state,
      gameCategoriesLoading: payload.state,
    };
  case ResourceActionType.SAVE_RESOURCE_USERS:
    return {
      ...state,
      users: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_USERS:
    return {
      ...state,
      users: [],
    };
  case ResourceActionType.SAVE_RESOURCE_PAYMENT_METHOD_CURRENCIES:
    return {
      ...state,
      paymentMethodCurrencies: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PAYMENT_METHOD_CURRENCIES:
    return {
      ...state,
      paymentMethodCurrencies: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CURRENCIES:
    return {
      ...state,
      currencies: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CURRENCIES:
    return {
      ...state,
      currencies: [],
    };
  case ResourceActionType.SAVE_RESOURCE_PROVIDERS:
    return {
      ...state,
      providers: {
        ...state.providers,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_PROVIDERS:
    return {
      ...state,
      providers: {
        ...state.providers,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS:
    return {
      ...state,
      providerCategories: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAME_CATEGORIES_IN_PROVIDERS:
    return {
      ...state,
      providerCategories: [],
    };
  case ResourceActionType.SAVE_RESOURCE_GAMES:
    return {
      ...state,
      games: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAMES:
    return {
      ...state,
      games: [],
    };
  case ResourceActionType.SAVE_RESOURCE_REASONS:
    return {
      ...state,
      reasons: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_REASONS:
    return {
      ...state,
      reasons: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CAMPAIGNS:
    return {
      ...state,
      campaigns: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CAMPAIGNS:
    return {
      ...state,
      campaigns: [],
    };
  case ResourceActionType.SAVE_RESOURCE_TAG_CATEGORIES:
    return {
      ...state,
      tagCategories: {
        ...state.tagCategories,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_TAG_CATEGORIES:
    return {
      ...state,
      tagCategories: {
        ...state.tagCategories,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_TAG_CATEGORY:
    return {
      ...state,
      tagCategory: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_TAG_CATEGORY:
    return {
      ...state,
      tagCategory: {},
    };
  case ResourceActionType.SAVE_RESOURCE_TAGS_NAMES:
    return {
      ...state,
      tagNames: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_TAGS_NAMES:
    return {
      ...state,
      tagNames: [],
    };

  case ResourceActionType.SAVE_RESOURCE_PLAYER_TAGS:
    return {
      ...state,
      playerTags: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PLAYER_TAGS:
    return {
      ...state,
      playerTags: [],
    };
  case ResourceActionType.SAVE_RESOURCE_VERTICAL_CATEGORY_REASONS:
    return {
      ...state,
      verticalCategoryReasons: {
        ...state.verticalCategoryReasons,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_VERTICAL_CATEGORY_REASONS:
    return {
      ...state,
      verticalCategoryReasons: {
        ...state.verticalCategoryReasons,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_BRAND:
    return {
      ...state,
      brand: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_BRAND:
    return {
      ...state,
      brand: {},
    };
  case ResourceActionType.SAVE_RESOURCE_PARTNER:
    return {
      ...state,
      partner: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PARTNER:
    return {
      ...state,
      partner: {},
    };
  case ResourceActionType.SAVE_RESOURCE_NEGATIVE_BALANCE_EXISTS:
    return {
      ...state,
      negativeBalanceExists: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_NEGATIVE_BALANCE_EXISTS:
    return {
      ...state,
      negativeBalanceExists: false,
    };
  case ResourceActionType.SAVE_RESOURCE_BETSHOPS:
    return {
      ...state,
      betshops: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_BETSHOPS:
    return {
      ...state,
      betshops: [],
    };
  case ResourceActionType.SAVE_RESOURCE_PARTNER_TFA_STATE:
    return {
      ...state,
      partnerTFAState: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PARTNER_TFA_STATE:
    return {
      ...state,
      partnerTFAState: false,
    };
  case ResourceActionType.SAVE_RESOURCE_GAMES_SEGMENTS:
    return {
      ...state,
      gamesSegments: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAMES_SEGMENTS:
    return {
      ...state,
      gamesSegments: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGNS:
    return {
      ...state,
      crossPlatformCampaign: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS:
    return {
      ...state,
      crossPlatformCampaign: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES:
    return {
      ...state,
      crossPlatformCampaignBonuses: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES:
    return {
      ...state,
      crossPlatformCampaignBonuses: [],
    };
  case ResourceActionType.CHANGE_RESOURCE_GAMES_LOADING:
    return {
      ...state,
      gamesLoading: payload.state,
    };
  case ResourceActionType.SAVE_RESOURCE_PROMOTIONS:
    return {
      ...state,
      promotions: {
        ...state.promotions,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_PROMOTIONS:
    return {
      ...state,
      promotions: {
        ...state.promotions,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_PROMOTION_MACHINES:
    return {
      ...state,
      promotionMatches: {
        ...state.promotionMatches,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_PROMOTION_MACHINES:
    return {
      ...state,
      promotionMatches: {
        ...state.promotionMatches,
        [payload.key]: [],
      },
    };
  case ResourceActionType.SAVE_RESOURCE_BRAND_PERIODS_LIMITS:
    return {
      ...state,
      brandPeriodsLimits: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_BRAND_PERIODS_LIMITS:
    return {
      ...state,
      brandPeriodsLimits: {},
    };
  case ResourceActionType.SAVE_RESOURCE_GAME_GROUPS_NAMES:
    return {
      ...state,
      gameGroupsNames: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAME_GROUPS_NAMES:
    return {
      ...state,
      gameGroupsNames: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CURRENCIES_PRECISIONS:
    return {
      ...state,
      currenciesPrecisions: payload.data,
    };
  case ResourceActionType.SAVE_RESOURCE_BRAND_TAX_DDA:
    return {
      ...state,
      brandTaxDda: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_BRAND_TAX_DDA:
    return {
      ...state,
      brandTaxDda: false,
    };
  case ResourceActionType.SAVE_RESOURCE_FIXED_WITHDRAWAL_AMOUNT:
    return {
      ...state,
      hasFixedWithdrawalAmount: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT:
    return {
      ...state,
      hasFixedWithdrawalAmount: false,
    };
  case ResourceActionType.SAVE_RESOURCE_PROVIDERS_AGGREGATORS:
    return {
      ...state,
      providersAggregators: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_PROVIDERS_AGGREGATORS:
    return {
      ...state,
      providersAggregators: [],
    };
  case ResourceActionType.CHANGE_RESOURCE_TAGS_LOADING:
    return {
      ...state,
      tagsLoading: payload.state,
    };
  case ResourceActionType.SAVE_RESOURCE_SPORTSBOOK_LAYOUTS:
    return {
      ...state,
      sportsBookLayouts: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_SPORTSBOOK_LAYOUTS:
    return {
      ...state,
      sportsBookLayouts: [],
    };
  case ResourceActionType.SAVE_RESOURCE_GRID_MACHINE_DIMENSION_TYPES:
    return {
      ...state,
      gridMachineDimensionTypes: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES:
    return {
      ...state,
      gridMachineDimensionTypes: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CUSTOM_PAGES:
    return {
      ...state,
      customPages: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CUSTOM_PAGES:
    return {
      ...state,
      customPages: [],
    };
  case ResourceActionType.SAVE_RESOURCE_CASHIER_TYPE:
    return {
      ...state,
      cashierType: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CASHIER_TYPE:
    return {
      ...state,
      cashierType: null,
    };
  case ResourceActionType.SAVE_RESOURCE_RELEASE_NOTES:
    return {
      ...state,
      releaseNotes: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_RELEASE_NOTES:
    return {
      ...state,
      releaseNotes: [],
    };
  case ResourceActionType.SAVE_RESOURCE_GAME_TYPES:
    return {
      ...state,
      gameTypes: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GAME_TYPES:
    return {
      ...state,
      gameTypes: [],
    };
  case ResourceActionType.SAVE_RESOURCE_DEEP_LINK_PROMOTIONS:
    return {
      ...state,
      deepLinkPromotions: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_DEEP_LINK_PROMOTIONS:
    return {
      ...state,
      deepLinkPromotions: [],
    };
  case ResourceActionType.SAVE_RESOURCE_PERMISSION_GROUPS:
    return {
      ...state,
      permissionGroups: {
        ...state.permissionGroups,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_PERMISSION_GROUPS:
    return {
      ...state,
      permissionGroups: {},
    };
  case ResourceActionType.SAVE_RESOURCE_KYC_TYPE:
    return {
      ...state,
      kycType: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_KYC_TYPE:
    return {
      ...state,
      kycType: null,
    };
  case ResourceActionType.SAVE_RESOURCE_DIRECTORY_RULES:
    return {
      ...state,
      directoryRules: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_DIRECTORY_RULES:
    return {
      ...state,
      directoryRules: [],
    };
  case ResourceActionType.SAVE_RESOURCE_LOBBY_GROUPS:
    return {
      ...state,
      lobbyGroups: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_LOBBY_GROUPS:
    return {
      ...state,
      lobbyGroups: [],
    };
  case ResourceActionType.SAVE_RESOURCE_GROUP_GAMES:
    return {
      ...state,
      groupGames: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_GROUP_GAMES:
    return {
      ...state,
      groupGames: [],
    };
  case ResourceActionType.CHANGE_RESOURCE_GROUP_GAMES_LOADING:
    return {
      ...state,
      groupGamesLoading: payload.state,
    };
  case ResourceActionType.SAVE_RESOURCE_CAMPAIGNS_V2:
    return {
      ...state,
      campaignsV2: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CAMPAIGNS_V2:
    return {
      ...state,
      campaignsV2: [],
    };
  case ResourceActionType.SAVE_RESOURCE_LOBBY_PROVIDERS:
    return {
      ...state,
      lobbyProviders: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_LOBBY_PROVIDERS:
    return {
      ...state,
      lobbyProviders: [],
    };
  case ResourceActionType.SAVE_RESOURCE_LOBBY_CATEGORIES:
    return {
      ...state,
      lobbyCategories: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_LOBBY_CATEGORIES:
    return {
      ...state,
      lobbyCategories: [],
    };
  case ResourceActionType.SAVE_RESOURCE_LOBBY_GAMES:
    return {
      ...state,
      lobbyGames: {
        ...state.lobbyGames,
        [payload.key]: payload.data,
      },
    };
  case ResourceActionType.RESET_RESOURCE_LOBBY_GAMES:
    return {
      ...state,
      lobbyGames: {},
    };
  case ResourceActionType.SAVE_RESOURCE_CAMPAIGNS_V2_UNITS:
    return {
      ...state,
      campaignsV2Units: payload.data,
    };
  case ResourceActionType.RESET_RESOURCE_CAMPAIGNS_V2_UNITS:
    return {
      ...state,
      campaignsV2Units: [],
    };
  default:
    return state;
  }
};

export default resourceReducer;
