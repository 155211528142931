import BonusActionType from './bonusActionType';

export const getCasinoBonuses = (brandId, params) => ({
  type: BonusActionType.GET_CASINO_BONUSES,
  payload: { brandId, params },
});

export const saveCasinoBonuses = (data) => ({
  type: BonusActionType.SAVE_CASINO_BONUSES,
  payload: { data },
});

export const resetCasinoBonuses = () => ({
  type: BonusActionType.RESET_CASINO_BONUSES,
});

export const getSpinBonuses = (brandId, params) => ({
  type: BonusActionType.GET_SPIN_BONUSES,
  payload: { brandId, params },
});

export const saveSpinBonuses = (data) => ({
  type: BonusActionType.SAVE_SPIN_BONUSES,
  payload: { data },
});

export const resetSpinBonuses = () => ({
  type: BonusActionType.RESET_SPIN_BONUSES,
});

export const getSportBonuses = (brandId, params) => ({
  type: BonusActionType.GET_SPORT_BONUSES,
  payload: { brandId, params },
});

export const saveSportBonuses = (data) => ({
  type: BonusActionType.SAVE_SPORT_BONUSES,
  payload: { data },
});

export const resetSportBonuses = () => ({
  type: BonusActionType.RESET_SPORT_BONUSES,
});

export const getSpecialBonuses = (brandId, params) => ({
  type: BonusActionType.GET_SPECIAL_BONUSES,
  payload: { brandId, params },
});

export const saveSpecialBonuses = (data) => ({
  type: BonusActionType.SAVE_SPECIAL_BONUSES,
  payload: { data },
});

export const resetSpecialBonuses = () => ({
  type: BonusActionType.RESET_SPECIAL_BONUSES,
});

export const getPlayerCasinoBonuses = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_CASINO_BONUSES,
  payload: { playerId, params },
});

export const savePlayerCasinoBonuses = (data) => ({
  type: BonusActionType.SAVE_PLAYER_CASINO_BONUSES,
  payload: { data },
});

export const resetPlayerCasinoBonuses = () => ({
  type: BonusActionType.RESET_PLAYER_CASINO_BONUSES,
});

export const getPlayerSpinBonuses = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_SPIN_BONUSES,
  payload: { playerId, params },
});

export const savePlayerSpinBonuses = (data) => ({
  type: BonusActionType.SAVE_PLAYER_SPIN_BONUSES,
  payload: { data },
});

export const resetPlayerSpinBonuses = () => ({
  type: BonusActionType.RESET_PLAYER_SPIN_BONUSES,
});

export const getPlayerSportBonuses = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_SPORT_BONUSES,
  payload: { playerId, params },
});

export const savePlayerSportBonuses = (data) => ({
  type: BonusActionType.SAVE_PLAYER_SPORT_BONUSES,
  payload: { data },
});

export const resetPlayerSportBonuses = () => ({
  type: BonusActionType.RESET_PLAYER_SPORT_BONUSES,
});

export const getPlayerSpecialBonuses = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_SPECIAL_BONUSES,
  payload: { playerId, params },
});

export const savePlayerSpecialBonuses = (data) => ({
  type: BonusActionType.SAVE_PLAYER_SPECIAL_BONUSES,
  payload: { data },
});

export const resetPlayerSpecialBonuses = () => ({
  type: BonusActionType.RESET_PLAYER_SPECIAL_BONUSES,
});

export const getCasinoBonusStatistics = (bonusId, params) => ({
  type: BonusActionType.GET_CASINO_BONUS_STATISTICS,
  payload: { bonusId, params },
});

export const saveCasinoBonusStatistics = (data) => ({
  type: BonusActionType.SAVE_CASINO_BONUS_STATISTICS,
  payload: { data },
});

export const resetCasinoBonusStatistics = () => ({
  type: BonusActionType.RESET_CASINO_BONUS_STATISTICS,
});

export const exportCasinoBonusStatistics = (id, params) => ({
  type: BonusActionType.EXPORT_CASINO_BONUS_STATISTICS,
  payload: { id, params },
});

export const getSpinBonusStatistics = (bonusId, params) => ({
  type: BonusActionType.GET_SPIN_BONUS_STATISTICS,
  payload: { bonusId, params },
});

export const saveSpinBonusStatistics = (data) => ({
  type: BonusActionType.SAVE_SPIN_BONUS_STATISTICS,
  payload: { data },
});

export const resetSpinBonusStatistics = () => ({
  type: BonusActionType.RESET_SPIN_BONUS_STATISTICS,
});

export const exportSpinBonusStatistics = (id, params) => ({
  type: BonusActionType.EXPORT_SPIN_BONUS_STATISTICS,
  payload: { id, params },
});

export const getSportBonusStatistics = (bonusId, params) => ({
  type: BonusActionType.GET_SPORT_BONUS_STATISTICS,
  payload: { bonusId, params },
});

export const saveSportBonusStatistics = (data) => ({
  type: BonusActionType.SAVE_SPORT_BONUS_STATISTICS,
  payload: { data },
});

export const resetSportBonusStatistics = () => ({
  type: BonusActionType.RESET_SPORT_BONUS_STATISTICS,
});

export const exportSportBonusStatistics = (id, params) => ({
  type: BonusActionType.EXPORT_SPORT_BONUS_STATISTICS,
  payload: { id, params },
});

export const getSpecialBonusStatistics = (bonusId, params) => ({
  type: BonusActionType.GET_SPECIAL_BONUS_STATISTICS,
  payload: { bonusId, params },
});

export const saveSpecialBonusStatistics = (data) => ({
  type: BonusActionType.SAVE_SPECIAL_BONUS_STATISTICS,
  payload: { data },
});

export const resetSpecialBonusStatistics = () => ({
  type: BonusActionType.RESET_SPECIAL_BONUS_STATISTICS,
});

export const exportSpecialBonusStatistics = (id, params) => ({
  type: BonusActionType.EXPORT_SPECIAL_BONUS_STATISTICS,
  payload: { id, params },
});

export const getCasinoBonus = (id) => ({
  type: BonusActionType.GET_CASINO_BONUS,
  payload: { id },
});

export const saveCasinoBonus = (data) => ({
  type: BonusActionType.SAVE_CASINO_BONUS,
  payload: { data },
});

export const resetCasinoBonus = () => ({
  type: BonusActionType.RESET_CASINO_BONUS,
});

export const getSpinBonus = (id) => ({
  type: BonusActionType.GET_SPIN_BONUS,
  payload: { id },
});

export const saveSpinBonus = (data) => ({
  type: BonusActionType.SAVE_SPIN_BONUS,
  payload: { data },
});

export const resetSpinBonus = () => ({
  type: BonusActionType.RESET_SPIN_BONUS,
});

export const getSportBonus = (id) => ({
  type: BonusActionType.GET_SPORT_BONUS,
  payload: { id },
});

export const saveSportBonus = (data) => ({
  type: BonusActionType.SAVE_SPORT_BONUS,
  payload: { data },
});

export const resetSportBonus = () => ({
  type: BonusActionType.RESET_SPORT_BONUS,
});

export const getSpecialBonus = (id, isLoading) => ({
  type: BonusActionType.GET_SPECIAL_BONUS,
  payload: { id, isLoading },
});

export const saveSpecialBonus = (data) => ({
  type: BonusActionType.SAVE_SPECIAL_BONUS,
  payload: { data },
});

export const resetSpecialBonus = () => ({
  type: BonusActionType.RESET_SPECIAL_BONUS,
});

export const cancelBonus = (data) => ({
  type: BonusActionType.CANCEL_BONUS,
  payload: { data },
});

export const cancelPlayerBonus = (data) => ({
  type: BonusActionType.CANCEL_PLAYER_BONUS,
  payload: { data },
});

export const getBonusContent = (bonusId) => ({
  type: BonusActionType.GET_BONUS_CONTENT,
  payload: { bonusId },
});

export const saveBonusContent = (data) => ({
  type: BonusActionType.SAVE_BONUS_CONTENT,
  payload: { data },
});

export const resetBonusContent = () => ({
  type: BonusActionType.RESET_BONUS_CONTENT,
});

export const getPlayerFreeBetBonusesV2 = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_FREE_BET_BONUSES_V2,
  payload: { playerId, params },
});

export const savePlayerFreeBetBonusesV2 = (data) => ({
  type: BonusActionType.SAVE_PLAYER_FREE_BET_BONUSES_V2,
  payload: { data },
});

export const resetPlayerFreeBetBonusesV2 = () => ({
  type: BonusActionType.RESET_PLAYER_FREE_BET_BONUSES_V2,
});

export const getPlayerSpinBonusesV2 = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_SPIN_BONUSES_V2,
  payload: { playerId, params },
});

export const savePlayerSpinBonusesV2 = (data) => ({
  type: BonusActionType.SAVE_PLAYER_SPIN_BONUSES_V2,
  payload: { data },
});

export const resetPlayerSpinBonusesV2 = () => ({
  type: BonusActionType.RESET_PLAYER_SPIN_BONUSES_V2,
});

export const getPlayerCombinedBonusesV2 = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_COMBINED_BONUSES_V2,
  payload: { playerId, params },
});

export const savePlayerCombinedBonusesV2 = (data) => ({
  type: BonusActionType.SAVE_PLAYER_COMBINED_BONUSES_V2,
  payload: { data },
});

export const resetPlayerCombinedBonusesV2 = () => ({
  type: BonusActionType.RESET_PLAYER_COMBINED_BONUSES_V2,
});

export const getPlayerSpecialBonusesV2 = (playerId, params) => ({
  type: BonusActionType.GET_PLAYER_SPECIAL_BONUSES_V2,
  payload: { playerId, params },
});

export const savePlayerSpecialBonusesV2 = (data) => ({
  type: BonusActionType.SAVE_PLAYER_SPECIAL_BONUSES_V2,
  payload: { data },
});

export const resetPlayerSpecialBonusesV2 = () => ({
  type: BonusActionType.RESET_PLAYER_SPECIAL_BONUSES_V2,
});

export const cancelPlayerBonusV2 = (playerBonusId) => ({
  type: BonusActionType.CANCEL_PLAYER_BONUS_V2,
  payload: { playerBonusId },
});
