import BrandAutomationActionType from 'actions/brandAutomation/brandAutomationActionType';

export const getIsBrandAutomationExist = (brandId, params) => ({
  type: BrandAutomationActionType.GET_IS_BRAND_AUTOMATION_EXIST,
  payload: { brandId, params },
});

export const saveIsBrandAutomationExist = (data) => ({
  type: BrandAutomationActionType.SAVE_IS_BRAND_AUTOMATION_EXIST,
  payload: { data },
});

export const resetIsBrandAutomationExist = () => ({
  type: BrandAutomationActionType.RESET_IS_BRAND_AUTOMATION_EXIST,
});

export const getConditionsActions = (triggerType) => ({
  type: BrandAutomationActionType.GET_CONDITIONS_ACTIONS,
  payload: { triggerType },
});

export const saveConditionsActions = (data) => ({
  type: BrandAutomationActionType.SAVE_CONDITIONS_ACTIONS,
  payload: { data },
});

export const resetConditionsActions = () => ({
  type: BrandAutomationActionType.RESET_CONDITIONS_ACTIONS,
});

export const createBrandAutomation = (data) => ({
  type: BrandAutomationActionType.CREATE_BRAND_AUTOMATION,
  payload: { data },
});

export const getBrandAutomationRules = (params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_RULES,
  payload: { params },
});

export const saveBrandAutomationRules = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_RULES,
  payload: { data },
});

export const resetBrandAutomationRules = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_RULES,
});

export const exportBrandAutomationRules = (brandId, params) => ({
  type: BrandAutomationActionType.EXPORT_BRAND_AUTOMATION_RULES,
  payload: { brandId, params },
});

export const getBrandAutomationRule = (id) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_RULE,
  payload: { id },
});

export const saveBrandAutomationRule = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_RULE,
  payload: { data },
});

export const resetBrandAutomationRule = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_RULE,
});

export const editBrandAutomation = (id, data) => ({
  type: BrandAutomationActionType.EDIT_BRAND_AUTOMATION,
  payload: { id, data },
});

export const enableBrandAutomation = (id, params) => ({
  type: BrandAutomationActionType.ENABLE_BRAND_AUTOMATION,
  payload: { id, params },
});

export const finishBrandAutomation = (id, params) => ({
  type: BrandAutomationActionType.FINISH_BRAND_AUTOMATION,
  payload: { id, params },
});

export const deleteBrandAutomation = (id, params) => ({
  type: BrandAutomationActionType.DELETE_BRAND_AUTOMATION,
  payload: { id, params },
});

export const getBrandAutomationTransactionLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_TRANSACTION_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationTransactionLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_TRANSACTION_LOGS,
  payload: { data },
});

export const resetBrandAutomationTransactionLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_TRANSACTION_LOGS,
});

export const generateBrandAutomationTransactionLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_TRANSACTION_LOGS,
  payload: { ruleId, params },
});

export const getBrandAutomationPeriodicalLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_PERIODICAL_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationPeriodicalLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_PERIODICAL_LOGS,
  payload: { data },
});

export const resetBrandAutomationPeriodicalLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_PERIODICAL_LOGS,
});

export const generateBrandAutomationPeriodicalLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_PERIODICAL_LOGS,
  payload: { ruleId, params },
});

export const getBrandAutomationSignUpLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_SIGN_UP_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationSignUpLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_SIGN_UP_LOGS,
  payload: { data },
});

export const resetBrandAutomationSignUpLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_SIGN_UP_LOGS,
});

export const generateBrandAutomationSignUpLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_SIGN_UP_LOGS,
  payload: { ruleId, params },
});

export const getBrandAutomationVerificationLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_VERIFICATION_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationVerificationLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_VERIFICATION_LOGS,
  payload: { data },
});

export const resetBrandAutomationVerificationLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_VERIFICATION_LOGS,
});

export const generateBrandAutomationVerificationLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_VERIFICATION_LOGS,
  payload: { ruleId, params },
});

export const getBrandAutomationVerificationDiscardLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationVerificationDiscardLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS,
  payload: { data },
});

export const resetBrandAutomationVerificationDiscardLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS,
});

export const generateBrandAutomationVerificationDiscardLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS,
  payload: { ruleId, params },
});

export const getBrandAutomationAccountUpdateLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS,
  payload: { ruleId, params },
});

export const saveBrandAutomationAccountUpdateLogs = (data) => ({
  type: BrandAutomationActionType.SAVE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS,
  payload: { data },
});

export const resetBrandAutomationAccountUpdateLogs = () => ({
  type: BrandAutomationActionType.RESET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS,
});

export const generateBrandAutomationAccountUpdateLogs = (ruleId, params) => ({
  type: BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS,
  payload: { ruleId, params },
});
