import TaxesActionType from './taxesActionType';

export const getDepositTaxes = (brandId) => ({
  type: TaxesActionType.GET_DEPOSIT_TAXES,
  payload: { brandId },
});

export const saveDepositTaxes = (data) => ({
  type: TaxesActionType.SAVE_DEPOSIT_TAXES,
  payload: { data },
});

export const resetDepositTaxes = () => ({
  type: TaxesActionType.RESET_DEPOSIT_TAXES,
});

export const editDepositTaxes = (brandId, data) => ({
  type: TaxesActionType.EDIT_DEPOSIT_TAXES,
  payload: { brandId, data },
});

export const getWithdrawalTaxes = (brandId) => ({
  type: TaxesActionType.GET_WITHDRAWAL_TAXES,
  payload: { brandId },
});

export const saveWithdrawalTaxes = (data) => ({
  type: TaxesActionType.SAVE_WITHDRAWAL_TAXES,
  payload: { data },
});

export const resetWithdrawalTaxes = () => ({
  type: TaxesActionType.RESET_WITHDRAWAL_TAXES,
});

export const editWithdrawalTaxes = (brandId, data) => ({
  type: TaxesActionType.EDIT_WITHDRAWAL_TAXES,
  payload: { brandId, data },
});

export const getTaxSetting = (brandId) => ({
  type: TaxesActionType.GET_TAX_SETTING,
  payload: { brandId },
});

export const saveTaxSetting = (data) => ({
  type: TaxesActionType.SAVE_TAX_SETTING,
  payload: { data },
});

export const resetTaxSetting = () => ({
  type: TaxesActionType.RESET_TAX_SETTING,
});

export const addTaxSetting = (brandId, data) => ({
  type: TaxesActionType.ADD_TAX_SETTING,
  payload: { brandId, data },
});
