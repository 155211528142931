import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import BrandAutomationActionType from 'actions/brandAutomation/brandAutomationActionType';
import { AppConstants } from 'constants/index';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus, changeGenerateReportEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import {
  saveIsBrandAutomationExist,
  saveConditionsActions,
  saveBrandAutomationRules,
  saveBrandAutomationRule,
  saveBrandAutomationTransactionLogs,
  saveBrandAutomationPeriodicalLogs,
  saveBrandAutomationSignUpLogs,
  saveBrandAutomationVerificationLogs,
  saveBrandAutomationVerificationDiscardLogs,
  saveBrandAutomationAccountUpdateLogs,
} from 'actions/brandAutomation/brandAutomationActionCreator';
import { downloadFile } from 'utils/utils';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';

function* getIsBrandAutomation({ payload }) {
  try {
    const { brandId, params } = payload;
    const { DataCollector, Automation, Exists } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Automation, Exists, brandId], { params });
    yield put(saveIsBrandAutomationExist(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getConditionsActions({ payload }) {
  try {
    const { triggerType } = payload;
    const { DataCollector, Automation, Configs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Automation, triggerType, Configs]);
    yield put(saveConditionsActions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createBrandAutomation({ payload }) {
  try {
    const { name } = payload.data;
    const { DataCollector, Automation } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Automation], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationRules({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { DataCollector, Automation } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Automation], options);
    yield put(saveBrandAutomationRules(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandAutomationRules({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Automation, Export } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Export, brandId], options);
    downloadFile(data, i18n.t('rules'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationRule({ payload }) {
  try {
    const { id } = payload;
    const { DataCollector, Automation } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Automation, id]);
    yield put(saveBrandAutomationRule(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandAutomationRule({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const { DataCollector, Automation } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Automation, id], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableBrandAutomationRule({ payload }) {
  try {
    const { id, params } = payload;
    const { name, enable } = params;
    const { DataCollector, Automation, Enable } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Automation, id, Enable], { params });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t(enable ? 'notification:slEnabled' : 'notification:slDisabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishBrandAutomationRule({ payload }) {
  try {
    const { id, params } = payload;
    const { name } = params;
    const { DataCollector, Automation, Finish } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Automation, id, Finish], { params });
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteBrandAutomationRule({ payload }) {
  try {
    const { id, params } = payload;
    const { name } = params;
    const { DataCollector, Automation } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Automation, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('tool:lRule'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationTransactionLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, Payment, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Payment, ruleId, Reports], options);
    yield put(saveBrandAutomationTransactionLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationTransactionLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, Payment, Reports, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Payment, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationPeriodicalLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, Periodical, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Periodical, ruleId, Reports], options);
    yield put(saveBrandAutomationPeriodicalLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationPeriodicalLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, Periodical, Generate, Reports,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Periodical, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationSignUpLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, SignUp, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, SignUp, ruleId, Reports], options);
    yield put(saveBrandAutomationSignUpLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationSignUpLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, SignUp, Generate, Reports,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, SignUp, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationVerificationLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, Verification, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Verification, ruleId, Reports], options);
    yield put(saveBrandAutomationVerificationLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationVerificationLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, Verification, Generate, Reports,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, Verification, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationVerificationDiscardLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, UnVerification, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, UnVerification, ruleId, Reports], options);
    yield put(saveBrandAutomationVerificationDiscardLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationVerificationDiscardLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, UnVerification, Generate, Reports,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, UnVerification, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandAutomationAccountUpdateLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Automation, AccountUpdate, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, AccountUpdate, ruleId, Reports], options);
    yield put(saveBrandAutomationAccountUpdateLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBrandAutomationAccountUpdateLogs({ payload }) {
  try {
    const { ruleId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Automation, AccountUpdate, Generate, Reports,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Automation, AccountUpdate, ruleId, Reports, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* brandAutomationSaga() {
  yield all([
    takeLatest(BrandAutomationActionType.GET_IS_BRAND_AUTOMATION_EXIST, getIsBrandAutomation),
    takeLatest(BrandAutomationActionType.GET_CONDITIONS_ACTIONS, getConditionsActions),
    takeLatest(BrandAutomationActionType.CREATE_BRAND_AUTOMATION, createBrandAutomation),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_RULES, getBrandAutomationRules),
    takeLatest(BrandAutomationActionType.EXPORT_BRAND_AUTOMATION_RULES, exportBrandAutomationRules),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_RULE, getBrandAutomationRule),
    takeLatest(BrandAutomationActionType.EDIT_BRAND_AUTOMATION, editBrandAutomationRule),
    takeLatest(BrandAutomationActionType.ENABLE_BRAND_AUTOMATION, enableBrandAutomationRule),
    takeLatest(BrandAutomationActionType.FINISH_BRAND_AUTOMATION, finishBrandAutomationRule),
    takeLatest(BrandAutomationActionType.DELETE_BRAND_AUTOMATION, deleteBrandAutomationRule),

    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_TRANSACTION_LOGS, getBrandAutomationTransactionLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_TRANSACTION_LOGS, generateBrandAutomationTransactionLogs),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_PERIODICAL_LOGS, getBrandAutomationPeriodicalLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_PERIODICAL_LOGS, generateBrandAutomationPeriodicalLogs),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_SIGN_UP_LOGS, getBrandAutomationSignUpLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_SIGN_UP_LOGS, generateBrandAutomationSignUpLogs),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_VERIFICATION_LOGS, getBrandAutomationVerificationLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_VERIFICATION_LOGS, generateBrandAutomationVerificationLogs),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS, getBrandAutomationVerificationDiscardLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_VERIFICATION_DISCARD_LOGS, generateBrandAutomationVerificationDiscardLogs),
    takeLatest(BrandAutomationActionType.GET_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS, getBrandAutomationAccountUpdateLogs),
    takeLatest(BrandAutomationActionType.GENERATE_BRAND_AUTOMATION_ACCOUNT_UPDATE_LOGS, generateBrandAutomationAccountUpdateLogs),
  ]);
}
